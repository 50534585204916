<template>
  <div
    class="w-full bg-[#FBFCFC] text-black-900 flex flex-col h-full overflow-hidden relative"
    :class="{ 'drag-over': isDragging }"
    style="max-height: calc(100% - 60px)"
    @dragenter.prevent="handleDragEnter"
    @dragleave.prevent="handleDragLeave"
    @dragover.prevent
    @drop.prevent="handleDrop"
  >
    <img
      v-if="isDragging"
      src="@src/assets/img/composer/upload-icon.svg"
      alt="Upload"
      class="drag-over-icon"
    />
    <!-- Chat messages -->
    <div
      ref="conversationBox"
      class="flex-col w-full flex-1 p-4 justify-between overflow-auto scroll-smooth relative"
      style="overscroll-behavior: none"
    >
      <template v-if="props.activeChat.messages.length > 0">
        <template
          v-for="(message, index) in props.activeChat.messages"
          :key="index"
        >
          <component
            :is="message.role === 'user' ? UserChatTemplate : BotChatTemplate"
            :message="message"
            :type="props.type"
            :save-prompt="props.handleCustomPrompts"
            :delete-message="props.deleteMessage"
          />
        </template>
        <div v-if="props.messageLoader" class="flex justify-center w-full pb-2">
          <div
            class="py-2 px-3 inline-flex justify-center items-center rounded"
            style="box-shadow: 0 1px 8px 0 rgba(0, 27, 70, 0.06)"
          >
            <beatLoader :color="'#436aff'" size="8px" class="h-4" />
          </div>
        </div>
        <div ref="lastMessageDiv" class="opacity-0" />
      </template>
      <template v-else-if="props.activeChatLoader">
        <div class="absolute bottom-0 left-[48%]">
          <beatLoader size="8px" :color="'#436aff'" />
        </div>
      </template>
      <template v-else>
        <div
          class="mt-16 w-full text-center flex-1 flex flex-col items-center justify-center px-4"
        >
          <img
            src="@src/assets/img/chat_bot/cyrus-icon-blue-light.svg"
            class="w-[6.25rem]"
            alt=""
          />
          <div class="whitespace-nowrap font-medium text-lg mt-4"
            >Hey {{ getUserName }}!</div
          >
          <div class="text-gray-700 font-normal text-base"
            >How may I help you today?</div
          >
        </div>
      </template>
    </div>

    <!-- Quick prompts and input -->
    <div
      id="quick-prompts"
      ref="quickPromptsRef"
      class="bg-[#FBFCFC] w-full"
      style="border-radius: 0 0 8px 8px"
    >
      <div class="m-4 border border-gray rounded-md">
        <div
          ref="promptsContainer"
          class="mt-1 mb-1 transition-all duration-300 p-2"
          :style="{
            maxHeight: expanded ? '180px' : '86px',
            minHeight: !hasImageAttachment ? '85.63px' : '',
            overflow: 'auto',
          }"
        >
          <div class="flex flex-wrap gap-1.5">
            <template v-if="activeTab === 'quick'">
              <div
                v-for="prompt in AiChatQuickPrompts[
                  hasImageAttachment ? 'image' : 'text'
                ]"
                :key="prompt"
                v-tooltip="{
                  content: prompt,
                  delay: 500,
                }"
                class="bg-[#F3F7FE] text-sm text-[#000D21] px-3 py-1.5 rounded-[0.375rem] cursor-pointer hover:bg-[#FBFCFC] border border-[#D2D5DF80]"
                @click="handlePromptClick(prompt)"
              >
                {{ prompt }}
              </div>
            </template>
            <template v-else>
              <div
                v-for="prompt in props.prompts"
                :key="prompt.title"
                v-tooltip="{
                  content: prompt?.prompt,
                  delay: 500,
                }"
                class="text-sm text-[#000D21] px-3 py-1.5 rounded-[0.375rem] cursor-pointer"
                :style="{
                  backgroundColor: prompt.color_code || '#F3F7FE',
                  border: `1px solid ${
                    clickedPrompt === prompt._id ? '#2961D2' : '#F5F5F5'
                  }`,
                }"
                @click="handlePromptClick(prompt, 'custom')"
              >
                <span class="prompt-title">{{ prompt.title }}</span>
              </div>
              <template v-if="!props.prompts?.length">
                <div
                  class="flex flex-col justify-center items-center w-full h-[69.63px]"
                >
                  <span class="text-md">No favorites yet.</span>
                  <span class="text-md"
                    >Mark your favorites from the prompts library!</span
                  >
                </div>
              </template>
            </template>
          </div>
        </div>
        <div
          class="w-full flex px-2 py-2 justify-between items-center bg-[#F4F4F4]"
        >
          <div class="flex-grow flex items-center">
            <CstButton
              size="default"
              variant="text"
              class="text-[#000D21] font-normal"
              :class="{ '!bg-[#E7E7E7]': activeTab === 'quick' }"
              style="font-size: 12px"
              @click="toggleTab('quick')"
            >
              <img
                src="@src/assets/img/chat_bot/quick-prompts.svg"
                alt="quick_prompts"
                class="mr-2"
              />
              <span>Quick Prompts</span>
            </CstButton>

            <CstButton
              size="default"
              variant="text"
              class="text-[#000D21] font-normal"
              :class="{ '!bg-[#E7E7E7]': activeTab === 'favourites' }"
              style="font-size: 12px"
              @click="toggleTab('favourites')"
            >
              <i class="far fa-star mr-2"></i>
              <span>Favorite Prompts</span>
            </CstButton>


          </div>

          <div
            v-if="activeTab === 'favourites' && hasOverflow"
            class="flex-none"
          >
            <CstButton
              size="default"
              variant="text"
              class="text-primary font-normal"
              @click="toggleExpand"
            >
              {{ expanded ? 'View less' : 'View more' }}
            </CstButton>
          </div>
        </div>
      </div>

      <!-- Input area -->
      <div
        class="px-4 py-4 w-full min-h-[10rem] bg-[#F4F6FA] relative"
        style="border-top: 1px solid #eaf0f6"
      >
        <div
          class="bg-white p-4 rounded-lg relative"
          style="box-shadow: 0 4px 15px -5px rgba(25, 0, 125, 0.1)"
        >
          <div v-if="imageFiles.length > 0 || isProcessingLibraryImages" class="flex flex-wrap gap-2 mb-3">
            <template v-if="isProcessingLibraryImages">
              <div class="w-20 h-20 rounded-lg border border-gray-200 flex items-center justify-center bg-gray-50">
                <div class="text-center">
                  <clip-loader :color="'#436aff'" size="16px" />
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-for="(file, index) in imageFiles"
                :key="index"
                class="relative w-20 h-20 rounded-lg overflow-hidden border border-gray-200"
              >
                <img
                  v-tooltip="'Image Preview'"
                  :src="imageUrls[index]"
                  class="w-full h-full object-cover cursor-pointer"
                  alt="preview"
                  @click="handleImageClick(index)"
                />
                <span
                  v-tooltip="'Remove Image'"
                  class="absolute top-1 right-1 w-5 h-5 bg-black-500 rounded-full flex items-center justify-center cursor-pointer"
                  @click="removeImage(index)"
                >
                  <i class="fas fa-times text-white text-xs"></i>
                </span>
              </div>
            </template>
          </div>

          <!-- Separator -->
          <div
            v-if="imageFiles.length > 0 || isProcessingLibraryImages"
            class="w-full h-px my-3 bg-gray-200 rounded-full dark:bg-gray-700"
          ></div>

          <div
            ref="editableDiv"
            contenteditable="true"
            class="chatInput resize-none w-full border-0 text-base min-h-[45px] h-full rounded-lg !max-h-[225px] overflow-auto"
            placeholder="Type your message here"
            @input="updateContent"
            @keydown="handleKeyEvents"
          ></div>

          <div
            class="mt-4 w-full flex items-center justify-between bg-white rounded-lg"
          >
            <div class="flex gap-2 items-center">
              <p
                class="text-[#0068E5] text-base font-normal rounded px-4 py-1.5 cursor-pointer select-none"
                style="border: 1px solid #0068e5"
                @click="props.toggleChatDialogs('openSavedPrompts')"
              >
                Prompts
              </p>

              <!-- Separator -->
              <div class="border border-r-2 border-gray-200 h-6 mx-2"></div>

              <p
                v-tooltip="'Upload image'"
                class="text-gray-700 bg-[#E6F0FC] leading-none rounded-lg cursor-pointer select-none text-center flex items-center px-2 h-8"
                @click="handleImageUpload"
              >
                <i class="fa fa-paperclip"></i>
              </p>

              <p
                v-tooltip="'Upload from Media Library'"
                class="text-gray-700 bg-[#E6F0FC] leading-none rounded-lg cursor-pointer select-none text-center flex items-center px-2 h-8"
                @click="openMediaLibrary"
              >
                <i class="fa fa-image"></i>
              </p>
              <img
                class="mt-1 ml-1"
                src="@src/assets/img/common/new_tag.svg"
                alt="new-tag"
              />
            </div>
            <p
              class="bg-cs-primary text-white text-base font-normal rounded px-4 py-1.5 cursor-pointer select-none flex items-center gap-x-2 hover:bg-opacity-75"
              :class="{
                'opacity-50 !cursor-not-allowed':
                  props.activeChatLoader || props.messageLoader || isProcessingLibraryImages,
              }"
              style="border: 1px solid #0068e5"
              @click="handleSendMessage"
            >
              Send
              <img
                src="@src/assets/img/chat_bot/send_icon.svg"
                alt="send_icon"
                width="14"
                height="14"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <VueEasyLightbox
    :visible="visible"
    :imgs="imageUrls"
    :index="imageIndex"
    @hide="visible = false"
  />

  <!-- Confirmation popup for image processing -->
  <CstConfirmationPopup
    modal-name="process-images"
    :primary-button-text="'Keep All'"
    :secondary-button-text="'Replace'"
    @confirmed="handleImageActionConfirmed(true)"
    @secondary-action="handleImageActionConfirmed(false)"
  >
    <template v-slot:head>
      <h4 class="text-xl !flex items-center gap-x-2">
        <i class="fa fa-image"></i> Process Content
      </h4>
    </template>
    <template v-slot:body>
      <p class="pt-4">You already have content in the chat. Would you like to keep the existing content and append the new one?</p>
    </template>
  </CstConfirmationPopup>
</template>

<script setup>
import { computed, onMounted, ref, watch, nextTick, onUnmounted, inject } from 'vue'
import { AiChatQuickPrompts } from '@src/modules/AI-tools/Prompts.js'
import { useStore } from '@state/base'
import BotChatTemplate from '@src/modules/AI-tools/BotChatTemplate.vue'
import UserChatTemplate from '@src/modules/AI-tools/UserChatTemplate.vue'
import CstConfirmationPopup from '@ui/Popup/CstConfirmationPopup.vue'
import { EventBus } from '@common/lib/event-bus'
import { debounce } from 'lodash'
import VueEasyLightbox from 'vue-easy-lightbox'
import CstButton from '@/src/components/UI/Button/CstButton.vue'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

const store = useStore()

const props = defineProps({
  type: {
    type: String,
    default: 'modal',
  },
  modalToggler: {
    type: Boolean,
    default: false,
  },
  activeChatLoader: {
    type: Boolean,
    default: false,
  },
  messageLoader: {
    type: Boolean,
    default: false,
  },
  activeChat: {
    type: Object,
    default: () => ({}),
  },
  fetchActiveChat: {
    type: Function,
    default: () => {},
  },
  sendMessage: {
    type: Function,
    default: () => {},
  },
  deleteMessage: {
    type: Function,
    default: () => {},
  },
  toggleChatDialogs: {
    type: Function,
    default: () => {},
  },
  promptInput: {
    type: String,
    default: '',
  },
  isCreditVisible: {
    type: Boolean,
    default: false,
  },
  handleCustomPrompts: {
    type: Function,
    default: () => {},
  },
  prompts: {
    type: Array,
    default: () => [],
  },
  handleNewChat: {
    type: Function,
    default: () => {},
  },
})

const quickPromptsRef = ref(null)
const lastMessageDiv = ref(null)
const conversationBox = ref(null)
const editableDiv = ref(null)
const previousContent = ref('')
const activeTab = ref('quick')
const expanded = ref(false)
const clickedPrompt = ref(null)
const promptsContainer = ref(null)
const hasOverflow = ref(false)
const removeBG = ref(false)
const imageUrls = ref([])
const imageFiles = ref([])
const imageIndex = ref(0)
const visible = ref(false)
const isDragging = ref(false)
const isProcessingLibraryImages = ref(false)
const pendingImageAction = ref(null)

const root = inject('root')
const { $bvModal } = root

const { showNewChat } = useComposerHelper()
const getUserName = computed(() => store.getters.getProfile?.firstname)

const hasImageAttachment = computed(() => imageUrls.value.length > 0)

// Helper functions for image processing
const processImageFiles = async (files) => {
  const newUrls = files.map((file) => URL.createObjectURL(file))
  imageFiles.value = [...imageFiles.value, ...files]
  imageUrls.value = [...imageUrls.value, ...newUrls]
}

const processImageUrls = async (urls) => {
  try {
    const responses = await Promise.all(urls.map((url) => fetch(url)))
    const blobs = await Promise.all(responses.map((res) => res.blob()))
    const files = blobs.map(
      (blob, i) => new File([blob], `image-${i + 1}.jpg`, { type: 'image/jpeg' })
    )
    return files
  } catch (error) {
    console.error('Error processing image URLs:', error)
    throw error
  }
}

const cleanupImageUrls = () => {
  imageUrls.value.forEach((url) => {
    if (url.startsWith('blob:')) {
      URL.revokeObjectURL(url)
    }
  })
}

const clearImages = () => {
  cleanupImageUrls()
  imageFiles.value = []
  imageUrls.value = []
}

// Validate images: maximum count and total size constraint.
function validateBase64Images(base64Images) {
  const MAX_IMAGES = 10;
  const MAX_SIZE_BYTES = 32 * 1024 * 1024; // 32 MB in bytes

  if (base64Images.length > MAX_IMAGES) {
    return { valid: false, message: 'A maximum of 10 images or a total size of up to 32MB is allowed.' };
  }

  let totalSize = 0;
  for (const base64Image of base64Images) {
    // If base64 string has a header, get the actual base64 content.
    const base64 = base64Image.includes(',') ? base64Image.split(',')[1] : base64Image;
    const paddingMatch = base64.match(/=+$/);
    const padding = paddingMatch ? paddingMatch[0].length : 0;
    totalSize += (base64.length * 3 / 4) - padding;

    if (totalSize > MAX_SIZE_BYTES) {
      return { valid: false, message: 'A maximum of 10 images or a total size of up to 32MB is allowed.' };
    }
  }

  return { valid: true, message: 'Valid images.' };
}

const toggleTab = (tab) => {
  activeTab.value = tab
}

const handleKeyEvents = (event) => {
  if (props.activeChatLoader || props.messageLoader) return
  if (!event.shiftKey && event.key === 'Enter') {
    event.preventDefault()
    handleSendMessage()
  }
}

const highlightAndSetContent = (content) => {
  const highlightedContent = content.replace(
    /\[([^\]]+)\]/g,
    '<span style="background-color: #DCECFF; padding: 2px 4px; border-radius: 4px;">[$1]</span>'
  )
  editableDiv.value.innerHTML = highlightedContent
  previousContent.value = editableDiv.value.innerText
  const range = document.createRange()
  const sel = window.getSelection()
  range.selectNodeContents(editableDiv.value)
  range.collapse(false)
  sel.removeAllRanges()
  sel.addRange(range)
  editableDiv.value.focus()
  removeBG.value = false
}

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const handleSendMessage = async () => {
  showNewChat.value = true
  if (props.activeChatLoader || props.messageLoader) return

  const content = editableDiv.value?.innerText?.trim() || ''
  const hasContent = content !== '' && !props.activeChatLoader

  if (!hasContent) {
    store.dispatch('toastNotification', {
      message: 'Please enter a message',
      type: 'info',
    })
    return
  }

  try {
    // Convert all images to base64
    const base64Images = await Promise.all(
      (imageFiles.value || []).map((file) => fileToBase64(file))
    )

    // validate images 10 max or 32 MB total
    const validationResult = validateBase64Images(base64Images);
    if (!validationResult.valid) {
      store.dispatch('toastNotification', {
        message: validationResult.message,
        type: 'error',
      });
      return;
    }

    // Send message with content and images
    await props?.sendMessage?.(content, base64Images)

    // Clear the input and images
    if (editableDiv.value) {
      editableDiv.value.innerHTML = ''
    }
    clearImages()

    if (expanded.value) {
      expanded.value = false
    }

    // Remove focus
    editableDiv.value?.blur()
    scrollToLastMessage()
  } catch (error) {
    console.error('Error sending message:', error)
  }
}

const scrollToLastMessage = () => {
  const ele = conversationBox.value
  if (ele) {
    setTimeout(() => {
      ele.scrollTop = ele.scrollHeight
    }, 30)
  }
}

const scrollSmooth = () => {
  const ele = lastMessageDiv.value
  if (ele) {
    setTimeout(() => {
      ele.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  } else {
    scrollToLastMessage()
  }
}

const updateContent = (event) => {
  const editableDivValue = editableDiv.value
  if (!editableDivValue) {
    console.error('Editable div not found')
    return
  }

  const currentContent = editableDivValue.innerText
  const selection = window.getSelection()
  const range = selection.getRangeAt(0)

  // Calculate the absolute cursor position
  const absoluteCursorPosition = getAbsoluteCursorPosition(
    editableDivValue,
    range
  )

  // Find the bracket pair the cursor is in, if any
  const bracketPair = findEnclosingBracketPair(
    currentContent,
    absoluteCursorPosition
  )

  if (bracketPair) {
    event.preventDefault()
    const [left, right] = bracketPair

    // Remove brackets
    const newContent =
      currentContent.slice(0, left) +
      currentContent.slice(left + 1, absoluteCursorPosition) +
      currentContent.slice(right + 1)

    // Update content and cursor position
    editableDivValue.innerText = newContent
    const newCursorPosition = absoluteCursorPosition - 1 // Adjust for removed '['

    // Set cursor position after content update
    nextTick(() => setCursorPosition(editableDivValue, newCursorPosition))

    previousContent.value = newContent
    highlightAndSetContent(newContent)
  } else {
    previousContent.value = currentContent
  }
}

// Helper functions
const getAbsoluteCursorPosition = (element, range) => {
  let position = 0
  const walker = document.createTreeWalker(
    element,
    NodeFilter.SHOW_TEXT,
    null,
    false
  )
  let node
  while ((node = walker.nextNode())) {
    if (node === range.startContainer) {
      return position + range.startOffset
    }
    position += node.nodeValue.length
  }
  return position
}

const findEnclosingBracketPair = (content, position) => {
  const left = content.lastIndexOf('[', position - 1)
  if (left === -1) return null
  const right = content.indexOf(']', left)
  if (right === -1 || right < position) return null
  return [left, right]
}

const setCursorPosition = (element, position) => {
  const range = document.createRange()
  const walker = document.createTreeWalker(
    element,
    NodeFilter.SHOW_TEXT,
    null,
    false
  )
  let currentPosition = 0
  let node

  while ((node = walker.nextNode())) {
    const nodeLength = node.nodeValue.length
    if (currentPosition + nodeLength >= position) {
      range.setStart(node, position - currentPosition)
      range.setEnd(node, position - currentPosition)
      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      break
    }
    currentPosition += nodeLength
  }
}

const handlePromptClick = async (prompt, type = 'quick') => {
  if (props.activeChatLoader || props.messageLoader) return

  clickedPrompt.value = prompt._id
  highlightAndSetContent(type === 'custom' ? prompt.prompt : prompt)

  // if the type is quick, then send the message immediately
  if (type === 'quick') {
    await handleSendMessage()
  }
}

const toggleExpand = () => {
  expanded.value = !expanded.value
}

const checkOverflow = () => {
  if (promptsContainer.value) {
    hasOverflow.value = promptsContainer.value.scrollHeight > 90
  }
}

// Action prompts and their functions
const actionPrompts = {
  improve: (text) =>
    `Enhance the clarity, coherence, and overall quality of the following text:\n\n"${text}"\n\nMake sure the tone is engaging and professional.`,

  rephrase: (text) =>
    `Rewrite the following text to improve readability and flow while preserving its original meaning:\n\n"${text}"\n\nEnsure the tone is natural and polished.`,

  shorten: (text) =>
    `Condense the following text to be more concise and to the point while retaining key information and clarity:\n\n"${text}"\n\nFocus on removing redundancy.`,

  lengthen: (text) =>
    `Expand the following text by adding relevant details, examples, and explanations to enhance its depth and clarity:\n\n"${text}"\n\nEnsure the additions are meaningful and coherent.`,

  'grammar-fix': (text) =>
    `Correct any grammatical, spelling, and punctuation errors in the following text:\n\n"${text}"\n\nEnsure the text reads fluently and professionally.`,

  'extract-text-image': () =>
    'Extract all text content visible in the provided image(s). Return the extracted text in a structured format.',

  'summarize-image': () =>
    'Provide a concise and clear summary highlighting the information in the image(s).',

  'describe-image': () =>
    'Analyze the provided image(s) and describe its visual content in detail. If any text is present, mention its relevance to the description too.',

  'caption-from-image': () =>
    'Create a single, engaging caption that captures the essence of the image(s), unifying multiple images under a common theme if needed. Suitable for social media or marketing.',
}

// Remove attached image
const removeImage = (index) => {
  // Revoke the object URL to prevent memory leaks
  if (imageUrls.value[index]?.startsWith('blob:')) {
    URL.revokeObjectURL(imageUrls.value[index])
  }

  // Remove the image from both arrays
  imageUrls.value = imageUrls.value.filter((_, i) => i !== index)
  imageFiles.value = imageFiles.value.filter((_, i) => i !== index)
}

// Upload new images
const handleImageUpload = async () => {
  const input = document.createElement('input')
  input.type = 'file'
  input.accept = 'image/*'
  input.multiple = true

  input.onchange = async (e) => {
    const files = Array.from(e.target.files)
    if (files.length === 0) return
    await processImageFiles(files)
  }

  input.click()
}

// Process image action
const processImageAction = async ({ type, images = [] }) => {
  if (!showNewChat.value) {
    await props.handleNewChat?.()
  }

  const prompt = actionPrompts[type]
  if (!prompt) return

  // Format the prompt
  const formattedPrompt = prompt()

  // Get existing content if any
  const existingContent = editableDiv.value?.innerText?.trim() || ''

  // If there are existing content or images, show confirmation
  if (existingContent || imageFiles.value.length > 0) {
    pendingImageAction.value = { type, images, formattedPrompt, existingContent }
    $bvModal.show('process-images-confirmation-modal')
    return
  }

  // Set the prompt in the input
  if (editableDiv.value) {
    editableDiv.value.innerText = formattedPrompt
  }

  try {
    const files = await processImageUrls(images)
    await processImageFiles(files)
  } catch (error) {
    store.dispatch('toastNotification', {
      message: 'Error processing images',
      type: 'error',
    })
    return
  }
  // Send the message after processing is complete
  handleSendMessage()
  pendingImageAction.value = null

  if(keepExisting){
    // Hide the confirmation modal
    // cancelled event is triggered when the user closes in the confirmation modal
    $bvModal.hide('process-images-confirmation-modal')
  }
}

const handleDrop = async (e) => {
  e.preventDefault()
  isDragging.value = false

  const items = Array.from(e.dataTransfer.items)
  const imageItems = items.filter(
    (item) => item.kind === 'file' && item.type.startsWith('image/')
  )

  if (imageItems.length === 0) return

  const files = imageItems.map((item) => item.getAsFile())
  await processImageFiles(files)

  // focus out
  editableDiv.value?.blur()
}

// Handle AI text actions
const handleAITextAction = async ({ type, text }) => {
  // First ensure new chat is created
  if (!showNewChat.value || !text) {
    await props.handleNewChat?.()
    if (!text) {
      editableDiv.value?.focus()
      return
    }
  }

  const prompt = actionPrompts[type]
  if (!prompt) return

  // Format the prompt
  const formattedPrompt = prompt(text)

  // Get existing content if any
  const existingContent = editableDiv.value?.innerText?.trim() || ''

  // If there is existing content, show confirmation
  if (existingContent) {
    pendingImageAction.value = { type, formattedPrompt, existingContent }
    $bvModal.show('process-images-confirmation-modal')
    return
  }

  // Set the prompt in the input
  if (editableDiv.value) {
    editableDiv.value.innerText = formattedPrompt
  }

  // Send the message
  handleSendMessage()
}

const processLibraryImages = async (images = []) => {
  if (images.length === 0) return
  isProcessingLibraryImages.value = true

  try {
    const base64Images = await Promise.all(images.map(urlToBase64))
    const validBase64Images = base64Images.filter((img) => img !== null)
    const files = validBase64Images.map((base64, i) => {
      const byteString = atob(base64.split(',')[1])
      const mimeType = base64.split(',')[0].split(':')[1].split(';')[0]
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      const blob = new Blob([ab], { type: mimeType })
      return new File([blob], `library-image-${i + 1}.jpg`, { type: mimeType })
    })

    await processImageFiles(files)
  } catch (error) {
    console.error('Error processing library images:', error)
    store.dispatch('toastNotification', {
      message: 'Error processing images from media library',
      type: 'error',
    })
  } finally {
    isProcessingLibraryImages.value = false
  }
}

const urlToBase64 = async (url) => {
  try {
    const response = await fetch(url)
    const blob = await response.blob()
    return await fileToBase64(blob)
  } catch (error) {
    console.error('Error converting URL to base64:', error)
    return null
  }
}

// Open media library
const openMediaLibrary = () => {
  EventBus.$emit('show-media-library-modal', {
    source: 'ai-chat',
    details: {},
    sideTabIndex: 1,
    modalId: 'global-upload-media-modal',
  })
}

const handleDragEnter = (e) => {
  e.preventDefault()
  isDragging.value = true
}

const handleDragLeave = (e) => {
  e.preventDefault()
  const rect = e.currentTarget.getBoundingClientRect()
  const x = e.clientX
  const y = e.clientY

  // Check if the mouse has actually left the element
  if (x <= rect.left || x >= rect.right || y <= rect.top || y >= rect.bottom) {
    isDragging.value = false
  }
}

watch(activeTab, () => {
  nextTick(() => {
    checkOverflow()
  })
})

watch(
  () => props.prompts,
  () => {
    nextTick(() => {
      checkOverflow()
    })
  },
  { deep: true }
)

watch(
  () => props.activeChat,
  () => {
    scrollToLastMessage()
  },
  { deep: true }
)

watch(() => props.activeChatLoader, scrollSmooth)

watch(
  () => props.modalToggler,
  (value) => {
    if (value && showNewChat.value) {
      props.fetchActiveChat()
      scrollSmooth()
    }
  }
)

watch(
  () => store.getters.getWorkspaces.activeWorkspace._id,
  (newValue) => {
    if (newValue) props.fetchActiveChat()
  },
  { immediate: true }
)

onMounted(() => {
  EventBus.$on('append-prompt', (value) => {
    nextTick(() => {
      const promptText = value.prompt ?? value
      highlightAndSetContent(promptText)
    })
  })
  // TODO: need to check thi with @Shaharyar07
  EventBus.$on('ai-text-action', debounce(handleAITextAction, 0))

  // AI Image Actions
  EventBus.$on('ai-image-action', processImageAction)

  EventBus.$on('add-images-from-library', processLibraryImages)

  checkOverflow()
})

onUnmounted(() => {
  EventBus.$off('ai-text-action')
  EventBus.$off('ai-image-action')
  // Clean up image URLs
  imageUrls.value.forEach((url) => URL.revokeObjectURL(url))
})

const handleImageActionConfirmed = async (keepExisting = false) => {
  if (!pendingImageAction.value) return

  const { images = [], formattedPrompt, existingContent } = pendingImageAction.value

  // Set the prompt in the input, either appending or replacing based on keepExisting
  if (editableDiv.value) {
    if (keepExisting && existingContent) {
      // Append new content to existing content with a newline separator
      editableDiv.value.innerText = `${existingContent}\n\n${formattedPrompt}`
    } else {
      editableDiv.value.innerText = formattedPrompt
    }
  }

  try {
    if (images.length > 0) {
      const files = await processImageUrls(images)

      if (!keepExisting) {
        cleanupImageUrls()
        imageFiles.value = []
        imageUrls.value = []
      }

      await processImageFiles(files)
    }
  } catch (error) {
    store.dispatch('toastNotification', {
      message: 'Error processing images',
      type: 'error',
    })
    pendingImageAction.value = null
    return
  }

  // Send the message after processing is complete
  handleSendMessage()
  pendingImageAction.value = null

  if(keepExisting){
    // Hide the confirmation modal
    // cancelled event is triggered when the user closes in the confirmation modal
    $bvModal.hide('process-images-confirmation-modal')
  }
}

const handleImageClick = (index) => {
  visible.value = true
  imageIndex.value = index
}
</script>

<style lang="scss">
.ai-chat-message {
  .chat-codeblock {
    color: #000000ad;
    font-weight: 300;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    display: block;
    word-wrap: break-word;
  }
  .chat-hashtag {
    color: #2e7cff;
  }
}
span.prompt-title {
  display: block;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chatInput[contenteditable='true']:empty:before {
  content: attr(placeholder);
  color: #aaa;
}
.chatInput span[style*='background-color: #E6F3FF'] {
  display: inline-block;
  line-height: 1.2;
  margin: 0 2px;
}

.chatInput {
  &:focus {
    border-color: #2e7cff;
    box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
    height: 10rem;
    padding: 10px;
  }
}

.drag-over {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px dashed #2e7cff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 100;
  }
  &::before {
    content: 'Drop images here';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 20px;
    font-size: 1.2rem;
    color: #2e7cff;
    font-weight: 500;
    z-index: 101;
    pointer-events: none;
    white-space: nowrap;
  }
}

.drag-over-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 30px));
  width: 40px;
  height: 40px;
  z-index: 101;
  pointer-events: none;
}
</style>
