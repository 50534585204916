import { ref } from 'vue'
import { analyticsBaseUrl } from '@src/config/api-utils'
import useNumber from '@common/composables/useNumber'
import useDateFormat from "@common/composables/useDateFormat";

const { momentWrapper } = useDateFormat()
const analyticsDesignSystem = {
  graphs: {
    defaultColor: '#78CEA0',
    singleBarMaxWidth: 16,
    dualBarMaxWidth: 14,
    labelBoxStyle: {
      width: 11,
      height: 11,
      borderRadius: 2,
      display: 'inline-block',
      textAlign: 'center',
      lineHeight: 14,
      fontSize: 12,
    },
    richStylesBySentiment: {
      neutral: { color: '#979CA0', fontSize: 12, padding: [0, 0, 0, 6] },
      positive: {
        backgroundColor: '#EEF9F3',
        color: '#3D8A61',
        padding: 4,
        borderRadius: 2,
        display: 'inline-block',
        textAlign: 'center',
        lineHeight: 14,
        fontSize: 12,
      },
      negative: {
        backgroundColor: '#FFECF0',
        color: '#e02f2f',
        padding: 4,
        borderRadius: 2,
        display: 'inline-block',
        textAlign: 'center',
        lineHeight: 14,
        fontSize: 12,
      },
    },
  },
}
const BASIC_ENDPOINT = analyticsBaseUrl
const BASE_ENDPOINT = analyticsBaseUrl + 'analytics/overview/'
const TWITTER_JOB_ENDPOINT = analyticsBaseUrl + 'analytics/settings/twitter/'
const LABEL_AND_CAMPAIGNS_BASE_ENDPOINT =
    analyticsBaseUrl + 'analytics/campaignLabelAnalytics/'
const isReportView = ref(false)
const DEFAULT_DATE_RANGE = [
  momentWrapper().subtract(30, 'days').toDate(),
  momentWrapper().toDate(),
]

const ANALYTICS_DELIMITER = ' $$$CS_ANALYTICS_DELIMITER$$$ '

const screenWidth = ref(window.innerWidth)

export default function useAnalytics(defaultLabel = 'Data') {
  const { formatNumber } = useNumber()

  const defaultChartOptions = {
    color: analyticsDesignSystem?.graphs?.defaultColor,
    borderColor: analyticsDesignSystem?.graphs?.defaultColor,
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          color: analyticsDesignSystem?.graphs?.defaultColor,
          backgroundColor: '#E6F7FF',
        },
        crossStyle: {
          color: analyticsDesignSystem?.graphs?.defaultColor,
          width: 1,
        },
      },
      formatter: tooltipFormatter,
    },
    xAxis: {
      type: 'category',
      nameGap: 40,
      nameLocation: 'center',
      nameTextStyle: {
        color: '#979CA0',
      },
      axisLabel: {
        color: '#979CA0',
        fontSize: 12,
        fontWeight: 400,
        margin: 10,
        formatter: (value) => {
          return momentWrapper(value).formatDate()
        },
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
        lineStyle: {
          color: '#E9EFF6',
        },
      },
      axisLine: {
        lineStyle: {
          color: '#dfe1e1',
        },
      },
      splitLine: {
        lineStyle: {
          color: '#F2F4F6',
        },
      },
      axisPointer: {
        type: 'shadow',
        label: {
          show: false,
          backgroundColor: analyticsDesignSystem?.graphs?.defaultColor,
          color: 'white',
          formatter: (value) => {
            return momentWrapper(value).formatDate()
          },
        },
      },
    },
    yAxis: {
      type: 'value',
      sort: 'ascending',
      name: `{a|} {b|${defaultLabel}}`,
      nameTextStyle: {
        rich: {
          a: {
            ...analyticsDesignSystem?.graphs?.labelBoxStyle,
            backgroundColor: analyticsDesignSystem?.graphs?.defaultColor,
          },
          b: {
            color: analyticsDesignSystem?.graphs?.defaultColor,
            fontSize: 12,
            padding: [0, 0, 0, 6],
          },
        },
      },
      min: function (value) {
        const minValue = value.min

        // Function to determine the scaling factor based on the value range
        const getScalingFactor = (val) => {
          if (val < 10) return 0
          if (val < 100) return 0.1
          if (val < 1000) return 0.01
          if (val < 10000) return 0.001
          if (val < 100000) return 0.0001
          return 0.00001
        }

        const scalingFactor = getScalingFactor(minValue)
        return scalingFactor === 0 ? 0 : minValue - minValue * scalingFactor
      },
      position: 'left',
      nameLocation: 'center',
      nameGap: 35,
      axisLabel: {
        color: '#979CA0',
        fontSize: 12,
        fontWeight: 400,
        formatter: (value) => formatNumber(value?.toFixed(0)),
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
        lineStyle: {
          color: '#E9EFF6',
        },
      },
      axisLine: {
        lineStyle: {
          color: '#F2F4F6',
        },
      },
      axisPointer: {
        label: {
          backgroundColor: analyticsDesignSystem?.graphs?.defaultColor,
          color: 'white',
          formatter: (dataPoint) =>
            formatNumber(dataPoint?.value) ?? dataPoint.value,
        },
        snap: true,
      },
      splitLine: {
        lineStyle: {
          color: '#F2F4F6',
        },
      },
    },
    grid: {
      left: 50,
      top: 30,
      right: 50,
      bottom: 30,
      borderColor: '#E9EFF6',
      containLabel: true,
    },
    series: [
      {
        name: defaultLabel,
        data: [],
        type: 'line',
        areaStyle: { opacity: 0.4, cursor: 'auto' },
        cursor: 'auto',
        showSymbol: true,
        symbolSize: 8,
        colorBy: 'series',
      },
    ],
  }

  const legendOptions = {
    show: true,
    data: [],
    top: 0,
    left: 'center',
    icon: 'circle',
  }

  const dataZoomOptions = {
    dataZoom: [
      {
        type: 'inside',
      },
      {
        type: 'slider',
        bottom: 5,
      },
    ],
  }

  const lineChartOptions = ref(defaultChartOptions)

  const barChartOptions = ref({
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          color: analyticsDesignSystem?.graphs?.defaultColor,
          backgroundColor: '#E6F7FF',
        },
        crossStyle: {
          color: analyticsDesignSystem?.graphs?.defaultColor,
          width: 1,
        },
      },
      formatter: tooltipFormatter,
    },
    dataZoom: defaultChartOptions.dataZoom,
    grid: defaultChartOptions.grid,
    xAxis: {
      type: 'category',
      nameLocation: 'center',
      nameGap: 40,
      nameTextStyle: {
        color: '#979CA0',
      },
      position: 'bottom',
      axisPointer: {
        type: 'shadow',
        label: {
          show: false,
        },
      },
      axisLabel: {
        color: '#979CA0',
        fontSize: 12,
        fontWeight: 400,
        margin: 10,
        formatter: (value) => {
          return momentWrapper(value).formatDate()
        },
      },
      axisTick: defaultChartOptions.xAxis.axisTick,
      axisLine: defaultChartOptions.xAxis.axisLine,
    },
    yAxis: {
      type: defaultChartOptions.yAxis.type,
      sort: defaultChartOptions.yAxis.sort,
      name: defaultChartOptions.yAxis.name,
      nameTextStyle: {
        rich: {
          a: {
            ...analyticsDesignSystem?.graphs?.labelBoxStyle,
            backgroundColor: analyticsDesignSystem?.graphs?.defaultColor,
          },
          b: {
            color: analyticsDesignSystem?.graphs?.defaultColor,
            fontSize: 12,
            padding: [0, 0, 0, 6],
          },
        },
      },
      position: defaultChartOptions.yAxis.position,
      nameLocation: defaultChartOptions.yAxis.nameLocation,
      nameGap: defaultChartOptions.yAxis.nameGap,
      axisLabel: defaultChartOptions.yAxis.axisLabel,
      axisTick: {
        show: true,
        alignWithLabel: true,
        interval: 'int',
        lineStyle: {
          color: '#E9EFF6',
        },
      },
      axisLine: defaultChartOptions.yAxis.axisLine,
      axisPointer: {
        label: {
          backgroundColor: analyticsDesignSystem?.graphs?.defaultColor,
          color: 'white',
          formatter: (dataPoint) =>
            formatNumber(dataPoint?.value) ?? dataPoint.value,
        },
      },
      splitLine: defaultChartOptions.yAxis.splitLine,
    },
    series: [
      {
        name: defaultLabel,
        data: [],
        type: 'bar',
        color: analyticsDesignSystem?.graphs?.defaultColor,
        colorBy: 'series',
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
        },
        barMaxWidth: analyticsDesignSystem?.graphs?.singleBarMaxWidth,
        yAxisIndex: 0,
        areaStyle: { opacity: 0.4, cursor: 'pointer' },
        cursor: 'pointer',
      },
    ],
  })

  const pieChartOptions = ref({
    color : [
      '#61BAE4',
      '#8AC1A2',
      '#145B9B',
      '#777777',
      '#8081B9',
      '#32a487',
      '#339ca8',
      '#cda819',
    ],
    tooltip: {
      trigger: 'item',
      formatter: tooltipFormatterPieChart,
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        top: 30,
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: true,
          position: 'outside',
          formatter: '{b} \n {c}'
        },
        labelLine:{
          show: true,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 18,
            fontWeight: 'normal',
            formatter: '{b} \n {c}'
          }
        },
        data: []
      }
    ]
  });

  const multipleSeriesBarChartOptions = ref({
    ...defaultChartOptions,
    tooltip: {
      ...defaultChartOptions.tooltip,
    },
    yAxis: {
      ...defaultChartOptions.yAxis,
      name: defaultLabel,
      nameTextStyle: {
        color: '#979CA0',
      },
    },
    series: barChartOptions.value.series,
  })

  const multipleSeriesLineChartOptions = ref({
    ...defaultChartOptions,
    yAxis: {
      ...defaultChartOptions.yAxis,
      name: defaultLabel,
      nameTextStyle: {
        color: '#979CA0',
      },
    },
    series: [],
  })

  const isNoAnalyticsData = (data = [], getFromValue = true) => {
    let checkData = data.value

    if (Array.isArray(data.value)) {
      checkData = data.value[0]
    }

    if (!getFromValue) {
      checkData = data
    }

    return (
        !checkData ||
        (!checkData?.day_bucket &&
            !checkData?.days_bucket &&
            !checkData?.buckets) ||
        checkData?.day_bucket?.length === 0 ||
        checkData?.days_bucket?.length === 0 ||
        checkData?.buckets?.length === 0
    )
  }

  /**
   * Tooltip formatter for line and bar charts
   * @param {Array} series
   * @returns {String} HTML string
   */
  function tooltipFormatter(series) {

    const { axisValue: axisDate } = series[0]
    let html = ''

    let headerHTML ='';

    if(momentWrapper(axisDate).isValid()){
      const date = momentWrapper(axisDate).formatDate()
      headerHTML = `<span class="text-[#000D21] font-weight-300">${date}</span>`
    }
    else{
      headerHTML = ` <span class="text-[#000D21] font-weight-300">${axisDate}</span>`
    }

    series.forEach((series) => {
      const { seriesName, data: value } = series

      html += `
    <div class="flex items-end justify-between mt-2.5">
      <span class="text-[979CA0] text-sm mr-1.5">${seriesName}: </span>
      <span class="text-[#69788E] text-sm font-bold">${value}</span>
    </div>`
    })

    return `
      <div class="flex flex-col mb-2 pb-2">
        <div class="flex items-center">
          ${headerHTML}
        </div>
          ${html}
      </div>
  `
  }
    /**
   * Tooltip formatter for pie charts
   * @param {Array} series
   * @returns {String} HTML string
   */
    function tooltipFormatterPieChart(series) {
      const { data } = series
      console.log(series)
      console.log(data)
      let html = ''

      const headerHTML = ` <span class="text-[#000D21] font-weight-300">${data?.name}</span>`
        html += `
      <div class="flex items-end justify-between mt-2.5">
        <span class="text-[#69788E] text-sm font-bold">${data?.value}</span>
      </div>`

      return `
        <div class="flex flex-col">
          <div class="flex items-center">
            ${headerHTML}
          </div>
            ${html}
        </div>
    `
    }

  function tooltipFormatterBarChart(series) {
      return (
        tooltipFormatter(series) +
        '<span class="italic border border-t !border-l-0 !border-r-0 !border-b-0 p-1 text-sm mt-2 pt-2">Click to view posts</span>'
      )
    }
  /**
   * Tooltip formatter for grid layout
   * @param {Array} series
   * @returns {String} HTML string
   */
  function tooltipFormatterScroll(series) {

    const { axisValue: axisDate } = series[0]
    const date = momentWrapper(axisDate).formatDate()
    let html = ''

    series.forEach((series) => {
      const { seriesName, data: value } = series

      const name = seriesName.split(ANALYTICS_DELIMITER)[0] ?? seriesName
      html += `
      <div class="flex items-end justify-between mt-2.5">
        <span class="text-[979CA0] text-sm mr-1.5 max-w-[120px] text-ellipsis truncate">${name}: </span>
        <span class="text-[#69788E] text-sm font-bold">${value}</span>
      </div>`
    })

    return `
    <div class="flex flex-col max-h-[300px] overflow-auto">
      <div class="flex items-center">
        <span class="text-[#000D21] font-weight-300">${date}</span>
      </div>
        ${html}
    </div>
  `
  }
  function axisLabelFormatter(value) {
    const formattedValue = formatNumber(value)
    return value > 0
        ? `{positive|+${formattedValue}}`
        : value === 0
            ? `{neutral|${formattedValue}}`
            : `{negative|${formattedValue}}`
  }

  const generateUniqueStacks = (charts) => [
    ...new Set(charts?.map((chart) => chart?.stack)),
  ]

  const generateYAxisName = (charts, stack) => {
    let string = ''
    const titleString = `{b|${stack}}`
    charts.forEach((chart) => {
      if (chart?.stack === stack) {
        string += `{${chart?.name.split(' ').join('')}|} `
      }
    })
    return string + titleString
  }

  const generateRichtextStyle = (charts, stack) => {
    const richData = {}
    charts?.forEach((chart) => {
      if (chart?.stack === stack) {
        richData[chart?.name.split(' ').join('')] = {
          backgroundColor: chart?.color,
          width: 11,
          height: 11,
          borderRadius: 2,
          display: 'inline-block',
          textAlign: 'center',
          lineHeight: 14,
          fontSize: 12,
        }
      }
    })
    return {
      rich: {
        ...richData,
        b: {
          color: '#979CA0',
          fontSize: 12,
          padding: [0, 0, 0, 6],
        },
      },
    }
  }

  const generateYAxisConfigs = (charts, stacks) =>
    stacks.map((stack, i) => ({
      type: 'value',
      sort: 'ascending',
      name: generateYAxisName(charts, stack),
      nameTextStyle: generateRichtextStyle(charts, stack),
      position: charts?.find((chart) => chart?.stack === stack)?.position,
      nameLocation: 'center',
      nameGap: 40,
      axisLabel: {
        color: '#979CA0',
        fontSize: 12,
        fontWeight: 400,
        formatter: (value) => value?.toFixed(0),
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
        interval: 'int',
        lineStyle: {
          color: '#E9EFF6',
        },
      },
      axisLine: {
        lineStyle: {
          color: '#F2F4F6',
        },
      },
      axisPointer: {
        label: {
          backgroundColor: charts?.find((chart) => chart?.stack === stack)?.color,
          color: 'white',
          formatter: (dataPoint) =>
            formatNumber(dataPoint?.value) ?? dataPoint.value,
        },
      },
      splitLine: {
        show: i === 0,
        lineStyle: {
          color: '#F2F4F6',
        },
      },
    }))

  const generateStackedChartOptions = (charts) => {
    const stacks = generateUniqueStacks(charts)

    return {
      tooltip: barChartOptions.value.tooltip,
      grid: barChartOptions.value.grid,
      dataZoom: barChartOptions.value.dataZoom,
      xAxis: barChartOptions.value.xAxis,
      legend: undefined,
      yAxis: generateYAxisConfigs(charts, stacks),
      series: [],
    }
  }

  return {
    analyticsDesignSystem,
    DEFAULT_DATE_RANGE,
    ANALYTICS_DELIMITER,
    lineChartOptions,
    barChartOptions,
    pieChartOptions,
    multipleSeriesBarChartOptions,
    multipleSeriesLineChartOptions,
    defaultChartOptions,
    dataZoomOptions,
    BASE_ENDPOINT,
    TWITTER_JOB_ENDPOINT,
    BASIC_ENDPOINT,
    LABEL_AND_CAMPAIGNS_BASE_ENDPOINT,
    legendOptions,
    isReportView,
    screenWidth,
    isNoAnalyticsData,
    axisLabelFormatter,
    tooltipFormatterScroll,
    generateStackedChartOptions,
    tooltipFormatterBarChart,
  }
}
