<script setup>
import { computed, watch, defineProps } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/linkedin_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useLinkedinAnalytics from '@src/modules/analytics/views/linkedin_v2/composables/useLinkedinAnalytics.js'

// state
const {
  overviewPostsPerDay,
  dataZoomOptions,
  barChartOptions: chartOptions,
  isReportView,
  isLoadingStates,
  routes,
} = useLinkedinAnalytics('Posts')

defineProps({
  type: {
    type: String,
    default: 'All',
  },
})

const isNoData = computed(() => {
  const data = overviewPostsPerDay.value || {}
  const isAllZero = Object.values(data).every((value) => value === 0)
  return Object.keys(data).length === 0 || isAllZero
})

watch(
  () => overviewPostsPerDay.value,
  () => {
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ]
    const options = overviewPostsPerDay.value || {}
    chartOptions.value.series[0].data = days.map((day) => options?.[day] || 0)
    chartOptions.value.series[0].barMaxWidth = 32

    chartOptions.value.xAxis.data = days
    chartOptions.value.xAxis.axisLabel.formatter = null
  },
  {
    deep: true,
    immediate: true,
  }
)
const isLoading = computed(
  () => isLoadingStates.value?.[routes.OVERVIEW_POSTS_PER_DAY]
)
</script>

<template>
  <AnalyticsCardWrapper type="post-density" :enable-modal="true">
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Post Density - Daily
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Track the number of active users on your page by days over the
                  selected time period.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          :chart-options="
            slotProps.isModal
              ? {
                  ...chartOptions,
                  ...dataZoomOptions,
                  grid: { ...chartOptions.grid, bottom: 70 },
                }
              : chartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
