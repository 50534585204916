<script setup>
import { ref, computed } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import AnalyticsPostsTable from '@src/modules/analytics/views/common/AnalyticsPostsTable.vue'
import TiktokPostModal from '@src/modules/analytics/views/tiktok/components/TiktokPostModal.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import useTiktokAnalytics from '@/src/modules/analytics/views/tiktok/composables/useTiktokAnalytics'
import { EventBus } from '@/src/modules/common/lib/event-bus'

// state

const {
  routes,
  selectedAccount,
  allPosts,
  postsLimit,
  isPostDataLoading,
  isReportView,
  fetchMedia,
  validPostsTableHeaders,
  getHeaderTitles,
  getBodyValues,
  getHeadersTooltips,
  nonSortableItems,
  customThumbnailKey,
  getHeaderApiKey,
  validPostsTableApiKeys,
} = useTiktokAnalytics()

defineProps({
  rangeStartValue: {
    type: Number,
    default: 0,
  },
  rangeEndValue: {
    type: Number,
    default: 10,
  },
})
const selectedSortKey = ref(validPostsTableApiKeys.total_engagement)

const dropDownValues = computed(() => {
  const range = []
  for (let i = 10; i <= 100; i += 10) {
    range.push(i)
  }
  return range
})

const handleDropDownClick = async (value) => {
  EventBus.$emit('set-top-posts-limit', value)
  postsLimit.value = value
  await fetchMoreData()
}

const handleSortClick = async (header) => {
  const apiKey = getHeaderApiKey(header)
  if (apiKey) {
    selectedSortKey.value = apiKey
    await fetchMoreData()
  }
}

/**
 * Asynchronously fetches more data based on the current offset and selected sorting order.
 * The function checks if there is more data to fetch by comparing the current offset
 * with the total number of account posts. If there is more data available, it increments
 * the current offset and makes an asynchronous call to fetch additional data.
 *
 * @async
 * @function
 * @name fetchMoreData
 * @returns {Promise<void>}
 * @throws {Error} Throws an error if the data fetching operation fails.
 * @example
 * // Usage example:
 * await fetchMoreData();
 */
const fetchMoreData = async () => {
  await fetchMedia(routes.POSTS_DATA, {
    limit: postsLimit.value,
    sort_order: selectedSortKey.value,
  })
}
</script>

<template>
  <AnalyticsCardWrapper custom-class="overflow-x-auto">
    <template v-slot:card-header>
      <div v-if="!isReportView" class="flex justify-between items-center w-full">
        <div class="mx-1">
          <h2 class="text-secondary-cs text-base font-medium">
            Tiktok Top Posts {{ isReportView ? `(Top ${postsLimit})` : '' }}
          </h2>
          <p class="text-sm text-secondary-cs-700">
            Review your top posts published during the selected time period,
            based on the post's lifetime performance.
          </p>
        </div>
        <CstDropdown
          v-if="!isReportView && !isPostDataLoading && allPosts?.length > 0"
          dropdown-placement="bottom"
          container-classes="!max-h-96"
          dropdown-classes= '!rounded-md !border-[#70707029]'
          button-classes="flex !px-5 !w-28 !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
        >
          <template v-slot:arrow>
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </template>
          <template v-slot:selected>
            <p class="text-base font-weight-500 capitalize">
              Top {{ !isReportView && postsLimit }}
            </p>
          </template>
          <template v-slot>
            <CstDropdownItem
              v-for="(value, index) in dropDownValues"
              :key="`activity_type_${index}`"
              @click="handleDropDownClick(value)"
            >
              <p class="text-gray-900 text-sm">{{ value }}</p>
            </CstDropdownItem>
          </template>
        </CstDropdown>
      </div>
    </template>
    <template v-slot:card-body>
      <AnalyticsPostsTable
        :selected-account="selectedAccount"
        :data-list="allPosts"
        :valid-headers-list="validPostsTableHeaders"
        :non-sortable-items="nonSortableItems"
        :mutate-header-titles="getHeaderTitles"
        :mutate-body-values="getBodyValues"
        :mutate-header-tooltips="getHeadersTooltips"
        :is-report-view="isReportView"
        :range-start-value="rangeStartValue"
        :range-end-value="rangeEndValue"
        :is-loading="isPostDataLoading"
        :custom-thumbnail-key="customThumbnailKey"
        @update-sort="handleSortClick"
      >
        <template v-slot:post-modal="{ selectedPost }">
          <TiktokPostModal
            v-if="selectedPost"
            :selected-account="selectedAccount"
            :selected-post="selectedPost"
          />
        </template>
      </AnalyticsPostsTable>
    </template>
  </AnalyticsCardWrapper>
</template>
