import moment from 'moment'
import * as linkify from 'linkifyjs'
import linkifyHtml from 'linkify-html'
// import 'linkify-plugin-mention';
import 'linkify-plugin-hashtag'
import { IMG_PROXY_KEY, IMG_PROXY_SALT, IMG_PROXY_URL } from '@/src/config/api-utils'

const createHmac = require('create-hmac')

const KEY = IMG_PROXY_KEY
const SALT = IMG_PROXY_SALT
const RESIZED_API_URL = IMG_PROXY_URL

export const helperMixin = {
  methods: {
    /**
     * Method is responsible for checking query params and populate an object
     */
    getFrillParams() {
      const postData = {}
      if (this.$route.query.source) {
        postData.source = this.$route.query.source
      }
      if (this.$route.query.redirect) {
        postData.redirect = this.$route.query.redirect
      }
      return postData
    },

    // We are not using signed resized URL because it's too slow. Will you it once we will be able to reduced it's waiting time
    getSignedResizedImageURL(
      url,
      width = 0,
      height = 450,
      resizingType = 'auto',
      gravity = 'sm',
      enlarge = 0,
      extension = 'png'
    ) {
      // Signing the URL and Calculating the HMAC digest using SHA256
      const sign = (salt, target, secret) => {
        const hexToBin = (hex) => {
          const buffer = Buffer.from(hex, 'hex')
          if (buffer.length === 0) {
            throw new Error('Key/Salt expected to be hex-encoded string')
          }
          return buffer
        }

        const saltBin = hexToBin(salt)
        const keyBin = hexToBin(secret)

        // Create message by concatenating salt and target as raw binary
        const message = Buffer.concat([saltBin, Buffer.from(target, 'utf8')])

        // Generate HMAC using the binary key
        const hmac = createHmac('sha256', keyBin)
        hmac.update(message)
        const signature = hmac.digest()

        // Take first 32 bytes and base64 encode
        const truncatedSignature = signature.slice(0, 32)
        return Buffer.from(truncatedSignature)
          .toString('base64')
          .replace(/\+/g, '-')
          .replace(/\//g, '_')
          .replace(/=/g, '')
      }

      // Check if the image is gif
      if (/.gif/gi.test(url)) {
        return url
      } else {
        // Construct the path with all parameters
        const path = `/resize:${resizingType}:${width}:${height}:${gravity}:${enlarge}/plain/${encodeURIComponent(url)}`
        const signature = sign(SALT, path, KEY)
        return `${RESIZED_API_URL}${signature}${path}`
      }
    },

    getResizedImageURL(
      url,
      width = 0,
      height = 450,
      resizingType = 'auto',
      gravity = 'sm',
      enlarge = 0
    ) {
      // Check if the image is gif
      try {
        if (/.gif/gi.test(url)) {
          return url
        } else {
          return this.getSignedResizedImageURL(url, width, height, resizingType, gravity, enlarge)
        }
      } catch (e) {
        // console.error(e)
        return url
      }
    },

    randomNumber() {
      const min = 200
      const max = 1000
      return Math.random() * (+max - +min) + +min
    },

    getRandomInt(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min) + min) // The maximum is exclusive and the minimum is inclusive
    },

    isJsonObjectEmpty(obj) {
      for (const key in obj) {
        if (key in obj) {
          return false
        }
      }
      return true
    },

    trimURL(url) {
      if (url !== undefined) {
        url = url.replace('/xmlrpc.php', '')
        url = url.replace('http://', '')
        url = url.replace('https://', '')
        url = url.replace('www.', '')

        if (url.slice(-1) === '/') {
          url = url.substring(0, url.length - 1)
        }

        return url
      } else {
        return ''
      }
    },

    extractHostname(url) {
      let hostname
      // find & remove protocol (http, ftp, etc.) and get hostname

      if (url.indexOf('//') > -1) {
        hostname = url.split('/')[2]
      } else {
        hostname = url.split('/')[0]
      }

      // find & remove port number
      hostname = hostname.split(':')[0]
      // find & remove "?"
      hostname = hostname.split('?')[0]

      return hostname
    },
    // To address those who want the "root domain," use this function:
    extractRootDomain(url) {
      let domain = this.extractHostname(url)
      const splitArr = domain.split('.')
      const arrLen = splitArr.length

      // extracting the root domain here
      // if there is a subdomain
      if (arrLen > 2) {
        domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1]
        // check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
        if (
          splitArr[arrLen - 2].length === 2 &&
          splitArr[arrLen - 1].length === 2
        ) {
          // this is using a ccTLD
          domain = splitArr[arrLen - 3] + '.' + domain
        }
      }
      return domain
    },

    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },

    switchVisibility(event, selector) {
      console.debug('Method:switchVisibility')
      selector = document.querySelector('#' + selector)
      if (selector.getAttribute('type') === 'password') {
        selector.setAttribute('type', 'text')
        // eslint-disable-next-line no-undef
        $(event.currentTarget).removeClass('cs-eye').addClass('cs-eye-close')
      } else {
        selector.setAttribute('type', 'password')
        // eslint-disable-next-line no-undef
        $(event.currentTarget).removeClass('cs-eye-close').addClass('cs-eye')
      }
    },

    maskingValidationStatus(value) {
      if (value) {
        const res = value.split(':')
        if (res[0] && res[1]) {
          if (res[0] >= 0 && res[0] <= 23 && res[1] >= 0 && res[1] <= 59) {
            return true
          }
        }
      }
      return false
    },
    isStorageLink(url) {
      return (
        url.match(/(storage.googleapis\.com\/lumotive-web)/g) ||
        url.match(/(storage.googleapis\.com\/contentstudio-)/g)
      )
    },
    intToString(value) {
      // console.debug(value)
      // Nine Zeroes for Billions
      return Math.abs(Number(value)) >= 1.0e9
        ? Number((Math.abs(Number(value)) / 1.0e9).toFixed(1)) + 'B'
        : // Six Zeroes for Millions
        Math.abs(Number(value)) >= 1.0e6
        ? Number((Math.abs(Number(value)) / 1.0e6).toFixed(1)) + 'M'
        : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
        ? Number((Math.abs(Number(value)) / 1.0e3).toFixed(1)) + 'K'
        : Number(value) === value && value % 1 !== 0
        ? Math.abs(Number(value)).toFixed(2)
        : Math.abs(Number(value))
    },

    isValidUrl(url) {
      // let regexp = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig //not validating http://j.mp/2gAYmSN
      const regexp =
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      return regexp.test(url)
    },

    generateRandomId() {
      let text = ''
      const possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

      for (let i = 0; i < 30; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      }
      return text
    },

    // receive time in unix and return fromNow
    unixFromNow(unixtime) {
      return moment(moment.unix(unixtime)).local().fromNow()
    },

    cleanArray(actual) {
      const newArray = []
      for (let i = 0; i < actual.length; i++) {
        if (actual[i]) {
          newArray.push(actual[i])
        }
      }
      return newArray
    },

    getLinksFromText(text) {
      // return text.match(/((ftp|http|https):\/\/)?[-a-zA-Z0-9@:%._/\+~#=]{2,256}\.[a-z]{2,15}\b([-a-zA-Z0-9@:%_\+.,~#?&//=]*)/g) //not validating http://j.mp/2gAYmSN
      return text.match(
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g
      )
    },

    /**
     * This method will return the links found in the text provided.
     * @param text
     * @returns null|array
     */
    getLinks(text) {
      const links = linkify.find(text, 'url')
      if (links.length === 0) return null
      return links.map((link) => link.value)
    },

    removeLinksFromText(text) {
      const urls = this.getLinks(text)
      // eslint-disable-next-line no-undef
      $(urls).each(function (index, el) {
        text = text.replace(el, '')
      })
      return text
    },

    /**
     * This method will return the html base on the string.
     * @param text
     * @returns string
     */
    stringToHtml(text) {
      console.debug('stringToHtml', text)
      return linkifyHtml(text)
    },

    stringToHtmlTwitter(text) {
      return linkifyHtml(text, {
        formatHref: function (href, type) {
          if (type === 'hashtag') {
            href = 'https://twitter.com/hashtag/' + href.substring(1)
          }

          if (type === 'mention') {
            href = 'https://twitter.com/' + href.substring(1)
          }
          return href
        },
        target: '_blank',
      })
    },

    /**
     * This method will return the mentions in the text provided.
     * @param text
     * @returns {*}
     */
    getMentions(text) {
      const mentions = linkify.find(text, 'mention')
      if (mentions.length === 0) return null
      return mentions.map((link) => link.value)
    },

    /**
     * This method will return the mentions in the text provided.
     * @param text
     * @returns {*}
     */
    getHashTags(text) {
      const mentions = linkify.find(text, 'hashtag')
      if (mentions.length === 0) return null
      return mentions.map((link) => link.value)
    },

    /**
     * This method is use to find and replace substr in the description.
     * @param text
     * @param find
     * @param replace
     * @returns {*}
     */

    replaceAll(text, find, replace) {
      // eslint-disable-next-line no-extend-native
      String.prototype.replaceAllPrototype = function (search, replacement) {
        return this.split(search).join(replacement)
      }
      return find ? text.replaceAllPrototype(find, replace) : text
    },

    /**
     * This method is use to find starting and ending position of a substr in the description.
     * @param searchStr
     * @param str
     * @param caseSensitive
     * @returns {Array}
     */
    getIndicesOf(searchStr, str, caseSensitive) {
      const searchStrLen = searchStr.length

      // checking search string length
      if (searchStrLen === 0) {
        return []
      }
      let startIndex = 0
      let index
      const indices = []

      // ignoring the case sensitivity
      if (!caseSensitive) {
        str = str.toLowerCase()
        searchStr = searchStr.toLowerCase()
      }

      // searching in the text and adding the staring and ending index positions of all the occurrences.
      while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push({
          start: index,
          end: index + searchStr.length,
        })
        startIndex = index + searchStrLen
      }
      return indices
    },

    replaceIgnoringCase(text, search, replace) {
      const regEx = new RegExp(search, 'ig')
      return text.replace(regEx, replace)
    },

    getTextMentions(searchText) {
      const regexp = /\B@\w\w+\b/g
      return searchText.match(regexp)
    },

    getTextHashTags(searchText) {
      const regexp = /\B#\w\w+\b/g
      return searchText.match(regexp)
    },

    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0 Byte'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

      const value = bytes / Math.pow(1024, i)
      return Math.round(value * 100) / 100 + ' ' + sizes[i]
    },

    jsonStringifyData(data) {
      return JSON.parse(JSON.stringify(data))
    },

    formatNumber(number) {
      const formatter = Intl.NumberFormat('en', { notation: 'compact' })
      return formatter.format(number)
    },

    alertMessageReturn(message, type) {
      this.alertMessage(message, type)
      return false
    },

    toUnicodeVariant(str, variant, flags) {
      console.log(
        'METHOD::toUnicodeVariant ~ str, variant, flags -> ',
        str,
        variant,
        flags
      )
      const offsets = {
        m: [0x1d670, 0x1d7f6],
        b: [0x1d400, 0x1d7ce],
        i: [0x1d434, 0x00030],
        bi: [0x1d468, 0x00030],
        c: [0x1d49c, 0x00030],
        bc: [0x1d4d0, 0x00030],
        g: [0x1d504, 0x00030],
        d: [0x1d538, 0x1d7d8],
        bg: [0x1d56c, 0x00030],
        s: [0x1d5a0, 0x1d7e2],
        bs: [0x1d5d4, 0x1d7ec],
        is: [0x1d608, 0x00030],
        bis: [0x1d63c, 0x00030],
        o: [0x24b6, 0x2460],
        p: [0x249c, 0x2474],
        w: [0xff21, 0xff10],
        u: [0x2090, 0xff10],
      }

      const variantOffsets = {
        monospace: 'm',
        bold: 'b',
        italic: 'i',
        'bold italic': 'bi',
        script: 'c',
        'bold script': 'bc',
        gothic: 'g',
        'gothic bold': 'bg',
        doublestruck: 'd',
        sans: 's',
        'bold sans': 'bs',
        'italic sans': 'is',
        'bold italic sans': 'bis',
        parenthesis: 'p',
        circled: 'o',
        fullwidth: 'w',
      }

      // special characters (absolute values)
      const special = {
        m: {
          ' ': 0x2000,
          '-': 0x2013,
        },
        i: {
          h: 0x210e,
        },
        g: {
          C: 0x212d,
          H: 0x210c,
          I: 0x2111,
          R: 0x211c,
          Z: 0x2128,
        },
        o: {
          0: 0x24ea,
          1: 0x2460,
          2: 0x2461,
          3: 0x2462,
          4: 0x2463,
          5: 0x2464,
          6: 0x2465,
          7: 0x2466,
          8: 0x2467,
          9: 0x2468,
        },
        p: {},
        w: {},
      }
      // support for parenthesized latin letters small cases
      for (let i = 97; i <= 122; i++) {
        special.p[String.fromCharCode(i)] = 0x249c + (i - 97)
      }
      // support for full width latin letters small cases
      for (let j = 97; j <= 122; j++) {
        special.w[String.fromCharCode(j)] = 0xff41 + (j - 97)
      }

      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      const bChars = '𝗔𝗕𝗖𝗗𝗘𝗙𝗚𝗛𝗜𝗝𝗞𝗟𝗠𝗡𝗢𝗣𝗤𝗥𝗦𝗧𝗨𝗩𝗪𝗫𝗬𝗭𝗮𝗯𝗰𝗱𝗲𝗳𝗴𝗵𝗶𝗷𝗸𝗹𝗺𝗻𝗼𝗽𝗾𝗿𝘀𝘁𝘂𝘃𝘄𝘅𝘆𝘇'
      const biChars = '𝘼𝘽𝘾𝘿𝙀𝙁𝙂𝙃𝙄𝙅𝙆𝙇𝙈𝙉𝙊𝙋𝙌𝙍𝙎𝙏𝙐𝙑𝙒𝙓𝙔𝙕𝙖𝙗𝙘𝙙𝙚𝙛𝙜𝙝𝙞𝙟𝙠𝙡𝙢𝙣𝙤𝙥𝙦𝙧𝙨𝙩𝙪𝙫𝙬𝙭𝙮𝙯'
      const iChars = '𝘈𝘉𝘊𝘋𝘌𝘍𝘎𝘏𝘐𝘑𝘒𝘓𝘔𝘕𝘖𝘗𝘘𝘙𝘚𝘛𝘜𝘝𝘞𝘟𝘠𝘡𝘢𝘣𝘤𝘥𝘦𝘧𝘨𝘩𝘪𝘫𝘬𝘭𝘮𝘯𝘰𝘱𝘲𝘳𝘴𝘵𝘶𝘷𝘸𝘹𝘺𝘻'
      const numbers = '0123456789'
      const bNumbers = '𝟬𝟭𝟮𝟯𝟰𝟱𝟲𝟳𝟴𝟵'

      const getType = function (variant) {
        if (variantOffsets[variant]) return variantOffsets[variant]
        if (offsets[variant]) return variant
        return 's' // monospace as default
      }
      const getFlag = function (flag, flags) {
        if (!flags) return false
        return flags.split(',').indexOf(flag) > -1
      }

      const type = getType(variant)
      const underline = getFlag('underline', flags)
      const strike = getFlag('strike', flags)
      let result = ''

      for (const k of str) {
        let index
        let c = k
        if (special[type] && special[type][c])
          c = String.fromCodePoint(special[type][c])
        if (type && (index = chars.indexOf(c)) > -1) {
          result += String.fromCodePoint(index + offsets[type][0])
        } else if (type && (index = numbers.indexOf(c)) > -1) {
          result += String.fromCodePoint(index + offsets[type][1])
        } else {
          if (
            bChars.indexOf(c) > -1 ||
            iChars.indexOf(c) > -1 ||
            biChars.indexOf(c) > -1 ||
            bNumbers.indexOf(c) > -1
          ) {
            let original = ''

            if (variant === 'bs') {
              if (this.isItalicCharacters(c, iChars)) {
                original = chars[Math.ceil(iChars.indexOf(c) / 2)]
                result += this.toUnicodeVariant(original, 'bis')
              } else if (this.isBoldItalicCharacters(c, biChars)) {
                original = chars[Math.ceil(biChars.indexOf(c) / 2)]
                result += this.toUnicodeVariant(original, 'is')
              } else if (this.isBoldCharacters(c, bChars)) {
                original = chars[Math.ceil(bChars.indexOf(c) / 2)]
                result += original
              } else if (this.isBoldNumbers(c, bNumbers)) {
                original = numbers[Math.ceil(bNumbers.indexOf(c) / 2)]
                result += original
              } else {
                result += c
              }
            } else if (variant === 'is') {
              if (this.isBoldCharacters(c, bChars)) {
                original = chars[Math.ceil(bChars.indexOf(c) / 2)]
                result += this.toUnicodeVariant(original, 'bis')
              } else if (this.isBoldItalicCharacters(c, biChars)) {
                original = chars[Math.ceil(biChars.indexOf(c) / 2)]
                result += this.toUnicodeVariant(original, 'bs')
              } else if (this.isItalicCharacters(c, iChars)) {
                original = chars[Math.ceil(iChars.indexOf(c) / 2)]
                result += original
              } else {
                result += c
              }
            }
          } else {
            result += c
          }
        }
        if (underline) result += '\u0332' // add combining underline
        if (strike) result += '\u0336' // add combining strike
      }
      return result
    },

    isItalicCharacters(s, iChars) {
      return iChars.includes(s)
      // return /[^\ud835\udc00\ud835\udc01\ud835\udc02\ud835\udc03\ud835\udc04\ud835\udc05\ud835\udc06\ud835\udc07\ud835\udc08\ud835\udc09\ud835\udc0a\ud835\udc0b\ud835\udc0c\ud835\udc0d\ud835\udc0e\ud835\udc0f\ud835\udc10\ud835\udc11\ud835\udc12\ud835\udc13\ud835\udc14\ud835\udc15\ud835\udc16\ud835\udc17\ud835\udc18\ud835\udc19\ud835\udc1a\ud835\udc1b\ud835\udc1c\ud835\udc1d\ud835\udc1e\ud835\udc1f\ud835\udc20\ud835\udc21\ud835\udc22\ud835\udc23\ud835\udc24\ud835\udc25\ud835\udc26\ud835\udc27\ud835\udc28\ud835\udc29\ud835\udc2a\ud835\udc2b\ud835\udc2c\ud835\udc2d\ud835\udc2e\ud835\udc2f\ud835\udc30\ud835\udc31\ud835\udc32\ud835\udc33]/.test(s)
    },

    isBoldCharacters(s, bChars) {
      return bChars.includes(s)
      // return /[^\ud835\udc34\ud835\udc35\ud835\udc36\ud835\udc37\ud835\udc38\ud835\udc39\ud835\udc3a\ud835\udc3b\ud835\udc3c\ud835\udc3d\ud835\udc3e\ud835\udc3f\ud835\udc40\ud835\udc41\ud835\udc42\ud835\udc43\ud835\udc44\ud835\udc45\ud835\udc46\ud835\udc47\ud835\udc48\ud835\udc49\ud835\udc4a\ud835\udc4b\ud835\udc4c\ud835\udc4d\ud835\udc4e\ud835\udc4f\ud835\udc50\ud835\udc51\ud835\udc52\ud835\udc53\ud835\udc54\u210e\ud835\udc56\ud835\udc57\ud835\udc58\ud835\udc59\ud835\udc5a\ud835\udc5b\ud835\udc5c\ud835\udc5d\ud835\udc5e\ud835\udc5f\ud835\udc60\ud835\udc61\ud835\udc62\ud835\udc63\ud835\udc64\ud835\udc65\ud835\udc66\ud835\udc67]/.test(s)
    },
    isBoldNumbers(s, bNumbers) {
      return bNumbers.includes(s)
    },

    isBoldItalicCharacters(s, biChars) {
      return biChars.includes(s)
      // return /[^\ud835\udc34\ud835\udc35\ud835\udc36\ud835\udc37\ud835\udc38\ud835\udc39\ud835\udc3a\ud835\udc3b\ud835\udc3c\ud835\udc3d\ud835\udc3e\ud835\udc3f\ud835\udc40\ud835\udc41\ud835\udc42\ud835\udc43\ud835\udc44\ud835\udc45\ud835\udc46\ud835\udc47\ud835\udc48\ud835\udc49\ud835\udc4a\ud835\udc4b\ud835\udc4c\ud835\udc4d\ud835\udc4e\ud835\udc4f\ud835\udc50\ud835\udc51\ud835\udc52\ud835\udc53\ud835\udc54\u210e\ud835\udc56\ud835\udc57\ud835\udc58\ud835\udc59\ud835\udc5a\ud835\udc5b\ud835\udc5c\ud835\udc5d\ud835\udc5e\ud835\udc5f\ud835\udc60\ud835\udc61\ud835\udc62\ud835\udc63\ud835\udc64\ud835\udc65\ud835\udc66\ud835\udc67]/.test(s)
    },

    /**
     * Format text applying Unicode variants (like bold, italic) only to parts of text enclosed by specific markers
     * @param {string} str - The text to format
     * @param {string} marker - The marker to identify text to format (e.g. '**' for bold)
     * @param {string} variant - The Unicode variant to apply ('b' for bold, 'i' for italic, etc.)
     * @param {string} flags - Optional flags for formatting
     * @returns {string} - Formatted text with Unicode variants applied only between markers
     */
    formatWithUnicodeVariant(str, marker, variant, flags){
      if (!str) return '';

      // Escape regex special characters in marker
      const escapedMarker = marker.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

      // Create regex to find text between markers, using non-greedy match
      const regex = new RegExp(`${escapedMarker}(.*?)${escapedMarker}`, 'g');

      // Replace text between markers with Unicode variant
      return str.replace(regex, (match, textBetweenMarkers) => {
        // Remove the markers and apply Unicode variant to the text between them
        return this.toUnicodeVariant(textBetweenMarkers, variant, flags);
      });
    },
  },
}
