import proxy from "@common/lib/http-common";
import {retryPostPublishURL} from "@src/modules/planner/config/api-utils";
import {platformPosting} from "@common/lib/planner";
import {getPlatformIdentifierValue} from "@common/lib/integrations";
import {useStore} from "@state/base";
import {ref} from "vue";

const { getters, dispatch } = useStore()
const retryLoader = ref(false)
export const learnMoreLink = (type) => {
    console.log('type', type)
    let link = ''
    switch (type) {
        case 'facebook':
            link = 'https://docs.contentstudio.io/article/688-facebook-errors'
            break
        case 'twitter':
            link =
                'https://docs.contentstudio.io/article/689-twitter-errors-while-publishing'
            break
        case 'linkedin':
            link =
                'https://docs.contentstudio.io/article/499-linkedin-post-failed-to-publish'
            break
        case 'pinterest':
            link =
                'https://docs.contentstudio.io/article/497-pinterest-post-failed-to-publish'
            break
        case 'instagram':
            link =
                'https://docs.contentstudio.io/article/693-instagram-errors-while-publishing'
            break
        case 'instagram_push_notification':
            link =
                'https://docs.contentstudio.io/article/732-how-to-post-stories-using-instagram-push-notification'
            break
        case 'gmb':
            link =
                'https://docs.contentstudio.io/article/575-troubleshooting-google-my-business'
            break
        case 'tiktok':
            link =
                'https://docs.contentstudio.io/article/923-how-to-post-to-tiktok-using-content-studio'
            break
        case 'youtube':
            link =
                'https://docs.contentstudio.io/article/996-youtube-errors-while-publishing'
            break
        case 'facebook_push_notification':
            link =
                'https://docs.contentstudio.io/article/1025-publishing-to-facebook-group-via-mobile-app-notification-method'
            break
    }
    return link
}

const platformPostingObj = (plan, type, account) => {
    return platformPosting(
        plan,
        type,
        getPlatformIdentifierValue(account, type)
    )
}

export const retryPosting = async (plan, account, type) => {
    if (retryLoader.value) return
    const payload = {
        posting_id: platformPostingObj(plan, type, account)._id,
        workspace_id: getters.getActiveWorkspace?._id,
    }
    retryLoader.value = true
    const postingIndex = plan.posting.findIndex(
        (posting) => posting._id === payload.posting_id
    )

    await proxy.post(retryPostPublishURL, payload).then(({ data }) => {
        const { status, message } = data
        const success = "success"
        const error = "error"
        if (status) {
            console.log('blog retrying', message)
            if (postingIndex > -1) {
                plan.posting[postingIndex].retrying = retryLoader.value
            }
            dispatch('toastNotification', {
                message, success
            })
        } else {
            dispatch('toastNotification', {
                message, error
            })
        }
    })
    retryLoader.value = false
}
