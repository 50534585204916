<script setup>
import { computed } from 'vue'

import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import useNumber from '@common/composables/useNumber'
import ReelsIcon from '@/src/assets/img/icons/analytic/colored/reels-icon.svg'
import VideoIcon from '@/src/assets/img/icons/analytic/colored/video-icon.svg'
import ImageIcon from '@/src/assets/img/icons/analytic/colored/image-colored-icon.svg'
import CarouselIcon from '@/src/assets/img/icons/analytic/colored/carousel-icon.svg'
import TotalIcon from '@/src/assets/img/icons/analytic/colored/total-colored-icon.svg'

import SkeletonBox from '@/src/modules/analytics/views/common/SkeletonBox'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

const props = defineProps({
  dataList: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  isReportView: {
    type: Boolean,
    default: false,
  },
})

const { formatNumber } = useNumber()
const { tooltipHtml } = useComposerHelper()

const headers = [
  'Media Type',
  'Total_Posts',
  'Engagement',
  'Likes',
  'Comments',
  'Saved',
  'Reach',
  'Impressions',
]

const computedDataList = computed(() => props.dataList || [])

const getMediaTypeIcon = (mediaType) => {
  switch (mediaType) {
    case 'REELS':
      return ReelsIcon
    case 'IMAGE':
      return ImageIcon
    case 'CAROUSEL_ALBUM':
      return CarouselIcon
    case 'VIDEO':
      return VideoIcon
    case 'TOTAL':
      return TotalIcon
    default:
      return null
  }
}

const getReadableMediaType = (mediaType) => {
  switch (mediaType) {
    case 'REELS':
      return 'Reels'
    case 'IMAGE':
      return 'Image'
    case 'CAROUSEL_ALBUM':
      return 'Carousel'
    case 'VIDEO':
      return 'Video'
    case 'TOTAL':
      return 'Total'
    default:
      return 'Unknown'
  }
}
const formatHeader = (header) => {
  if (header === 'Total_Posts') return 'Posts'
  return header.replace(/_/g, ' ')
}
</script>

<template>
  <AnalyticsCardWrapper :custom-class="'overflow-x-auto'">
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Publishing Behaviour Breakdown
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Compares performance metrics (engagement, reach, impressions)
                  across different Instagram post types (Reels, Images,
                  Carousels) to help optimize your content strategy.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body>
      <div
        v-if="computedDataList.length || props.isLoading"
        class="bg-white w-full shadow-sm select-none overflow-x-auto"
        :class="{ 'apply-min-width': !isReportView }"
      >
        <table class="w-full rounded-lg">
          <thead class="gap-0.5 flex xl:w-full rounded-md sticky z-[2] top-0">
            <th
              v-for="(header, index) in headers"
              :key="`table_header_${index}`"
              scope="col"
              class="flex justify-between items-center px-4 py-3 w-full text-secondary-cs bg-gray-200"
            >
              <span class="text-gray-900 text-sm font-medium">{{
                formatHeader(header)
              }}</span>
            </th>
          </thead>
          <tbody>
            <tr v-if="props.isLoading">
              <td colspan="8">
                <div class="p-4">
                  <SkeletonBox width="100%" height="320px" />
                </div>
              </td>
            </tr>
            <tr
              v-for="(row, rowIndex) in computedDataList"
              v-else
              :key="`table_row_${rowIndex}`"
              class="flex border-b border-t-0 border-l-0 border-r-0 border-gray-300 border-solid bg-white min-h-[3rem] hover:bg-gray-100"
            >
              <td
                v-for="(header, colIndex) in headers"
                :key="`table_cell_${rowIndex}_${colIndex}`"
                class="flex flex-1 items-center bg-white px-4 py-3"
                :class="{
                  '!bg-[#f5f5f5]': row.media_type === 'TOTAL',
                }"
              >
                <template v-if="colIndex === 0">
                  <div class="flex items-center">
                    <img
                      :src="getMediaTypeIcon(row.media_type)"
                      class="w-8 h-8 mr-2"
                      alt="Media Type Icon"
                    />
                    <span class="font-medium text-secondary-cs">{{
                      getReadableMediaType(row.media_type)
                    }}</span>
                  </div>
                </template>
                <span
                  v-else
                  v-tooltip="{
                    content:
                      row[header.toLowerCase()] === 'N/A'
                        ? 'Data not available'
                        : tooltipHtml(row[header.toLowerCase()]),
                    allowHTML: true,
                    theme: 'light',
                  }"
                  class="font-semibold text-secondary-cs"
                  :class="{
                    'font-normal': colIndex > 1 && row.media_type !== 'TOTAL',
                  }"
                >
                  {{
                    row[header.toLowerCase()] === 'N/A'
                      ? row[header.toLowerCase()]
                      : formatNumber(parseInt(row[header.toLowerCase()]))
                  }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="analytics-no-data-found">
        <img
          src="@/src/assets/img/no_data_images/no-analytical-data-available.svg"
          alt=""
        />
        <p>No data found for publishing behaviour breakdown</p>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>

<style scoped>
table {
  overflow-x: auto;
}
.apply-min-width th,
.apply-min-width td {
  min-width: 120px;
}
.apply-min-width th:first-child,
.apply-min-width td:first-child {
  min-width: 180px;
}
</style>
