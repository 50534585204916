<script setup>
import {ref, computed, inject} from 'vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import ConnectedAccountsDropdown from '@/src/modules/account/views/onboarding/ConnectedAccountsDropdown.vue'
import TwitterDropdown from '@/src/modules/account/views/onboarding/TwitterDropdown.vue'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'
import useSocialAccountsModal from '@/src/modules/account/composables/useSocialAccountsModal'
import { useStore } from '@/src/store/base'
const root = inject('root')
const { $bvModal } = root

const props = defineProps({
  platform: {
    type: Object,
    required: true,
  },
})

// declarations
const store = useStore()
const isActive = ref(false)
const isActiveTwitter = ref(false)
const showOnHover = ref(false)
const { getSocialImageRounded, showTypes, featureFlag } = useComposerHelper()
const { selectedPlatform, connectAccount, connectTwitterCustomApp } =
    useSocialAccountsModal()

// methods
const onDropdownStateChange = (state) => {
  if (state) {
    isActive.value = true
  }

  if (!state) {
    setTimeout(() => (isActive.value = false), 150)
  }
}

const onDropdownStateChangeTwitter = (state) => {
  if (state) {
    isActiveTwitter.value = true
  }

  if (!state) {
    setTimeout(() => (isActiveTwitter.value = false), 150)
  }
}

const handleConnect = (type = '') => {
  selectedPlatform.value = props.platform.name

  if(props.platform.name === 'bluesky') {
    $bvModal.show('add-bluesky-modal')
    return
  }

  connectAccount('connect',type)
}

const handleTwitterCustomApp = (_id) => {
  selectedPlatform.value = props.platform.name

  connectTwitterCustomApp(_id)
}

const onHover = () => {
  showOnHover.value = true
}

// computed properties
const accounts = computed(() => {
  const items = store.getters.getAllPlatforms[props.platform.name]

  return items?.map((item) => ({
    ...item,
    channel_type: props.platform.name,
  })) ?? []
})

</script>

<template>
  <div
      class="
      flex
      relative
      w-full
      h-[3.25rem]
      mb-[0.5rem]
      rounded-[0.5rem]
      justify-between
      align-items-center
      p-[1rem]
      bg-white
      hover:!border-[#409aff]
      border
    "
      :class="(isActive && 'z-10') || (isActiveTwitter && 'z-10')"
  >
    <div class="flex align-items-center gap-2">
      <!-- Platform name & Logo -->
      <img
          :src="getSocialImageRounded(platform.name)"
          :class="[platform.name === 'twitter' ? 'h-[1.6rem] w-[1.6rem]' : 'h-[1.8rem] w-[1.8rem]']"
          alt="Social Image"
      />
      <p class="text-[0.675rem] xl:text-[0.875rem] font-normal">
        {{ platform.label }}
        <span
            v-if="showTypes(platform.types)"
            class="text-[#757A8A] text-[0.675rem] xl:text-xs"
        >
          ({{ platform.types.join(' & ') }})
        </span>
        <img v-if="platform.name === 'bluesky'" src="/img/new_tag.svg" alt="new-tag" class="ml-1">
      </p>
    </div>

    <div class="flex justify-between align-items-center gap-14">


      <!-- Connect -->
      <!-- <div v-if="platform.name === 'twitter'" class="flex items-center flex-row-reverse gap-x-4">
        <TwitterDropdown
            :total-accounts="accounts?.length"
            @on-dropdown-state-change="onDropdownStateChangeTwitter"
            @on-connect="handleConnect"
            @on-connect-custom-app="handleTwitterCustomApp"
        />
        <ConnectedAccountsDropdown
            :platform="platform"
            class="cursor-pointer"
            @on-dropdown-state-change="onDropdownStateChange"
        />
      </div> -->

      <span
          class="
          cursor-pointer
          rounded-lg
          px-2
          flex
          items-center
          gap-x-4
        "
      >
        <ConnectedAccountsDropdown
            :platform="platform"
            class="cursor-pointer"
            @on-dropdown-state-change="onDropdownStateChange"
        />
         <!-- Facebook Dropdown -->
         <template v-if="platform.name === 'facebook'">
            <!-- Plus icon visible when not hovered -->
            <div>
              <CstDropdown
              :show-arrow="false"
              dropdown-classes="!w-48"
              button-classes="!border-none !bg-white !p-0 !m-0 "
              :open="showOnHover"
              :show-on-hover="true"
              @onClose="showOnHover = false"
              >
                <template v-slot:selected >
                  <img  src="@assets/img/icons/onboarding/add.svg" alt="add" class="w-5 h-5 " @mouseover="onHover">
                </template>
                <CstDropdownItem @click="handleConnect">
                  Connect Facebook Pages
                </CstDropdownItem>
                <CstDropdownItem @click="handleConnect('facebook-profile')">
                  Connect Facebook Profile
                </CstDropdownItem>
              </CstDropdown>
            </div>
        </template>

        <div v-else-if="platform.name === 'instagram'" class="flex items-center flex-row-reverse gap-x-4">
        <CstDropdown
              :show-arrow="false"
              dropdown-classes="!w-72"
              button-classes="!border-none !bg-white !p-0 !m-0 "
              :open="showOnHover"
              :show-on-hover="true"
              @onClose="showOnHover = false"
              >
                <template v-slot:selected >
                  <img  src="@assets/img/icons/onboarding/add.svg" alt="add" class="w-5 h-5 " @mouseover="onHover">
                </template>
                <CstDropdownItem class="flex items-center text-nowrap" @click="handleConnect">
                  Connect via Facebook Account
                  <v-menu
                  class="mx-2"
                  :popper-triggers="['hover']"
                  placement="bottom-end"
                  popper-class="first-comment__info-popover"
                >
                  <!-- This will be the popover target (for the events and position) -->
                  <a href="#"
                    ><i class="far fa-question-circle text-base text-blue-900"></i
                  ></a>
                  <!-- This will be the content of the popover -->
                  <template v-slot:popper>
                    <div>Choose this option if your Instagram account is linked to a Facebook Page. You'll log in with your Facebook credentials to connect associated Instagram accounts. Ensure your Instagram is connected to a Facebook Page for this method.
                      <br>
                      <a
                        v-close-popper
                        href="#"
                        class="beacon text-cs-primary"
                        data-beacon-article-modal="5c2f4a172c7d3a31944fab1a"
                        >Learn More</a
                      >
                    </div>

                  </template>
                </v-menu>
                </CstDropdownItem>
                <CstDropdownItem class="flex items-center text-nowrap" @click="handleConnect('instagram_login')">
                  Connect Directly with Instagram
                  <v-menu
                  class="mx-2"
                  :popper-triggers="['hover']"
                  placement="bottom-end"
                  popper-class="first-comment__info-popover"
                >
                  <!-- This will be the popover target (for the events and position) -->
                  <a href="#"
                    ><i class="far fa-question-circle text-base text-blue-900"></i
                  ></a>
                  <!-- This will be the content of the popover -->
                  <template v-slot:popper>
                    <div>Select this option to connect using your Instagram credentials. No Facebook account or Page linkage is required. Ideal if your Instagram isn't connected to a Facebook Page.
                      <br>
                      <a
                        v-close-popper
                        href="#"
                        class="beacon text-cs-primary"
                        data-beacon-article-modal="67d16a07d21924031bf5b028"
                        >Learn More</a
                      >
                    </div>

                  </template>
                </v-menu>
                </CstDropdownItem>
        </CstDropdown>
      </div>


        <!-- Default Connect Button for Other Platforms -->
        <template v-else>
        <p class="group flex items-center">
          <img src="@assets/img/icons/onboarding/add.svg" alt="add" class="w-5 h-5 block group-hover:hidden">
          <span class="text-[0.75rem] xl:text-[0.875rem] text-[#2A70FC] font-medium hidden group-hover:block" @click="handleConnect"> Connect </span>
        </p>
        </template>
      </span>
    </div>
  </div>
</template>

<style scoped>
.parent:hover {
  border: 0.5px solid #2973e5;
  transform: scaleX(1.04);
  -webkit-transform: scaleX(1.04);
  -ms-transform: scaleX(1.04);
  box-shadow: inset 0px 20px 40px 0px rgba(0, 0, 0, 0.75);
}

.parent:hover :deep(.child),
.parent:hover p {
  color: #2973e5;
}

.parent:hover + div,
:has(+ .parent:hover) {
  transform: scaleX(1.02);
  -webkit-transform: scaleX(1.02);
  -ms-transform: scaleX(1.02);
  box-shadow: inset 0px 20px 40px 0px rgba(0, 0, 0, 0.75);
}

.parent {
  box-shadow: 0px 2px 4px 0px rgba(15, 0, 111, 0.08) !important;
}
</style>
