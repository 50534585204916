<template>
  <div
    class="cst-carousel-post color-border rounded-xl px-3 py-3 mt-5 p-relative bg-white"
  >
    <div class="flex items-center">
      <div class="w-48 ml-1 flex items-center">
        <div
          class="inline-flex justify-center items-center w-8 h-8 rounded-lg text-lg"
        >
          <img
            src="@assets/img/composer/facebook-carousel-icon.svg"
            alt=""
            class="w-5 h-5"
          />
        </div>
        <span class="mx-2 font-medium text-sm text-black-300"
          >Facebook Carousel</span
        >
        <v-menu
          :popper-triggers="['hover']"
          placement="bottom-end"
          popper-class="first-comment__info-popover"
        >
          <!-- This will be the popover target (for the events and position) -->
          <a href="#"
            ><img
              src="@assets/img/composer/help-icon.svg"
              alt=""
              class="w-5 h-5"
          /></a>
          <!-- This will be the content of the popover -->
          <template v-slot:popper>
            <div> Carousel Post is available for: </div>
            <ul>
              <li> <i class="fab fa-facebook-f"></i> Pages </li>
            </ul>

            <p class="first-comment__message-box"
              >How to add carousel post?
              <a
                href="#"
                class="beacon"
                data-beacon-article-modal="6221f69bab585b230a89d34b"
                >Click here</a
              ></p
            >
          </template>
        </v-menu>
      </div>
      <CstSwitch
        v-model="carouselOptions.is_carousel_post"
        class="mx-2"
        :disabled="carouselAccounts.length < 1"
        size="small"
      />
      <v-menu
        v-if="errors.length"
        class="mx-2 cursor-pointer"
        placement="bottom-start"
        :popper-triggers="['hover']"
      >
        <img
          id="carousel-error-tooltip"
          src="@assets/img/composer/error-icon.svg"
          alt=""
          class="w-6 h-6"
        />
        <template v-slot:popper>
          <div>
            <ul>
              <li
                v-for="(item, index) in errors"
                :key="`carousel_error_${index}`"
                class="tooltip-errors"
                >{{ item.text }}</li
              >
            </ul>
          </div>
        </template>
      </v-menu>
      <div class="ml-auto">
        <AccountSelectionDropdown
          type="carousel"
          :carousel="carouselOptions"
          :accounts-list="carouselAccounts"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </div>
    </div>
    <div
      v-if="link && link.url && carouselOptions.cards.length > 0"
      class="py-3 px-3"
    >
      <div class="flex w-full justify-end">
        <span class="clear_content_link" @click="clearCarouselContent"
          >Clear all content
          <i
            v-tooltip.top="'By clearing content, all of carousel cards will be removed.'"
            class="far fa-question-circle"
          ></i>
        </span>
      </div>
      <Carousel
        v-if="carouselOptions.cards.length > 0"
        :navigation-next-label="navigationNext"
        :navigation-prev-label="navigationPrev"
        :navigation-enabled="true"
        :pagination-enabled="false"
        :scroll-per-page="false"
        :per-page-custom="[
          [1080, 4],
          [1440, 4],
        ]"
        class=""
      >
        <template v-for="(card, index) in carouselOptions.cards" :key="index">
          <Slide class="slide bg-white w-full p-px mx-1">
            <div class="relative block w-full bg-white">
              <!-- image -->
              <div
                class="relative w-full h-52 max-h-52 bg-cover bg-no-repeat bg-center border rounded-md"
                :style="{ 'background-image': 'url(' + card.picture + ')' }"
              >
                <img
                  class="max-w-full h-52 max-h-52 opacity-0"
                  :src="card.picture"
                  alt=""
                  @error="
                    (event) => {
                      event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                      event.target.classList.remove('opacity-0')
                    }
                  "
                />
                <div class="flex absolute top-0 w-full">
                  <div
                    class="bg-cs-primary text-white drop-shadow cursor-pointer rounded-sm w-6 h-6 flex items-center justify-center"
                    >{{ index + 1 }}
                  </div>
                  <div
                    v-if="index === 0"
                    class="bg-cs-primary text-white drop-shadow cursor-pointer rounded-md mt-2 mr-2 flex items-center ml-auto"
                  >
                    <i
                      v-if="!uploadingImage"
                      class="cs-upload-arrow p-2.5"
                      @click="updateImage(index)"
                    ></i>
                    <i v-else class="fad fa-spinner-third fa-spin p-2.5"></i>
                    <input
                      :id="'update-carousel-image' + index"
                      ref="update-carousel-image"
                      type="button"
                      title="Update Image"
                      accept="image/png,image/jpeg,.jpeg,.jpg,.png,.heic"
                      class="absolute opacity-0"
                      @click="updateImage(index)"
                    />
                  </div>
                </div>
                <div v-if="!card.picture" class="flex absolute bottom-0 w-full">
                  <i
                    id="carousel-img-error-tooltip"
                    class="far fa-exclamation-circle bg-cs-red text-white drop-shadow cursor-pointer rounded-sm w-6 h-6 flex items-center justify-center"
                  >
                  </i>

                  <span class="flex flex-col">
                    <b-tooltip
                      target="carousel-img-error-tooltip"
                      triggers="hover"
                    >
                      {{
                        'We were unable to get an image from the link, you can either upload your own or leave it up to Facebook to generate from the link.'
                      }}
                    </b-tooltip>
                  </span>
                </div>
              </div>
              <div
                class="flex justify-center items-center w-full h-full rounded-md"
                style=""
              >
                <div class="w-full py-1 overflow-hidden">
                  <textarea
                    :id="'card-name-' + index"
                    v-model="card.name"
                    title="Title"
                    type="text"
                    placeholder="Title"
                    class="overflow-y-hidden rounded-lg shadow-xs font-normal text-gray-900 hover:bg-gray-50 focus:outline-none bg-cs-light-gray w-full px-2 py-2.5 text-sm"
                    style="font-weight: 500; resize: none; margin-bottom: 0"
                    rows="2"
                  />
                  <CstInputFields
                    :id="'card-description-' + index"
                    :value="card.description"
                    class="w-full"
                    type="text"
                    size="small"
                    placeholder="Description"
                    @value="
                      (text) => {
                        card.description = text
                      }
                    "
                  />
                  <CstInputFields
                    :id="'card-url' + index"
                    :value="card.url"
                    class="w-full mt-1"
                    type="text"
                    size="small"
                    placeholder="Target URL"
                    @value="
                      (text) => {
                        card.url = text
                      }
                    "
                  />
                </div>
              </div>
            </div>
          </Slide>
        </template>
        <!--End Card-->
        <Slide
          v-if="carouselOptions.end_card && carouselOptions.end_card_url"
          class="slide bg-white w-full p-px mx-1"
        >
          <div class="relative block w-full bg-white">
            <div
              class="relative w-full h-52 max-h-52 bg-cover bg-no-repeat bg-center border rounded-md"
              :style="{
                'background-image': 'url(' + getAccountImageForEndCard() + ')',
              }"
            >
            </div>
            <div
              class="flex justify-center items-center w-full h-16 my-1 rounded-md"
            >
              <div
                class="footer w-100 h-full flex items-center overflow-hidden cursor-pointer text-center rounded-sm"
                style="background-color: #e4e6eb33"
              >
                <div class="w-full py-1 overflow-hidden">
                  <div class="w-100 font-bold">See more at</div>
                  <div
                    class="w-100 overflow-hidden font-normal text-gray-800 uppercase"
                  >
                    {{ getUrlHostName(carouselOptions.end_card_url) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </Carousel>
      <!-- CAT & end card options -->
      <span
        class="mt-4 mx-0.5 inline-block w-full font-medium text-sm text-black-300"
        >Call To Action</span
      >
      <div class="mt-1 grid grid-cols-3 gap-5 items-center">
        <div>
          <CstDropdown class="w-full" size="small">
            <template v-slot:selected>
              {{ getCTA }}
            </template>
            <template v-for="(action, index) in callToActions" :key="index" >
              <CstDropdownItem @click="setCTA(action.type)">{{
                action.name
              }}</CstDropdownItem>
            </template>
          </CstDropdown>
        </div>
        <div class="mx-auto self-center">
          <CstSimpleCheckbox v-model="carouselOptions.end_card" reverse>
            <template v-slot:label
              >Include Automatic Carousel End Card</template
            >
          </CstSimpleCheckbox>
        </div>
        <div>
          <CstInputFields
            v-show="carouselOptions.end_card"
            id="end-card-url"
            :value="carouselOptions.end_card_url"
            class="w-full required-input"
            type="text"
            size="small"
            placeholder="End Card Link..."
            @value="
              (value) => {
                // eslint-disable-next-line vue/camelcase
                carouselOptions.end_card_url = value
              }
            "
          />
        </div>
      </div>
    </div>
    <div
      v-if="carouselOptions.is_carousel_post && link.url === ''"
      class="flex w-full py-8 justify-center"
    >
      <div class="w-2/4">
        <input
          id="linkInput"
          ref="linkInput"
          type="text"
          class="w-full px-2 py-2.5 text-sm rounded-lg color-border shadow-xs font-normal text-gray-900 focus:outline-none bg-cs-light-gray"
          placeholder="Insert Your Link..."
          @input="validations.invalidUrl = false"
          @keyup.enter="validateLink"
        />
        <span v-if="validations.invalidUrl" class="input-error">{{
          validations.message
        }}</span>
      </div>
      <button
        class="mx-2 text-sm font-medium bg-cs-primary focus:outline-none rounded-md text-white border-0 px-3 py-2.5 h-10 max-h-10"
        @click="validateLink"
        >Submit URL
        <img
          v-if="urlLoader"
          style="width: 20px; margin-left: 8px"
          src="@src/assets/img/common/gif_loader_white.gif"
          alt=""
        />
      </button>
    </div>
  </div>
</template>

<script>
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import CstSwitch from '@ui/Switch/CstSwitch'
import CstInputFields from '@ui/Input/CstInputFields'
import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox'
import { Carousel, Slide } from '@jambonn/vue-concise-carousel';
import {
  FacebookCallToAction,
  swalAttributes,
} from '@common/constants/common-attributes'
import { fetchLinkPreviewUrl } from '@src/modules/publish/config/api-utils'
import AccountSelectionDropdown from '@src/modules/composer_v2/components/AccountSelectionDropdown'
import { MediaHelperMixin } from '@src/modules/publish/components/media-library/utils/MediaHelpers'
import ComposerHelperMixin from '@src/modules/composer_v2/mixins/ComposerHelper'
import { mapGetters } from 'vuex'
import { EventBus } from '@/src/modules/common/lib/event-bus'

export default {
  name: 'EditorCarouselBox',
  components: {
    Carousel,
    Slide,
    CstDropdown,
    CstDropdownItem,
    AccountSelectionDropdown,
    CstSwitch,
    CstInputFields,
    CstSimpleCheckbox,
  },
  mixins: [MediaHelperMixin, ComposerHelperMixin],
  props: {
    accountSelection: {
      type: Object,
      default: () => {},
    },
    carouselOptions: {
      type: Object,
      default: () => {
        return {
          cards: [],
          accounts: [],
          call_to_action: 'NO_BUTTON',
          end_card: false,
          end_card_url: '',
        }
      },
    },
    errors: {
      type: Array,
      default: () => [],
    },
    carouselAccounts: {
      type: Array,
      default: () => [],
    },
    carouselImages: {
      type: Array,
      default: () => [],
    },
    url: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update-carousel-cards', 'resetCarouselCards'],
  data() {
    return {
      callToActions: FacebookCallToAction,
      link: {
        url: '',
        image: '',
        title: '',
        description: '',
      },
      urlLoader: false,
      validations: {
        invalidUrl: false,
        message: '',
      },
      showInputs: -1,
      uploadingImage: false,
    }
  },
  computed: {
    ...mapGetters(['getSocialAccountsList']),
    navigationNext: function () {
      return `<i class="fas fa-chevron-right"></i>`
    },
    navigationPrev: function () {
      return `<i class="fas fa-chevron-left"></i>`
    },
    getCTA() {
      return this.callToActions.find(
        (action) => action.type === this.carouselOptions.call_to_action
      ).name
    },
  },
  watch: {
    url: function (newVal) {
      this.link = newVal
    },
    carouselImages: function () {
      this.fetchCard()
    },
    'link.url': function (newVal) {
      if (newVal !== '') this.fetchCard()
    },
  },
  mounted() {
    this.link = this.url
    this.fetchCard()
    this.handleFbCarouselAcc()
    EventBus.$on('carousel-image-update', this.handleCarouselImageUpdate);
  },
  beforeDestroy() {
    EventBus.$off('carousel-image-update', this.handleCarouselImageUpdate);
  },
  methods: {
    fetchCard() {
      const cards = []
      if (this.link && this.link.url && (this.link.image || this.link.title)) {
        cards.push({
          picture: this.link.image,
          name: this.link.title,
          description: this.link.description,
          url: this.link.url,
        })
      } else if (this.carouselOptions.cards[0]) {
        cards.push(this.carouselOptions.cards[0])
        this.link.url = this.carouselOptions.cards[0].url
        this.link.image = this.carouselOptions.cards[0].picture
        this.link.title = this.carouselOptions.cards[0].name
        this.link.description = this.carouselOptions.cards[0].description
      }

      if (
        this.link &&
        this.link.url &&
        this.carouselImages &&
        this.carouselImages.length > 0
      ) {
        this.carouselImages.forEach((image, index) => {
          if (index > 8) return
          // to skip the first image if it was from the url
          if (cards[0] && image === cards[0].picture) return
          // to skip if gif
          if (this.isGif(image)) return

          const card = this.carouselOptions.cards.find(
            (singleCard) => singleCard.picture === image
          )
          if (card && card.picture) {
            cards.push(card)
          } else {
            cards.push({
              picture: image,
              name: this.link.title,
              description: this.link.description,
              url: this.link.url,
            })
          }
        })
      }
      this.$emit( 'update-carousel-cards', JSON.parse(JSON.stringify(cards)))
    },
    validateLink() {
      const link = this.$refs.linkInput.value
      if (!link.trim()) {
        this.validations.invalidUrl = true
        this.validations.message = 'URL is required'
        return false
      }
      if (this.isValidURL(link)) {
        this.fetchCardPreview(link)
      } else {
        this.validations.invalidUrl = true
        this.validations.message = 'Invalid URl'
        return false
      }
    },
    isGif(url) {
      if (
        this.getSocialSharingMediaDetails[url] &&
        this.getSocialSharingMediaDetails[url].mime_type
      ) {
        return this.getSocialSharingMediaDetails[url].mime_type === 'image/gif'
      } else {
        return false
      }
    },
    fetchCardPreview(url) {
      this.urlLoader = true
      this.postRequest(
        fetchLinkPreviewUrl,
        { url },
        (response) => {
          if (response.data.status) {
            this.urlLoader = false
            if (
              response.data.facebook &&
              (response.data.facebook.image ||
                response.data.images.length > 0 ||
                response.data.facebook.title)
            ) {
              this.link.url = response.data.facebook.url
                ? response.data.facebook.url
                : url
              this.link.image = response.data.facebook.image
                ? response.data.facebook.image
                : response.data.images[0]
                ? response.data.images[0]
                : ''
              this.link.title = response.data.facebook.title
                ? response.data.facebook.title
                : response.data.meta.title
                ? response.data.meta.title
                : ''
              this.link.description = response.data.facebook.description
                ? response.data.facebook.description
                : response.data.meta.description
                ? response.data.meta.description
                : ''
            } else {
              this.urlLoader = false
              this.validations.invalidUrl = true
              this.validations.message =
                'Unable to generate preview for this url'
            }
          } else {
            this.urlLoader = false
            this.validations.invalidUrl = true
            this.validations.message = 'Unable to generate preview for this url'
          }
        },
        (error) => {
          this.urlLoader = false
          console.debug('Exception in fetchShortLinks', error)
          this.validations.invalidUrl = true
          this.validations.message = 'Unable to generate preview for this url'
        }
      )
    },
    setCTA(action) {
      this.carouselOptions.call_to_action = action
      // this.$emit('change-carousel','setAction',action)
    },

    updateImage(index) {
      this.selectedCardIndex = index;
      EventBus.$emit('show-media-library-modal', {
        source: 'carousel',
        details: {
          image: [],
          video: [],
          threadedTweetIndex: 0,
        },
        sideTabIndex: 1,
      });
    },

    async uploadImageForCarousel(event) {
      EventBus.$emit('media-action', { type: 'openMediaLibrary', index })
      return;
    },

    getAccountImageForEndCard() {
      if (this.carouselOptions && this.carouselOptions.accounts.length > 0) {
        const account = this.carouselAccounts.find(
          (account) => account.facebook_id === this.carouselOptions.accounts[0]
        )
        return account.image
          ? account.image
          : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
      }
      return 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    },

    async clearCarouselContent() {
      const res = await this.$bvModal
        .msgBoxConfirm('Are you sure you want to clear all content?', {
          title: 'Clear all content',
          ...swalAttributes(),
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          console.error('clearContent', err)
          return false
        })

      if (res) {
        // resting box states
        this.$emit('resetCarouselCards')
        this.link = {
          url: '',
          image: '',
          title: '',
          description: '',
        }
      }
    },

    /**
     * On Toggle, automatically selects the accounts for carousel post.
     */
    handleFbCarouselAcc() {
      this.accountSelection.facebook.forEach((account) => {
        const acc = this.getSocialAccountsList.find(
          (acc) =>
            acc.channel_type === 'facebook' &&
            acc.type === 'Page' &&
            acc[acc.channel_identifier] === account
        )
        if (
          acc &&
          !this.carouselOptions.accounts.includes(acc[acc.channel_identifier])
        ) {
          this.carouselOptions.accounts.push(acc[acc.channel_identifier])
        }
      })
    },
    handleCarouselImageUpdate({ options }) {
      const { images } = options;

      if (images.length > 1) {
        this.alertMessage(
          'Only one image is allowed',
          'error'
        )
        return;
      }

      if (images.length === 1) {
        this.carouselOptions.cards[this.selectedCardIndex].picture = images[0];
        if (this.link?.image) {
          this.link.image = images[0];
        }
      }
      this.selectedCardIndex = null;
    },
  },
}
</script>

<style lang="scss">
.cst-carousel-post {
  &:hover {
    border-color: #409aff;
    box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
  }
}

.tooltip-errors {
    max-width: 280px;
    background-color: #ffffff;
    text-align: left;
    color: #000000;
    padding: 10px 13px;
    border-radius: 7px;

    &:before {
      flex-shrink: 0;
      content: '';
      display: inline-flex;
      width: 6px;
      height: 6px;
      margin-right: 6px;
      margin-top: 8px;
      border-radius: 50%;
      background: #f43838;
    }
}
</style>
