<script setup>
import { computed } from 'vue'
import CardsComponent from '@src/modules/analytics/views/instagram_v2/components/CardsComponent.vue'
import TopPosts from '@src/modules/analytics/views/instagram_v2/components/TopPosts.vue'
import AudienceGrowthChart from '@src/modules/analytics/views/instagram_v2/components/graphs/AudienceGrowthChart.vue'
import ActiveUserByHour from '@src/modules/analytics/views/instagram_v2/components/graphs/ActiveUserByHour.vue'
import ActiveUserByDay from '@src/modules/analytics/views/instagram_v2/components/graphs/ActiveUserByDay.vue'
import useInstagramAnalytics from '@src/modules/analytics/views/instagram_v2/composables/useInstagramAnalytics.js'
import ReelsPerformanceChart from '@/src/modules/analytics/views/instagram_v2/components/graphs/ReelsPerformanceChart.vue'
import RollupCard from '@/src/modules/analytics/views/instagram_v2/components/RollupCard.vue'
import AiInsightsSection from '@src/modules/analytics/views/instagram_v2/components/AIInsightsSection.vue'
import StoriesPerformanceChart from '@/src/modules/analytics/views/instagram_v2/components/graphs/StoriesPerformanceChart.vue'
import PublishingBehaviourChart from '@/src/modules/analytics/views/instagram_v2/components/graphs/PublishingBehaviourChart.vue'
import PostsSection from '@/src/modules/analytics/views/instagram_v2/components/PostsSection.vue'
import AudienceLocationChart from '@/src/modules/analytics/views/instagram_v2/components/graphs/AudienceLocationChart.vue'
import DemographicsByGender from '@/src/modules/analytics/views/instagram_v2/components/graphs/DemographicsByGender.vue'
import DemographicsByAge from '@src/modules/analytics/views/instagram_v2/components/graphs/DemographicsByAge.vue'
import PublishingBehaviourBreakdownTable from '@/src/modules/analytics/views/instagram_v2/components/PublishingBehaviourBreakdownTable.vue'
import HashtagsChart from '@/src/modules/analytics/views/instagram_v2/components/graphs/HashtagsChart.vue'
import ReportFooter from '@/src/modules/analytics/components/reports/ReportFooter.vue'

const GENDER_MAP = {
  M: 'Males',
  F: 'Females',
  U: 'Unspecified',
}

const {
  routes,
  rollupTypes,
  topPostsData,
  overviewDemographicsData,
  overviewPublishingBehaviourData,
  isLoadingStates,
} = useInstagramAnalytics()

// max no. of posts per page
const chunkSize = 10

/**
 * Computes the array of start indexes for chunks of top engaging posts.
 * @returns {Array<number>} An array containing the start indexes for each chunk.
 */
const topEngagingPostsChunks = computed(() => {
  const startIndexes = []
  if (topPostsData.value) {
    for (let i = 0; i < topPostsData.value?.length; i += chunkSize) {
      startIndexes.push(i)
    }
  }
  return startIndexes
})

/**
 * Computes the total page size based on the number of hard-coded sections and the number of chunks of top engaging posts.
 * @returns {number} The total page size.
 */
const totalPageSize = computed(() => {
  const TOTAL_SECTIONS = 14

  return TOTAL_SECTIONS + topEngagingPostsChunks.value.length
})

const leadingGender = computed(() => {
  return (
    GENDER_MAP[overviewDemographicsData.value?.max_audience_age?.gender] ||
    'Unknown'
  )
})

const breakdownTableData = computed(() => {
  return (
    overviewPublishingBehaviourData.value?.publishing_behaviour_rollup || []
  )
})
</script>

<template>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Overview</h2>
      <div class="flex flex-col">
        <!-- Cards -->
        <div class="grid grid-cols-8 gap-4 mb-14">
          <CardsComponent />
        </div>
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Cumulative Audience Growth</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <AudienceGrowthChart type="cumulative" />
          </div>
          <RollupCard :type="rollupTypes.AUDIENCE" />
        </div>
      </div>
    </div>
    <ReportFooter :current-page="1" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Daily Audience Growth</h2>
      <div class="flex flex-col gap-1">
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <AudienceGrowthChart type="daily" />
          </div>
          <RollupCard :type="rollupTypes.AUDIENCE" />
        </div>
         <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Publishing Behaviour By Engagement</h2>
        <PublishingBehaviourChart type="engagements" />
      </div>
    </div>
    <ReportFooter :current-page="2" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <div class="flex flex-col gap-7">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Publishing Behaviour By Impressions</h2>
        <PublishingBehaviourChart type="impressions" />
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Publishing Behaviour By Reach</h2>
        <PublishingBehaviourChart type="reach" />
      </div>
    </div>
    <ReportFooter :current-page="3" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <div class="flex flex-col gap-7">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Publishing Behaviour Breakdown</h2>
        <PublishingBehaviourBreakdownTable
          :data-list="breakdownTableData"
          :is-loading="isLoadingStates[routes.OVERVIEW_PUBLISHING_BEHAVIOUR]"
          :is-report-view="true"
        />
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Hashtags Performance</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <HashtagsChart />
          </div>
          <RollupCard :type="rollupTypes.HASHTAGS" />
        </div>
      </div>
    </div>
    <ReportFooter :current-page="4" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <div class="flex flex-col gap-7">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Active Users</h2>
        <div class="grid grid-cols-2 gap-4">
          <ActiveUserByHour />
          <ActiveUserByDay />
        </div>
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Reels Performance By Engagement</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <ReelsPerformanceChart type="engagement" />
          </div>
          <RollupCard :type="rollupTypes.REELS" />
        </div>
      </div>
    </div>
    <ReportFooter :current-page="5" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <div class="flex flex-col gap-7">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Reels Performance By Watch Time</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <ReelsPerformanceChart type="watch_time" />
          </div>
          <RollupCard :type="rollupTypes.REELS" />
        </div>
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Reels Performance By Shares</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <ReelsPerformanceChart type="shares" />
          </div>
          <RollupCard :type="rollupTypes.REELS" />
        </div>
      </div>
    </div>
    <ReportFooter :current-page="6" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <div class="flex flex-col gap-7">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Stories Performance By Interactions</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <StoriesPerformanceChart type="interactions" />
          </div>
          <RollupCard :type="rollupTypes.STORIES" />
        </div>
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Stories Performance By Impressions</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <StoriesPerformanceChart type="impressions" />
          </div>
          <RollupCard :type="rollupTypes.STORIES" />
        </div>
      </div>
    </div>
    <ReportFooter :current-page="7" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Stories Performance By Reach</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <StoriesPerformanceChart type="reach" />
          </div>
          <RollupCard :type="rollupTypes.STORIES" />
        </div>
    </div>
    <ReportFooter :current-page="8" :total-pages="totalPageSize" />
  </div>
 <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Top Posts</h2>
      <TopPosts />
    </div>
    <ReportFooter :current-page="9" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
       <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Audience Demographics</h2>
      <div class="flex flex-col gap-7">
        <!-- Demographics Chart -->
        <div class="grid grid-cols-1 gap-4">
          <DemographicsByAge />
          <DemographicsByGender />

          <div
            class="bg-white p-5 rounded-md w-full relative flex justify-center color-border cst-editor"
          >
            <p>
              <span class="font-semibold"> {{ leadingGender }}</span> between
              the ages of
              <span class="font-semibold">
                {{ overviewDemographicsData?.max_audience_age?.age }}</span
              >
              appear to be the leading force among your followers.
            </p>
          </div>
        </div>
      </div>
    </div>
    <ReportFooter :current-page="10" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <div class="flex flex-col gap-7">
        <div class="grid grid-cols-1 gap-7">
          <AudienceLocationChart type="country" />
          <AudienceLocationChart type="city" />
        </div>
      </div>
    </div>
    <ReportFooter :current-page="11" :total-pages="totalPageSize" />
  </div>
  <template v-for="(start, index) in topEngagingPostsChunks" :key="index">
    <!-- Iterate over each chunk of top engaging posts -->
    <div class="page_view flex flex-col justify-between">
      <div class="subpage">
        <!-- Render post table for the current chunk of top posts -->
         <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Instagram Top Posts (Top 5)</h2>
        <PostsSection
          :range-start-value="start"
          :range-end-value="Math.min(start + chunkSize, topPostsData?.length)"
        />
      </div>
      <ReportFooter :current-page="12 + index" :total-pages="totalPageSize" />
    </div>
  </template>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">AI Insights</h2>
      <AiInsightsSection :indexes-to-show="[0, 1]" />
    </div>
    <ReportFooter :current-page="13 + topEngagingPostsChunks.length" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <AiInsightsSection :indexes-to-show="[2]" />
    </div>
    <ReportFooter :current-page="13 + topEngagingPostsChunks.length + 1" :total-pages="totalPageSize" />
  </div>
</template>
