// libs
import { ref } from 'vue'

// composables
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics'
import useInstagramAnalytics from '@src/modules/analytics/views/instagram_v2/composables/useInstagramAnalytics'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'

export default function useAiInsights(platform = 'facebook') {
  const { lineChartOptions } = useAnalytics()

  // Dynamically get the platform-specific analytics
  const analyticsComposable = platform === 'facebook' ? useFacebookAnalytics() : useInstagramAnalytics()
  const {
    fetchMedia,
    routes,
    isLoadingStates,
    aiInsightsData: platformAiInsightsData,
    AIInsightsSectionMapping,
    getAIInsightsIcons
  } = analyticsComposable

  const aiInsightsData = ref([])
  const activeSections = ref([])

  const formatDate = (date) => {
    if (date.includes('-')) {
      return new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
    }
    return date
  }

  const getChartOptions = (data) => {
    const formattedDates = data.buckets.map(formatDate)
    lineChartOptions.value.xAxis.data = formattedDates
    lineChartOptions.value.series[0].data = data.values
    return lineChartOptions.value
  }

  const fetchInsights = async (section, selectedDropdownOption) => {
    try {
      await fetchMedia(routes.AI_INSIGHTS, {
        type:
          AIInsightsSectionMapping[section] ??
          AIInsightsSectionMapping[selectedDropdownOption] ??
          selectedDropdownOption,
      })

      if (platformAiInsightsData.value?.success) {
        aiInsightsData.value = platformAiInsightsData.value.insights.key_insights
        if (aiInsightsData.value) {
          activeSections.value.push(aiInsightsData.value[0]?.category)
        }
      }
    } catch (error) {
      console.error('Error fetching insights:', error)
      aiInsightsData.value = []
    } finally {
      isLoadingStates.value.ai_insights = false
    }
  }

  const openCollapsible = (section) => {
    return activeSections.value.includes(section)
  }

  const toggleSection = (section) => {
    if (activeSections.value.includes(section)) {
      activeSections.value = activeSections.value.filter(
        (activeSection) => activeSection !== section
      )
      return
    }
    activeSections.value.push(section)
  }

  return {
    // data
    aiInsightsData,
    isLoadingStates,
    activeSections,
    getAIInsightsIcons,

    // methods
    fetchInsights,
    getChartOptions,
    openCollapsible,
    toggleSection,
  }
}
