<script setup>
import { computed } from 'vue'
import usePinterestAnalytics from '@src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'
import PostsSection from '@src/modules/analytics/views/pinterest/components/PostsSection.vue'
import CardsComponent from '@/src/modules/analytics/views/pinterest/components/CardsComponent'
import FollowersGraph from '@/src/modules/analytics/views/pinterest/components/graphs/FollowersGraph.vue'
import ImpressionsGraph from '@/src/modules/analytics/views/pinterest/components/graphs/ImpressionsGraph.vue'
import EngagementsGraph from '@/src/modules/analytics/views/pinterest/components/graphs/EngagementsGraph.vue'
import PinsPostingAndEngagements from '@/src/modules/analytics/views/pinterest/components/graphs/PinsPostingAndEngagements.vue'
import PostingByDayGraph from '@/src/modules/analytics/views/pinterest/components/graphs/PostingByDayGraph.vue'
import TopAndLeastEngagingPosts from '@/src/modules/analytics/views/pinterest/components/TopAndLeastEngagingPosts'
import ReportFooter from '@/src/modules/analytics/components/reports/ReportFooter.vue'

const { selectedAccount, topEngagingPosts, leastEngagingPosts } =
  usePinterestAnalytics()

const chunkSize = 10
/**
 * Computes the array of start indexes for chunks of top engaging posts.
 * @returns {Array<number>} An array containing the start indexes for each chunk.
 */
const topEngagingPostsChunks = computed(() => {
  const startIndexes = []

  let i = 0;
  while (i < topEngagingPosts.value.engagement_rate.length) {
    startIndexes.push(i);
    i += chunkSize;
  }

  return startIndexes
})

/**
 * Computes the total page size based on the number of hard-coded sections and the number of chunks of top engaging posts.
 * @returns {number} The total page size.
 */
const totalPageSize = computed(() => {
  const hardCodedSections = 7
  return hardCodedSections + topEngagingPostsChunks.value.length
})
</script>

<template>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Overview</h2>
      <div class="flex flex-col">
        <!-- Cards -->
        <div class="grid grid-cols-8 gap-4 mb-14">
          <CardsComponent />
        </div>

        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Cumulative Followers Growth</h2>
        <FollowersGraph class="!mb-7" />

        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Daily Followers Growth</h2>
        <FollowersGraph class="!mb-7" default-type="bar" />
      </div>
    </div>
    <ReportFooter :current-page="1" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Daily Engagement Activity</h2>
      <EngagementsGraph class="!mb-7" />

      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Daily Impressions over time</h2>
      <ImpressionsGraph class="!mb-7" />
    </div>
    <ReportFooter :current-page="2" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Engagements vs Daily Posting Pattern</h2>
      <PinsPostingAndEngagements class="!mb-7" />

      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Impressions vs Daily Posting Pattern</h2>
      <PinsPostingAndEngagements type="Impressions" class="!mb-7" />
    </div>
    <ReportFooter :current-page="3" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Posting By Day</h2>
      <PostingByDayGraph class="!mb-7" />

      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Image Posting By Day</h2>
      <PostingByDayGraph type="Image" class="!mb-7" />
    </div>
    <ReportFooter :current-page="4" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Video Posting By Day</h2>
      <PostingByDayGraph type="Video" class="!mb-7" />
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Top Performing Pins by Impressions</h2>
      <div class="!mb-7">
        <!-- Engaging Posts -->
        <TopAndLeastEngagingPosts
          custom-report-label="Impressions"
          custom-report-data-key="impressions"
          :top-posts="topEngagingPosts.impressions"
          :least-posts="leastEngagingPosts.impressions"
          :selected-account="selectedAccount"
        />
      </div>
    </div>
    <ReportFooter :current-page="5" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Top Performing Pins by Engagement</h2>
      <div class="!mb-7">
        <!-- Engaging Posts -->
        <TopAndLeastEngagingPosts
          custom-report-label="Engagements"
          custom-report-data-key="engagement_rate"
          :top-posts="topEngagingPosts.engagement_rate"
          :least-posts="leastEngagingPosts.engagement_rate"
          :selected-account="selectedAccount"
        />
      </div>
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Top Performing Pins by Pin Clicks</h2>
      <div class="!mb-7">
        <TopAndLeastEngagingPosts
          class="!mb-7"
          custom-report-label="Pin Clicks"
          custom-report-data-key="pin_clicks"
          :top-posts="topEngagingPosts.pin_clicks"
          :least-posts="leastEngagingPosts.pin_clicks"
          :selected-account="selectedAccount"
        />
      </div>
    </div>
    <ReportFooter :current-page="6" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Top Performing Pins by Outbound Clicks</h2>
      <div class="!mb-7">
        <!-- Engaging Posts -->
        <TopAndLeastEngagingPosts
          custom-report-label="Outbound Clicks"
          custom-report-data-key="outbound_clicks"
          :top-posts="topEngagingPosts.outbound_clicks"
          :least-posts="leastEngagingPosts.outbound_clicks"
          :selected-account="selectedAccount"
        />
      </div>
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Top Performing Pins by Saves</h2>
      <div class="!mb-7">
        <TopAndLeastEngagingPosts
          class="!mb-7"
          custom-report-label="Saves"
          custom-report-data-key="saves"
          :top-posts="topEngagingPosts.saves"
          :least-posts="leastEngagingPosts.saves"
          :selected-account="selectedAccount"
        />
      </div>
    </div>
    <ReportFooter :current-page="7" :total-pages="totalPageSize" />
  </div>

  <template v-for="(start, index) in topEngagingPostsChunks" :key="index">
    <!-- Iterate over each chunk of top engaging posts -->
    <div class="page_view flex flex-col justify-between">
      <div class="subpage">
        <!-- Render post table for the current chunk of top posts -->
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Pinterest Top Pins (Top 5)</h2>
        <PostsSection
          :range-start-value="start"
          :range-end-value="
            Math.min(start + chunkSize, topEngagingPosts.engagement_rate.length)
          "
        />
      </div>
      <ReportFooter :current-page="8 + index" :total-pages="totalPageSize" />
    </div>
  </template>
</template>
