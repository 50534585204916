import { ref, computed, getCurrentInstance, inject } from 'vue'
import { EventBus } from '@common/lib/event-bus'
import { platformPosting } from '@common/lib/planner'
import proxy from '@common/lib/http-common'
import isEmpty from 'is-empty'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import { getPlatformIdentifierValue } from '@common/lib/integrations'
import { swalAttributes } from '@common/constants/common-attributes'
import { processHideFromclient } from '@src/modules/publish/config/api-utils'
import usePlannerHelper from '@modules/planner_v2/composables/usePlannerHelper'
import { useStore } from '@/src/store/base'
import useApproval from '@/src/modules/common/composables/useApproval'
import useToast from '@/src/modules/common/composables/useToast'
import router from '@/src/router'

const selectedItem = ref()
const commentsAccordion = ref(false)
const internalNotesAccordion = ref(false)
const externalAction = ref(false)

export default function usePlannerActions(plan) {
  const { fetchPlanAccounts } = usePlannerHelper()
  const root = inject('root')
  const { $bvModal } = root
  const { emit } = getCurrentInstance()
  const { canEditPost } = useApproval()
  const { alertMessage } = useToast()
  const store = useStore()
  const items = ref(store?.getters?.getPlans?.items ?? [])
  const item = ref(plan)

  const showDuplicateButton = computed(
    () => !!item.value?.can_perform?.duplicate
  )
  const showEditButton = computed(() => !!canEditPost(item.value))
  const showDelButton = computed(() => !!item.value?.can_perform.delete)
  const isActionProcessing = computed(() => !!item.value?.is_processing)

  const isInProgress = computed(
    () => item.value?.status === 'queued' || item.value?.status === 'processing'
  )

  const showEditDelButtons = computed(
    () => showEditButton.value && showDelButton.value
  )

  const showReviewButtons = computed(() => {
    if (item.value?.status === 'review' || item.value?.status === 'draft') {
      if (
        item.value?.approval &&
        item.value?.approval.status === 'pending_approval' &&
        checkApprovalStatus(item.value?.approval)
      ) {
        return true
      }
    }
    return false
  })

  const internalNotesLength = computed(() => {
    const internalNotes = item.value?.comments
    let length = 0
    if (item.value?.type === 'Composer Article') return length
    if (internalNotes) {
      internalNotes.forEach((item) => {
        if (item.is_note) length += 1
      })
    }
    return length
  })

  const commentsLength = computed(() => {
    const comments = item.value?.comments
    let length = 0
    if (comments) {
      comments.forEach((item) => {
        if (!item.is_note) length += 1
      })
    }
    if (item.value?.external_comments?.length > 0)
      length += item.value?.external_comments.length
    if (item.value?.external_actions?.length > 0) {
      item.value?.external_actions.forEach((item) => {
        if (item.comment) length += 1
      })
    }

    return length
  })

  const postAccounts = computed(() => {
    if (item.value?.account_selection) {
      const social = getPlatforms(item.value?.account_selection)

      const accounts = []
      social.forEach((platform) => {
        item.value?.account_selection[platform].forEach((account) => {
          accounts.push({
            ...account,
            platform,
          })
        })
      })
      return accounts.flat()
    }

    return []
  })

  const clientProfile = computed(() => {
    const userProfile =
      store?.getters?.getWorkspaces?.activeWorkspace?.members?.find(
        (item) => item.user_id === store?.getters?.getProfile?._id
      )

    return userProfile?.membership === 'client'
  })

  const showApprovalStatus = computed(
    () =>
      !!(
        item.value?.approval?.approvers?.length > 0 ||
        item.value?.external_actions?.length > 0
      )
  )

  const hideFromClientMessage = computed(() => {
    if (!item.value?.publish_time_options.hide_client) {
      return 'Hide from clients'
    }

    return 'Unhide from clients'
  })

  const hasThreadedTweetResponse = computed(() => {
    if (
      item.value?.status === 'published' &&
      isTwitterPosting.value &&
      item.value?.twitter_options
    ) {
      return item.value?.twitter_options.has_threaded_tweets
    }
    return false
  })

  const isTwitterPosting = computed(() => {
    return (
      item.value?.type !== 'Composer Article' &&
      item.value?.account_selection?.twitter?.length > 0
    )
  })

  const isInstagramPosting = computed(() => {
    return (
      item.value?.type !== 'Composer Article' &&
      item.value?.account_selection?.instagram?.length > 0
    )
  })

  const hasFirstCommentResponse = computed(() => {
    if (item.value?.status === 'published' && isInstagramPosting.value) {
      return item.value?.has_first_comment
    }

    return false
  })

  const getPlatformType = computed(() => {
    return item.value?.feed_preview?.channel === 'tumblr' &&
      item.value?.feed_preview?.account
      ? 'tumblr_account'
      : item.value?.feed_preview?.channel
  })

  // methods
  const clonePlan = (item) => {
    item.isGridView = true
    item.action = editPublication
    store.dispatch('clonePlan', item)
  }
  const replacePlan = async (plan) => emit('replace-plan', plan)
  const editPlan = (item) => {
    item.isGridView = true
    item.action = editPublication
    store.dispatch('editPlan', item)
  }
  const sharePlan = (planId) => EventBus.$emit('share-plan::show', [planId])

  const removePlan = async (plan, status, hasBlogReference) => {
    if (status === 'published' && !hasBlogReference) {
      await fetchPlanAccounts(plan)
      $bvModal.show('delete-post-modal')
      return
    }
    const res = await $bvModal.msgBoxConfirm(
      status === 'published'
        ? 'Are you sure your want to delete post ? Post will only be deleted from Content Studio and not from the platform where it published'
        : 'Are you sure you want to delete your post?',
      {
        title: 'Remove Post',
        ...swalAttributes(),
      }
    )
    if (res) {
      const response = await store.dispatch('removePlan', { id: plan })
      if (!response) {
        await store.dispatch('toastNotification', {
          message: 'An error while removing your post, please try again.',
          type: 'error',
        })
      } else {
        items.value?.splice(
          items.value?.findIndex((item) => item._id === plan),
          1
        )
        EventBus.$emit('fetch-plans-after-delete-actions')
      }
    }
  }

  const openMoveCampaignModel = (folderId, planId) => {
    if (!folderId) folderId = ''
    store.commit(composer.SET_MOVE_CAMPAIGN_ID, folderId)
    store.commit(composer.SET_MOVE_CAMPAIGN_PLAN_ID, planId)
    // eslint-disable-next-line no-undef
    $('#movePublicationModal').modal('show')
  }

  const checkApprovalStatus = (data, itemRequired = false) => {
    if (data.approvers) {
      const approvalUser = data.approvers.find(
        (user) => user.user_id === store?.getters?.getProfile?._id
      )
      if (approvalUser && approvalUser.status === 'pending') {
        if (itemRequired) {
          return approvalUser
        }

        return true
      }
    }
    return false
  }

  const handleExtra = (item, type = 'details') => {
    if (type === 'details') {
      commentsAccordion.value = false
      internalNotesAccordion.value = false
      EventBus.$emit('preview-plan', item?._id)
    } else if (
      type === 'commentsAccordion' ||
      type === 'internalNotesAccordion' ||
      type === 'externalAction'
    ) {
      commentsAccordion.value = false
      internalNotesAccordion.value = false
      externalAction.value = false
      switch (type) {
        case 'commentsAccordion':
          commentsAccordion.value = true
          break
        case 'internalNotesAccordion':
          internalNotesAccordion.value = true
          break
        case 'externalAction':
          externalAction.value = true
          break
      }
      selectedItem.value = item
      $bvModal.show('post-details-modal')
    } else {
      EventBus.$emit('post-status-modal', item)
    }
  }

  const showTweetsResponse = (item) => {
    const account = postAccounts.value?.find(
      (item) => item.platform === 'twitter'
    )
    EventBus.$emit('tweets_detail_modal', {
      item,
      response: platformPosting(
        item,
        getPlatformType.value,
        getPlatformIdentifierValue(account, 'twitter')
      ).threaded_tweets,
      profile: channelImage(account, account.platform),
      platform:
        'platform_name' in account ? account.platform_name : account.name,
    })
  }

  const channelImage = (account, type) => {
    const image =
      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    const gmbImage =
      'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
    if (!account) return image

    if (account && account.platform_logo) return account.platform_logo

    switch (type) {
      case 'facebook':
        return account.image
          ? account.image
          : account.picture && account.picture.url
          ? account.picture.url
          : image
      case 'twitter':
      case 'linkedin':
      case 'pinterest':
        return account.image && account.image.large && account.image.large.url
          ? account.image.large.url
          : account.image
          ? account.image
          : image
      case 'instagram':
        return account.image
          ? account.image
          : account.profile_picture
          ? account.profile_picture
          : image
      case 'medium':
        return account.imageUrl ? account.imageUrl : image
      case 'gmb':
        return account.location_name ? gmbImage : image
    }
  }

  const getPlatforms = (platforms) => {
    const platformsList = []
    for (const platformsKey in platforms) {
      if (!isEmpty(platforms[platformsKey])) {
        platformsList.push(platformsKey)
      }
    }

    return platformsList
  }

  const editPublication = (
    id,
    duplicate,
    reopen = false,
    initialize = false,
    postType = null
  ) => {
    if (!postType || (postType && !postType.includes('blog'))) {
      console.debug('Method:editPublication-composerv2')
      EventBus.$emit('publication-composer-post', {
        mode: 'edit',
        id,
        duplicate,
        reopen,
        initialize,
        postType,
      })
      $bvModal.show('composer-modal')
    }
  }

  const hideFromClientAction = async (item, status) => {
    try {
      const response = await proxy.post(processHideFromclient, {
        id: item._id,
        status,
        workspace_id: store.getters.getActiveWorkspace._id,
      })

      if (response.data.status === true) {
        item.publish_time_options = response.data.publish_time_options
      } else {
        alertMessage('Unable to perform action. Please try again.', 'error')
      }
    } catch (e) {
      alertMessage(
        e?.message ?? 'Unable to perform action. Please try again.',
        'error'
      )
    }
  }

  // Media Library redirect to planner helper method
  const OpenPlanFromUsage = async (planId, type) => {
    // if type is composer, ask for confirmation to close composer
    if(type === 'composer'){
      console.debug('Method:OpenPlanFromUsage-composerv2', type)
      const options = {
        ...swalAttributes(),
        modalClass:
          'sweet-alert-confirmation fade sweet-alert-minimize-composer',
        okVariant: 'primary',
        hideHeader: false,
      }
     const res = await $bvModal.msgBoxConfirm(
        'Your post will be saved and minimized. Do you want to proceed?',
        {
          title: '⚠️ Unfinished Post is in the Composer!',
          ...options,
          cancelTitle: 'Cancel',
          okTitle: 'Proceed',
        }
      )

    if (res === null || res === false) {
      return
    }
      EventBus.$emit('minimize-composer')
    }


    await router.push({
      name: 'planner_list_v2',
      params: { workspace: store.getters.getActiveWorkspace.slug },
      query: { plan_ids: planId },
    })

    // Open the preview modal after redirect
    setTimeout(() => {
      EventBus.$emit('preview-plan', planId)
    }, 2000)
  }

  return {
    item,
    items,
    selectedItem,

    // methods
    clonePlan,
    replacePlan,
    editPlan,
    removePlan,
    handleExtra,
    sharePlan,
    showTweetsResponse,
    channelImage,
    editPublication,
    openMoveCampaignModel,
    hideFromClientAction,
    commentsAccordion,
    internalNotesAccordion,
    externalAction,
    OpenPlanFromUsage,

    // computed
    internalNotesLength,
    commentsLength,
    isActionProcessing,
    isInProgress,
    showDuplicateButton,
    showReviewButtons,
    showEditDelButtons,
    showDelButton,
    showEditButton,
    showApprovalStatus,
    clientProfile,
    hideFromClientMessage,
    hasThreadedTweetResponse,
    hasFirstCommentResponse,
    isTwitterPosting,
  }
}
