<template>
  <div
      class="planner-calender-main"
      :class="{
      loader_overlay_with_loader: isFetchingPlans,
    }"
  >
    <beat-loader v-if="isFetchingPlans" :color="'005fd0'"></beat-loader>

    <div
      v-if="showNullPlansSection"
      :class="{ 'fade-list-item-show': showNullPlansSection }"
      class="warning_box text-center mx-0 "
    >
      <p>No posts found for the selected filters and date-range.</p>
    </div>

    <PlannerNotesModal/>

    <FullCalendar
        ref="calendar"
        class="planner-app-calendar"
        :options="{
        ...calendarOptions,
        timeZone: getActiveWorkspace?.timezone,
        events: [
            ...(defaultNotesToggle ? notes.map(note => ({
          ...note,
          startEditable: false,
          end: note.start_date !== note.end_date ? new Date(new Date(note.end_date).setHours(23, 59, 59, 999)) : null
        })) : []),
        ...plans,
        ],
        initialView: getCalendarViewPreference,
        buttonHints: {
          today: todayToolTipText,
        },
      }">
      <template v-slot:eventContent="arg">
        <CalendarEvent
            :key="arg.event.extendedProps?._id"
            :item="arg.event.extendedProps"
            :plan="plans.filter((plan) => plan._id === arg.event.extendedProps?._id)?.[0]"
            :type="
            arg.event.extendedProps?.common_box_status !== undefined
              ? 'plan'
              : 'note'
          "
        ></CalendarEvent>
      </template>
    </FullCalendar>
  </div>

  <b-modal
      id="post-status-modal"
      size="lg"
      centered
      :no-close-on-backdrop="true"
      hide-header
      hide-footer
      dialog-class="max-w-7xl"
  >
    <PlannerPostStatus :item="selectedPost" modal-id="post-status-modal" />
  </b-modal>

</template>

<script>
// Core Imports
import { mapGetters } from 'vuex'
import PlannerPostStatus from '@src/modules/planner_v2/components/PlannerPostStatus'
// Full Calendar Imports
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import useDateFormat from "@common/composables/useDateFormat";
import { CC_POST_RESCHEDULE_PLAN_ERROR } from '@common/constants/messages'
import { getPlanMedia } from '@common/lib/planner'
import { EventBus } from '@common/lib/event-bus'
import { swalAttributes } from '@common/constants/common-attributes'
import { pusherSocketPublish } from '@common/lib/pusher'
import debounce from 'lodash/debounce'
import { planner } from '@src/modules/planner/store/mutation-types'
import proxy from '@common/lib/http-common'
import { plannerDefaultCalendarView } from '@src/modules/publish/config/api-utils'
import usePlannerHelper from '@src/modules/planner_v2/composables/usePlannerHelper'
import {useComposerHelper} from "@modules/composer_v2/composables/useComposerHelper";
import {useFeatures} from "@modules/billing/composables/useFeatures";
import PlannerNotesModal from '../components/PlannerNotesModal.vue'
import { usePlannerNotes } from '@modules/planner_v2/composables/usePlannerNotes'
import ComposeNote from '@assets/img/planner/compose-note.svg'
import EditNote from '@assets/img/planner/edit-note.svg'
import moment from "moment";
import CalendarEvent from '../components/CalendarEvent'
import { parseDescriptionHtml } from '../../common/lib/helper'

FullCalendar.compatConfig = {
  MODE: 3,
}
CalendarEvent.compatConfig = {
  MODE: 3,
}

const clone = require('rfdc/default')

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarEvent,
    PlannerPostStatus,
    PlannerNotesModal
  },
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    plans: {
      type: Array,
      default: () => [],
    },
    notes: {
      type: Array,
      default: () => [],
    },
    isPlatformSelected: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'calendar-date-change',
    'handle-query-change',
    'replace-plan',
    'reject-with-comment',
    'preview-plan',
    'approve-with-comment',
  ],
  setup() {
    const { isEditablePost, canEditThisPost, fetchPlanAccounts } = usePlannerHelper()
    const { openDraftComposer, draftPlanId } = useComposerHelper()
    const { deleteNote, updateNoteField, openModal } = usePlannerNotes()
    const { momentWrapper} = useDateFormat()
    const {canAccess} = useFeatures()
    const blogPostAccess = canAccess('blog_publishing')

    return {
      isEditablePost,
      canEditThisPost,
      openDraftComposer,
      draftPlanId,
      momentWrapper,
      canAccess,
      blogPostAccess,
      openModal,
      updateNoteField,
      deleteNote,
      fetchPlanAccounts
    }
  },
  data() {
    return {
      // Planner Notes Modal Attributes
      note: {},
      isEditing: false,
      todayToolTipText: 'Current Week',
      selectedPost: null,
      isInitialDatesSet: true,
      calendarDefaultView: this.getCalendarViewPreference,
      defaultNotesToggle: false,
      calendarOptions: {
        // Plugins
        plugins: [
          dayGridPlugin,
          interactionPlugin, // needed for dateClick
          momentTimezonePlugin,
        ],
        // Components Options
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'toggleButton dayGridMonth,dayGridWeek',
        },

        // Custom
        customButtons: {
          toggleButton: {
            text: '', // Empty text since we'll use CSS to create the toggle
            click: () => this.handleNotesToggle(),
            className: 'calendar-toggle-button'
          }
        },

        // Views Options
        editable: true,
        // selectable: true,
        // selectMirror: true,
        dayMaxEvents: 10,
        weekends: true,
        contentHeight: 'auto',
        expandRows: false,
        dayHeaderFormat: {weekday: 'long'},
        dayCellContent: this.handleDayCellContent,
        views: {
          week: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
            },
          },
          day: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
            },
          },
        },
        datesSet: this.handleDatesSet,
        viewDidMount: this.handleViewDidMount,
        // dayCellContent: this.handleDayCellContent,
        // dayCellClassNames: 'h-100 fc-options-hover',
        dayCellClassNames: 'fc-cell',
        dayHeaders: true,

        // Events Options
        initialEvents: [],
        events: [],
        eventColor: 'transparent',
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventDisplay: 'block',
        eventDrop: this.reschedulePlan,
        firstDay: null,
        // eventContent: this.handleEventContent
        /* you can update a remote database when these fire:
                eventAdd:
                eventChange:
                eventRemove:
                */
      },
      currentEvents: [],
      labels_channel: '',
      previousTopOffset: '',
    }
  },
  computed: {
    ...mapGetters([
      'getPlans',
      'getPlannerLoaders',
      'getPublishSelection',
      'getWorkspaceDetails',
      'getWorkspaces',
      'getCalendarViewPreference',
      'getCalendarNotesPreference',
      'getActiveWorkspace'
    ]),
    isFetchingPlans() {
      return this.loader
    },
    planItemsCount() {
      return this.plans.length
    },
    /* isPlatformsSelected () {
      return this.getPublishSelection.platforms.selection.length
    }, */
    showNullPlansSection() {
      return !this.isFetchingPlans && !this.planItemsCount
    },
  },
  watch: {
    showNullPlansSection(newValue) {
      if (newValue && this.defaultNotesToggle) {
        this.$set(this.calendarOptions, 'events', [this.notes, ...this.plans])
        return
      }

      this.$set(this.calendarOptions, 'events', this.plans)
    },
    plans(newValue) {
      this.$nextTick(() => {
        const scrollerElements = document.querySelectorAll('.fc-scroller')
        if (scrollerElements.length > 1) {
          const scrollerElement = scrollerElements[1]

          scrollerElement.scrollTo({
            top: this.$store.state.planner.calendarScrollPosition,
            behavior: 'smooth',
          })
        }
      })
    },
    scroll(val) {
      debounce(() => {
        this.handleTableHeight()
      }, 650)()
    },
    'getWorkspaces.activeWorkspace.first_day'(firstDay) {
      this.calendarOptions.firstDay = firstDay?.day ? firstDay.key : 1
    },
  },
  created() {
    const query = Object.assign({}, this.$route.query)
    delete query.order_by
    this.$router.push({query})
  },
  mounted() {
    this.calendarOptions.firstDay = this.getWorkspaces.activeWorkspace.first_day
        ? this.getWorkspaces.activeWorkspace.first_day.key
        : 1
    EventBus.$on('post-status-modal', (post) => {
      this.selectedPost = post
      this.$bvModal.show('post-status-modal')
    })

    this.$nextTick(() => {
      this.labels_channel = pusherSocketPublish.subscribe(
          `labels_${this.getActiveWorkspace._id}`
      )
      this.bindPusherLabels(this.labels_channel)
    })

    this.$nextTick(() => {
      this.handleTableHeight()
    })

    // Initialize toggle button state for notes
    this.$nextTick(() => {
      this.defaultNotesToggle = this.getCalendarNotesPreference
      const button = document.querySelector('.fc-toggleButton-button');
      if (button && this.defaultNotesToggle) {
        button.classList.add('active');
      }
    });


    window.addEventListener('resize', () => {
      this.handleTableHeight()
    })
    const scrollerElements = document.querySelectorAll('.fc-scroller')

    if (scrollerElements.length > 1) {
      const scrollerElement = scrollerElements[1]

      scrollerElement.addEventListener('scroll', this.handleScroll)
    }

    EventBus.$on('empty-calendar-events', () => {
      this.calendarOptions.events = [];
      this.removeEventCalendar();
    })
  },

  beforeUnmount() {
    delete this.$route.query.date
    window.removeEventListener('resize', () => {
      this.handleTableHeight()
    })
    const scrollerElements = document.querySelectorAll('.fc-scroller')

    if (scrollerElements.length > 1) {
      const scrollerElement = scrollerElements[1]

      scrollerElement.removeEventListener('scroll', this.handleScroll)
    }
    this.$store.commit(planner.SET_CALENDAR_SCROLL_POSITION, 0)

    EventBus.$off('post-status-modal')

    EventBus.$off('empty-calendar-events')

  },
  methods: {
    async handleNotesToggle() {
      this.defaultNotesToggle = !this.defaultNotesToggle;
      this.$store.dispatch('setDefaultNotesView', this.defaultNotesToggle)

      // Update UI
      const button = document.querySelector('.fc-toggleButton-button');
      if (button) {
        button.classList.toggle('active', this.defaultNotesToggle);
      }

      this.setPlannerDefaultCalendarView(this.calendarDefaultView, this.defaultNotesToggle);

    },
    async removeEventCalendar() {
      if (this.$refs?.calendar) {
        console.log('removeEventCalendar');
        const calendarApi = this.$refs.calendar?.getApi();
        if (calendarApi) {
          console.log('removeEventCalendar: removeAllEvents');
          await calendarApi.removeAllEvents();
        }
      }
    },
    handleTableHeight() {
      if (this.$el && this.$el instanceof HTMLElement) {
        const offset = this.$el.getBoundingClientRect()
        this.previousTopOffset = offset
        const scroll =
            window.scrollY ||
            window.scrollTop ||
            document.getElementsByTagName('html')[0].scrollTop
        this.$el.style.height = `calc(100vh - ${
            scroll ? this.previousTopOffset.top : offset.top
        }px)`
      }
    },
    // https://preactjs.com/guide/v8/api-reference/ - createElement Guide
    handleDayCellContent(arg, createElement) {
      const day = this.momentWrapper(arg.date)?.formatTimezone()?.format('D')
      const wrapper = document.createElement('div')
      const dayContent = document.createElement('span')
      const noteWrapper = document.createElement('div')
      noteWrapper.className= 'parent-note-wrapper'

      const note = document.createElement('img')
      note.src= require('@assets/img/planner/note.svg')
      note.className="w-3.5 w-3.5"
      note.title = 'New Note'
      noteWrapper.addEventListener('click', (e) => {
        const date = this.momentWrapper(moment(arg.date).utc()).formatTimezone().format('MMM DD, YYYY HH:mm')
        this.updateNoteField('start_date', new Date(date))
        this.updateNoteField('end_date', new Date(date))
        this.openModal()
      })

      dayContent.className = arg.isToday
          ? 'fc-daygrid-day-no active'
          : 'fc-daygrid-day-no'
      dayContent.textContent = day
      if (
          (arg.isFuture || arg.isToday) &&
          !arg.isOther &&
          this.hasPermission('can_access_top_header')
      ) {
        const post = document.createElement('i')
        post.className = 'fas fa-plus'
        post.title = 'New Social Post'
        post.addEventListener('click', (e) =>
            this.createContent(e, 'post', arg.isToday, arg.date)
        )
        const blog = document.createElement('i')
        if(this.blogPostAccess?.allowed){
          blog.title =   'New Blog Post'
          blog.className = 'fas fa-file'
          blog.addEventListener('click', (e) =>
              this.createContent(e, 'blog', arg.isToday, arg.date)
          )
        }else{
          blog.className = 'fas fa-file !cursor-default'
          blog.title = this.blogPostAccess.error?.message
        }
        const icons = document.createElement('span')
        icons.className = 'fc-daygrid-top-icon'
        icons.appendChild(post)
        icons.appendChild(blog)

        noteWrapper.appendChild(note)
        wrapper.appendChild(icons)
        wrapper.appendChild(dayContent)

        return {domNodes: [noteWrapper, wrapper]}
      }

      noteWrapper.appendChild(note)

      wrapper.appendChild(dayContent)
      return {domNodes: [noteWrapper, wrapper]}
    },
    async createContent(e, type, isToday, date) {
      if(type === 'post') {
        const res = await this.openDraftComposer(
            '⚠️ Unfinished Post is in the Composer!',
            'You have a post currently in the composer that is minimized. What would you like to do?',
            'Save & Create New',
            'Return to Composer'
        )
        if(res === null) {
         return
        } else if(!res) {
          this.changePublishTypeFromCalender(type, isToday, date)
        } else {
          const workspace = this.$route.params.workspace
          history.pushState({}, null, `/${workspace}/composer/${this.draftPlanId}`)
          this.$bvModal.show('composer-modal')
        }
        return
      }
      this.changePublishTypeFromCalender(type, isToday, date)
    },
    handleDayClassNames(arg) {
      console.log('handleDayClassNames')
    },
    handleDateClick(arg) {
      alert('date click! ' + arg.dateStr)
    },
    handleViewDidMount(event) {
      this.fetchPlansWithCalendarDate(event)
    },
    changeCalendarView(event) {
      // setting user default calendar view ['month','week'] based on user Selection
      if (event?.view?.type !== this.getCalendarViewPreference) {
        this.setPlannerDefaultCalendarView(event?.view?.type, this.defaultNotesToggle);
      }
    },
    async handleDatesSet(event) {
      await this.fetchPlansWithCalendarDate(event)
      this.changeCalendarView(event)
      if (this.isInitialDatesSet) {
        this.isInitialDatesSet = false
        return
      }
      this.$emit('handle-query-change')
    },
    async fetchPlansWithCalendarDate(event) {
      this.calendarDefaultView =
          event?.view?.type || this.getCalendarViewPreference

      this.todayToolTipText =
          event?.view?.type === 'dayGridWeek' ? 'Current Week' : 'Current Month'

      const start = this.momentWrapper(event.view.activeStart).format('MMMM DD, YYYY')
      const end =  this.momentWrapper(event.view.activeEnd).format('MMMM DD, YYYY')

      // remove all events from calendar before fetching new events
      this.calendarOptions.events = [];
      this.removeEventCalendar();

      const date = clone(start + ' - ' + end)
      this.$emit('calendar-date-change', date)

      await this.$router.push({
        query: {...this.$route.query, date},
      })
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(selectInfo) {
    },
    async handleEventClick(info) {
      const target = info.jsEvent.target
      const event = target.getAttribute('data-event')

      const item = JSON.parse(JSON.stringify(info.event.extendedProps))
      this.closePopovers()
      if (event) {
        item.stateObject = this
        switch (event) {
          case 'viewItemAttachment':
            // eslint-disable-next-line no-case-declarations
            let plan = target.getAttribute('data-plan')
            if (plan) {
              // finding plan from states
              plan = item.stateObject.plans.find((item) => item._id === plan)
              if (plan) {
                // checking for plan media
                const attachment = getPlanMedia(plan)
                if (attachment) {
                  EventBus.$emit('displayFile', {
                    type: attachment.type,
                    media: attachment.media,
                    index: 0,
                  })
                  item.stateObject.$bvModal.show('display-file-modal')
                }
              }
            }
            break
          case 'editCalendarItem':
            const resp = await this.openDraftComposer(
                '⚠️ Unfinished Post is in the Composer!',
                'You have a post currently in the composer that is minimized. What would you like to do?',
                'Save & Edit Selected',
                'Return to Composer'
            )
            if(resp === null) {

            } else if(!resp) {
              EventBus.$emit('reset-composer-data')
              this.$store.dispatch('editPlan', item)
            } else {
              const workspace = this.$route.params.workspace
              history.pushState(
                  {},
                  null,
                  `/${workspace}/composer/${this.draftPlanId}`
              )
              this.$bvModal.show('composer-modal')
            }
            break
          case 'deleteCalendarItem':
            if (item?.post_state === 'published' && !item?.blog_reference) {
              await this.fetchPlanAccounts(item?._id)
              this.$bvModal.show('delete-post-modal')
              return
            }
            // eslint-disable-next-line no-case-declarations
            const res = await this.$bvModal
                .msgBoxConfirm('Are you sure you want to delete this post?', {
                  title: 'Remove Post',
                  ...swalAttributes(),
                })
                .then((res) => res)
                .catch(() => null)

            if (res) {
              await this.$store.dispatch('removePlan', {
                id: item._id,
              })
            }
            break
          case 'replaceCalendarItem':
            this.$emit('replace-plan', item)
            break
          case 'approveCalendarItem':
            this.changePlanStatusMethod('scheduled', item, true)
            break
          case 'rejectCalendarItem':
            this.changePlanStatusMethod('rejected', item, true)
            break
          case 'duplicateCalendarItem':
            const response = await this.openDraftComposer(
                '⚠️ Unfinished Post is in the Composer!',
                'You have a post currently in the composer that is minimized. What would you like to do?',
                'Save & Create Duplicate',
                'Return to Composer'
            )
            if(response === null) {

            } else if(!response) {
              EventBus.$emit('reset-composer-data')
              this.$store.dispatch('clonePlan', item)
            } else {
              const workspace = this.$route.params.workspace
              history.pushState(
                  {},
                  null,
                  `/${workspace}/composer/${this.draftPlanId}`
              )
              this.$bvModal.show('composer-modal')
            }
            break
          case 'calendarItemCheckbox':
            // eslint-disable-next-line no-case-declarations
            const planId = target.getAttribute('value')
            if (target.checked) {
              this.getPlans.selected_plans.push(planId)
            } else {
              this.getPlans.selected_plans.splice(
                  this.getPlans.selected_plans.indexOf(planId),
                  1
              )
            }
            break
          case 'approvalCalendarItemReject':
            this.$emit('reject-with-comment', item)
            break
          case 'approvalCalendarItemApprove':
            this.$emit('approve-with-comment', item)
            break
        }
      } else {
        if (item?.common_box_status !== undefined) {
          this.$emit('preview-plan', item._id)
        } else {

          const noteElement = target.closest('div[id$="_note_popover"]');
          const elementId = noteElement ? noteElement.id : null;

          const popover = this.createPopover(
              info.el,
              info.event.title,
              info.event.extendedProps,
              elementId,
              {
                isLastColumn: info.el.fcSeg.isEnd,
                column: info.el.fcSeg.lastCol,
                totalColumns: 7
              }
          )
        }
      }
    },
    handleEvents(events) {
      this.currentEvents = events
    },
    async reschedulePlan(info) {
      const item = JSON.parse(JSON.stringify(info.event.extendedProps))
      item.stateObject = this
      if (!this.canEditPlan(item)) {
        info.revert()
        return false
      }
      item.start = this.momentWrapper(info.event.start)
      item.startStr = info.event.startStr // startStr YYYY-MM-DD
      if (item.content_category_id && item.content_category_id.length > 0) {
        this.$store.dispatch('toastNotification', {
          message: CC_POST_RESCHEDULE_PLAN_ERROR,
          type: 'error',
        })
        info.revert()
        return false
      }
      // Allow only draft or scheduled or reviewed post to reschedule
      if (
          item.post_state !== 'draft' &&
          item.post_state !== 'scheduled' &&
          item.post_state !== 'reviewed'
      ) {
        this.$store.dispatch('toastNotification', {
          message:
              'You can only reschedule a draft or scheduled or in review post.',
          type: 'error',
        })
        info.revert()
        return false
      }

      const status = await this.$store.dispatch('reschedulePlan', item)
      if (!status) info.revert()
    },
    async handleScroll(event) {
      debounce(() => {
        const scrollPosition = event.target.scrollTop
        if (!this.isFetchingPlans) {
          this.$store.commit(
              planner.SET_CALENDAR_SCROLL_POSITION,
              scrollPosition
          )
        }
      }, 500)()
    },
    /**
     * Sets user default calendar View ['month','week'] based on user Selection
     * @param defaultView
     */
    setPlannerDefaultCalendarView(defaultView, notesToggle = true) {
      const self = this
      proxy
          .post(plannerDefaultCalendarView, {
            planner_default_calendar_view: defaultView,
            planner_default_notes_view: notesToggle,
          })
          .then(() => {
            self.$store.dispatch('setDefaultCalendarView', defaultView)
            self.$store.dispatch('setDefaultNotesView', notesToggle)
          })
          .catch((err) => {
            console.log('PROXY::plannerDefaultView ~ err -> ', err)
          })
    },
    canEditPlan(item) {
      return this.isEditablePost(item) && this.canEditThisPost(item)
    },
    createPopover(eventEl, title, item, elementId, placement = {}) {
      const popover = document.createElement('div')
      popover.className = 'popover notes-popup note-popup-open bg-transparent w-[28rem] mx-auto absolute z-10 border-0'

      const combinedContent = `${item.note_title} ${item.description}`;
      const contentHeight = combinedContent.length > 230 ? 'max-height: 18rem;' : '';

      // Define fallback image URL
      const fallbackImageUrl = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg';

      // Function to handle image error
      const handleImageError = (imgElement) => {
        imgElement.onerror = null; // Prevent infinite loop
        imgElement.src = fallbackImageUrl;
      };

      popover.innerHTML = `<div class="relative bg-transparent w-[28rem] mx-auto mb-20">
        <div id="caret" class="absolute top-[-24px] left-[25px] transform -translate-x-1/2">
          <i style="color: ${item.note_color}" class="fa fa-solid fa-caret-up text-4xl"></i>
        </div>
        <div class="rounded-lg shadow-md bg-white" style="border-top: 4px solid ${item.note_color}">
          <div style="border-bottom: 1px solid #e3e3e3" class="flex justify-between p-4">
            <div class"header">
              <h3 class="text-[0.9rem] mb-1 pr-3 pt-1.5 text-[#3A4557]">
                <strong>Note</strong> -
                    <span class="text-[#757A8A] text-normal">
                    ${item.start_date === item.end_date
                      ? this.momentWrapper(new Date(item.start_date)).formatDate()
                      : `${this.momentWrapper(new Date(item.start_date)).formatDate()} - ${this.momentWrapper(new Date(item.end_date)).formatDate()}`
                    }
                </span>
              </h3>
            </div>
            <div class="close-button">
            <button id="closepopover" title="Close" class="btn text-gray-400 hover:shadow px-2 py-0.5 bg-[#3A45570D]">
              <i class="font-light fas fa-times"></i>
            </button>
            </div>
          </div>


            <div class="overflow-y-auto" style="${contentHeight}">
            <div class="p-4">
              <h3 class="text-[0.9rem] mb-1 pr-3 text-[#3A4557]"><strong>${item.note_title}</strong></h3>
              <p class="text-sm text-[#757A8A] pb-4">${parseDescriptionHtml(item.description,item?.description?.length, true, false)}</p>
            </div>
          </div>

          <div style="border-top: 1px solid #e3e3e3" class="flex items-center justify-between pl-[10px] pr-5 pt-3 pb-4">
            <div title="${item.author}" class="flex items-center">
              <img
                src='${item.author_image || fallbackImageUrl}'
                alt="Author"
                class="rounded-full border mr-2 w-8 h-8"
                onerror="this.onerror=null; this.src='${fallbackImageUrl}';"
              >
              <span class="truncate inline-block max-w-[7rem] pt-1 lg:max-w-[9rem] text-sm text-[#757A8A]">By: <strong class="font-medium capitalize text-[#3A4557]">${item.author}</strong></span>
            </div>
            <div class="flex items-center space-x-1">
                ${this.getUserRole !== 'Approver' ? `
                <button id="composeButton" title="Send note content to composer"  class="btn !bg-[#005FD0] hover:bg-blue-300 hover:shadow transition-all ease-in-out font-light py-1 rounded-md">
                  <img src='${ComposeNote}' class='w-4 h-4 mr-1'> <span class="text-white">Compose Post</span>
                </button>` : ''}

                ${this.getProfile._id === item.user_id ? `
                <button id="edite-note" title="Edit note" class="btn !bg-[#EAF3FF] hover:shadow transition-all ease-in-out font-light px-2 py-1 rounded-md">
                  <img src='${EditNote}'>
                </button>

                   <div class="relative flex-shrink-0">
                    <button id="moreOptionsBtn" title="More options" class="btn !bg-[#EAF3FF] hover:shadow transition-all ease-in-out font-light px-3.5 py-1 rounded-md">
                        <i class="fas fa-ellipsis-v text-gray-500"></i>
                    </button>
                    <div id="optionsMenu" class="hidden absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white border border-gray-200 z-20">
                        <div class="py-1" role="menu">
                            <button id="deleteButton" class="btn text-normal flex items-center px-4 py-2 text-sm hover:bg-gray-100 w-full text-left" role="menuitem">
                                <i class="fas !text-red-500 fa-trash-alt mr-2"></i>
                                <span class="leading-normal text-gray-900">Delete note</span>
                            </button>
                        </div>
                    </div>
                    </div>
                   ` : ''}
            </div>
          </div>
        </div>
      </div>`

      // Add image error handling after popover is created
      const authorImage = popover.querySelector('img[alt="Author"]');
      if (authorImage) {
        authorImage.addEventListener('error', () => handleImageError(authorImage));
      }

      // Append popover to the calendar container instead of body
      const note_popover = document.getElementById(elementId)
      note_popover.appendChild(popover)

      // Function to close popover
      const closePopover = () => {
        if (popover.parentNode) {
          popover.parentNode.removeChild(popover)
          // Remove the click event listener when popover is closed
          document.removeEventListener('mousedown', handleOutsideClick)
        }
      }


      // Handle clicks outside the popover
      const handleOutsideClick = (event) => {
        // Check if click is outside both the popover and the triggering event element
        if (!popover.contains(event.target) &&
            !note_popover.contains(event.target)) {
          closePopover()
        }
      }

      document.addEventListener('mousedown', handleOutsideClick)

      const closeBtn = popover.querySelector('#closepopover')
      closeBtn.addEventListener('click', () => {
        if (popover.parentNode) {
          popover.parentNode.removeChild(popover)
        }
      })

      if (this.getUserRole !== 'Approver') {
        const composeBtn = popover.querySelector('#composeButton')
        composeBtn.addEventListener('click', () => {
          let description = item.note_title + ' ' + item.description
          this.changePublishType('Composer Social', false, '', description)
        })
      }

      if(this.getProfile._id === item.user_id) {
        const editNoteBtn = popover.querySelector('#edite-note')
        editNoteBtn.addEventListener('click', () => {
          this.openModal(item)
        })

        // More options menu functionality
        const moreOptionsBtn = popover.querySelector('#moreOptionsBtn')
        const optionsMenu = popover.querySelector('#optionsMenu')
        let isMenuOpen = false

        // Toggle menu on three dots click
        moreOptionsBtn.addEventListener('click', (e) => {
          e.stopPropagation();
          isMenuOpen = !isMenuOpen;

          const buttonRect = moreOptionsBtn.getBoundingClientRect();
          const spaceBelow = window.innerHeight - buttonRect.bottom;
          const optionsMenu = document.querySelector('#optionsMenu');

          // If space below is less than 100px, show above
          if (spaceBelow < 100) {
            optionsMenu.classList.remove('mt-2');
            optionsMenu.classList.add('bottom-[calc(100%+0.5rem)]');
            optionsMenu.style.top = 'auto';
          } else {
            // Show below
            optionsMenu.classList.add('mt-2');
            optionsMenu.classList.remove('bottom-[calc(100%+0.5rem)]');
            optionsMenu.style.top = '';
          }

          optionsMenu.classList.toggle('hidden');
        })

        // Handle delete button click
        const deleteButton = popover.querySelector('#deleteButton')
        deleteButton.addEventListener('click', (e) => {
          e.stopPropagation()
          // Add your delete functionality here

          this.deleteNote(item)

          optionsMenu.classList.add('hidden')
        })

        // Close menu when clicking outside
        document.addEventListener('click', (event) => {
          if (isMenuOpen && !optionsMenu.contains(event.target) && !moreOptionsBtn.contains(event.target)) {
            optionsMenu.classList.add('hidden')
            isMenuOpen = false
          }
        })
      }

      // Set initial position
      popover.style.position = 'absolute';
      popover.style.left = '20px';
      popover.style.top = '46px';

      console.log('createPopover', placement)

      // Get measurements after initial positioning
      const viewportWidth = window.innerWidth;
      const margin = 20; // Safe margin from viewport edge

      // Check if popover is overflowing viewport
      if (placement.column >= placement.totalColumns - 3) {
        // Set a fixed distance from the right edge
        const rightOffset = '13rem'; // 10rem in pixels

        popover.style.left = 'auto';
        popover.style.right = rightOffset;

        // Adjust caret position
        const caret = popover.querySelector('#caret');
        if (caret) {
          caret.style.left = 'auto';
          caret.style.right = '0px';
        }

        // Add max-width for smaller screens
        if (viewportWidth < 768) { // For screens smaller than tablet
          popover.style.maxWidth = `${viewportWidth - margin * 2}px`;
        }
      }


      return popover
    },

    /**
     * closePopovers
     *
     * Description:
     * Closes any open popover elements present in the document. This function is useful for maintaining a clean UI by ensuring auxiliary UI elements are removed when not in focus.
     *
     * Usage:
     * Typically called when interacting with non-calendar areas to ensure popovers are closed when their content is no longer relevant.
     *
     * Notes:
     * Consider integrating responsive behavior that automatically collapses or removes popovers based on user interactions beyond simple clicks.
     */
    closePopovers() {
      const popovers = document.querySelectorAll('.popover')
      if (popovers.length > 0) {
        popovers.forEach((popover) => {
          document.body.removeChild(popover);
        });
      }
    },
  },
  beforeRouteLeave(to, from, next) {
      if (this.$refs?.calendar) {
        const calendarApi = this.$refs.calendar?.getApi()
        if (calendarApi) {
          calendarApi.removeAllEvents()
          calendarApi.destroy()
        }
      }
    this.$nextTick(() => {
      next()
    })
  },
}
</script>

<style lang="scss">
/* Keyframes for fading in */
@keyframes fadeIn {
  from {
  opacity: 0;
}
  to {
    opacity: 1;
  }
}

/* Apply the fade in animation */
.fade-list-item-show {
  animation: fadeIn 0.5s ease forwards;
}

</style>
