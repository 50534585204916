<script setup>
import { computed, onBeforeMount, onMounted, onBeforeUnmount, ref } from 'vue'
import FilterBar from '@src/modules/analytics/views/instagram_v2/components/FilterBar.vue'
import TabsComponent from '@src/modules/analytics/views/common/TabsComponent.vue'
import CstAlert from '@ui/Alert/CstAlert'
import OverviewSection from '@src/modules/analytics/views/instagram_v2/components/OverviewSection.vue'
import DemographicsSection from '@src/modules/analytics/views/instagram_v2/components/DemographicsSection.vue'
import useAnalyticsUtils from '@src/modules/analytics/components/common/composables/useAnalyticsUtils.js'
import useInstagramAnalytics from '@src/modules/analytics/views/instagram_v2/composables/useInstagramAnalytics.js'
import { useStore } from '@state/base'
import { EventBus } from '@common/lib/event-bus'
import PostsSection from '@/src/modules/analytics/views/instagram_v2/components/PostsSection.vue'
import AIInsightsSection from '@/src/modules/analytics/views/instagram_v2/components/AIInsightsSection.vue'

const { getters } = useStore()

const { getPlatformAccounts } = useAnalyticsUtils()
const { selectedAccount } = useInstagramAnalytics()
const mainComponentRef = ref(null)

onBeforeMount(() => {
  console.log('before mount')
  EventBus.$on('workspace-changed', () => {
    console.log('workspace changed')
  })
})

onMounted(() => {
  console.log('mounted', getters.getFetchSocialStatus)
})

onBeforeUnmount(() => {
  console.log('unmounted')
  EventBus.$off('workspace-changed')
})

const getAllAccounts = computed(() => {
  return getPlatformAccounts('instagram')
})

const isReconnectRequired = computed(() => {
  return (
    selectedAccount &&
      ['invalid', 'expired'].includes(selectedAccount.value?.validity)
  )
})

const ScrollToTop = () => {
  setTimeout(() => {
    mainComponentRef.value?.scrollIntoView({ behavior: 'smooth' })
  }, 0)
}
</script>

<template>
  <div ref="mainComponentRef" class="analytics-main-container">
    <FilterBar @scroll-to-top="ScrollToTop" />
    <div v-if="!getAllAccounts.length" class="analytics-main-errors">
      <img
        alt="No accounts connected"
        draggable="false"
        src="@modules/analytics/assets/imgs/no_data_images/no-insta-account.png"
      />
      <p>
        <span> No Account Connected </span>
        <router-link
          class="btn btn-studio-theme-space btn-size-small"
          :to="{ name: 'social' }"
          >Connect Instagram Account
        </router-link>
      </p>
    </div>
    <div v-else>
      <div>
        <TabsComponent
          :tabs="['#overview', '#demographics', '#posts', '#AI-Insights']"
          type="instagram"
        >
          <template v-if="isReconnectRequired" v-slot:alert>
            <CstAlert type="warn" class="text-left mb-5">
              To view your updated data, you must reconnect your Instagram
              account.
              <router-link
                class="text-yellow-600 font-semibold hover:text-yellow-700"
                :to="{ name: 'social', params: { id: 'instagram' } }"
                >Reconnect Now
              </router-link>
            </CstAlert>
          </template>
          <!-- overview tab -->
          <template v-slot:overview>
            <OverviewSection />
          </template>
          <template v-slot:demographics>
            <DemographicsSection />
          </template>
          <template v-slot:posts>
            <PostsSection />
          </template>
          <template v-slot:AI-Insights>
            <AIInsightsSection />
          </template>
        </TabsComponent>
      </div>
    </div>
  </div>
</template>
