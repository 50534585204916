<template>
  <div>
    <b-modal
      id="custom-thumbnail-modal"
      centered
      header-class="cs-modal-header"
      hide-footer
      modal-class="custom-thumbnail-modal"
      no-close-on-backdrop
      size="lg"
      title-class="cs-modal-title"
      @shown="onShown"
      @hide="onHide"
    >
      <template v-slot:modal-title> Set Custom Thumbnail</template>

      <div class="px-2 py-4">
        <!--    Controls    -->
        <div class="flex space-x-7 items-center mb-5">
          <CstRadio
            v-model="type"
            name="thumbnail-type"
            value="suggested"
            :disabled="disabled.suggested || settingThumbnail"
          >
            Choose Suggested
          </CstRadio>

          <CstRadio
            v-model="type"
            name="thumbnail-type"
            value="frame"
            :disabled="disabled.frame || settingThumbnail"
          >
            Choose From Video
          </CstRadio>

          <CstRadio
            v-model="type"
            name="thumbnail-type"
            value="upload"
            :disabled="disabled.upload || settingThumbnail"
          >
            Upload Image
            <a
              v-tooltip.top="
                'In case of Instagram custom thumbnail is only supported on post type Reel.'
              "
              class="beacon ml-1"
              href="#"
            >
              <i class="far fa-question-circle"></i>
            </a>
          </CstRadio>
        </div>

        <!--    Images Slider    -->
        <div v-show="type === 'suggested'" class="suggestion-slider">
          <Carousel
              :mouse-drag="true"
              :navigation-next-label="carouselNextArrow"
              :navigation-prev-label="carouselPrevArrow"
              :navigation-enabled="true"
              :scroll-per-page="false"
              :per-page="1.1"
              :pagination-enabled="false"
          >
            <Slide
                    v-for="(image, key) in suggestedImages"
                    :key="key"
            >
              <div class="ratio ratio-4x3 cursor-pointer">
                <div
                  class="cursor-pointer absolute z-10 w-10 h-10 rounded border-t-2 border-r-2 border-l-2 border-b-2 border-solid border-gray-50 bg-gray-50 bg-opacity-70 hover:bg-opacity-75 text-cs-primary flex justify-center items-center text-2xl shadow top-6 right-6 left-auto"
                  :class="{
                    'bg-opacity-100 hover:bg-opacity-100':
                      selectedSuggestion === image,
                  }"
                  @click="selectSuggestedImage(image)"
                >
                  <template v-if="selectedSuggestion === image">
                    <i class="far fa-check"></i>
                  </template>
                </div>
                <img
                  :src="image"
                  class="object-cover rounded-md border-t border-b border-r border-l border-solid border-gray-400 border-opacity-80"
                  alt=""
                />
              </div>
            </Slide>
          </Carousel>
        </div>

        <!--    Choose From Video    -->
        <div v-show="type === 'frame'">
          <div class="ratio ratio-16x9 rounded-md overflow-hidden">
            <video
              ref="vid"
              controls="controls"
              crossorigin="anonymous"
              @timeupdate="() => seeking($event)"
            >
              <source :src="videoSource" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div class="text-sm mt-3 text-center"
            >Scroll through the video and then click the apply button to capture
            the selection.
          </div>
        </div>

        <!--    Upload Image    -->
        <div v-show="type === 'upload'">
          <label
            :style="{
              backgroundImage: `url(${
                uploadFilePreview ? uploadFilePreview : ''
              })`,
            }"
            class="ratio ratio-16x9 group cursor-pointer flex justify-center items-center bg-contain bg-no-repeat bg-center rounded-md overflow-hidden bg-gray-450 hover:bg-cs-fog border-t border-b border-r border-l border-solid border-gray-400 border-opacity-80"
          >
            <span
              class="flex justify-center items-center flex-col text-center text-base text-gray-800 text-bold-500"
            >
              <span
                class="flex flex-col items-center justify-center p-12"
                :class="{
                  'hidden group-hover:flex bg-gray-450 bg-opacity-95 rounded shadow-lg':
                    uploadFilePreview,
                }"
              >
                <span
                  class="h-14 w-14 rounded-full bg-gray-50 flex justify-center items-center"
                >
                  <i class="far fa-cloud-upload text-lg"></i>
                </span>
                <span class="mt-2.5">
                  <span class="text-cs-primary">Click to upload</span> or drag
                  and drop</span
                >
                <span class="mt-0.5">PNG or JPG</span>
              </span>
            </span>

            <input
              accept="image/png,image/jpeg,.jpeg,.jpg,.png,.heic"
              class="opacity-0 absolute z-0 cursor-pointer"
              type="file"
              @change="uploadNewThumbnail($event)"
            />
          </label>
        </div>

        <!--    Footer    -->
        <div class="flex justify-between items-center mt-3.5">
          <div>
            <div v-show="isShowWarningMessage('youtube')">
              <img
                src="@assets/img/composer/warning-icon.svg"
                alt="Warning"
                class="w-5 h-5 mx-2"
              />
              <span class="text-sm mt-0.5">
                Thumbnails are not supported on Youtube Shorts.
              </span>
            </div>
            <div v-show="isShowWarningMessage('tiktok') && type === 'upload'">
              <img
                  src="@assets/img/composer/warning-icon.svg"
                  alt=" "
                  class="w-5 h-5 mx-2"
              />
              <span class="text-sm mt-0.5">
                TikTok does not support uploading custom image thumbnails.
              </span>
            </div>
            <div v-show="isShowWarningMessage('instagram') && type === 'upload'">
              <img
                src="@assets/img/composer/warning-icon.svg"
                alt=" "
                class="w-5 h-5 mx-2"
              />
              <span class="text-sm mt-0.5">
                In case of Instagram custom thumbnail is only supported on post type Reel.
              </span>
            </div>
          </div>
          <div>
            <CstButton
              size="large"
              variant="secondary"
              class="mr-3 w-28"
              :disabled="settingThumbnail"
              text="Cancel"
              @click="EventBus.$emit('custom-thumbnail-modal-hide')"
            />
            <CstButton
              variant="primary"
              size="large"
              text="Apply"
              class="w-28"
              :loading="settingThumbnail"
              :disabled="
                settingThumbnail ||
                (type === 'suggested' && !selectedSuggestion)
              "
              @click="handleApply"
            />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CstRadio from '@ui/Radio/CstRadio'
import CstButton from '@ui/Button/CstButton'
import { MediaHelperMixin } from '@src/modules/publish/components/media-library/utils/MediaHelpers'
import { EventBus } from '@common/lib/event-bus'
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'

export default {
  name: 'CustomThumbnailModal',
  components: {
    CstRadio,
    CstButton,
    Carousel,
    Slide,
  },
  mixins: [MediaHelperMixin],
  props: {
    accountSelection: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      type: 'suggested',
      settingThumbnail: false,
      fileToUpload: null,
      carouselPrevArrow:
          '<button class="border-0 h-10 w-10  bg-opacity-80 text-white rounded absolute shadow" style="top: 50%; transform: translateY(-50%); left: 14px; z-index: 9; background: rgba(130, 125, 118, 0.57) none repeat scroll 0% 0%"><i class="fas fa-chevron-left"></i></button>',
      carouselNextArrow:
          '<button class="border-0 h-10 w-10  bg-opacity-80 text-white rounded absolute shadow" style="top: 50%; transform: translateY(-50%); right: 14px; z-index: 9; background: rgba(130, 125, 118, 0.57) none repeat scroll 0% 0%"><i class="fas fa-chevron-right"></i></button>',
      uploadFilePreview: '',
      selectedSuggestion: '',
      suggestedImages: [],
      videoSource: '',
      emitter: () => {},
      disabled: {
        suggested: false,
        frame: false,
        upload: false,
      },
      currentThumbnailTime: null,
      index: 0
    }
  },
  computed: {
    EventBus() {
      return EventBus
    },
    canUploadImage() {
      return Object.keys(this.accountSelection)?.some((key) => {
        return this.accountSelection[key]?.length && key !== 'instagram'
      })
    },
  },
  mounted() {
    EventBus.$on(
      'custom-thumbnail-modal-show',
      ({
        videoSource,
        suggestedImages,
        selectedSuggestion,
        disabledStates,
        emitter,
         index
      }) => {
        this.clearModalState()
        this.index = index
        this.selectedSuggestion = selectedSuggestion
        this.suggestedImages = [...suggestedImages]
        this.videoSource = videoSource
        this.manageDisableStates(disabledStates)

        this.emitter = emitter
        this.$bvModal.show('custom-thumbnail-modal')
      }
    )

    EventBus.$on('custom-thumbnail-modal-hide', () => {
      this.$bvModal.hide('custom-thumbnail-modal')
      this.clearModalState()
    })
  },
  beforeUnmount() {
    EventBus.$off('custom-thumbnail-modal-show')
    EventBus.$off('custom-thumbnail-modal-hide')
  },
  methods: {
    isShowWarningMessage(channelName) {
      return this.accountSelection[channelName]?.length
    },
    /*
     *  Method to load video when modal shown
     */
    onShown() {
      if (this.$refs.vid) {
        this.$refs.vid.load()
        this.$refs.vid.addEventListener('seeking', this.seeking)
      }
    },

    seeking(event) {
      this.getVideoTime(event)
    },

    /*
     *  Method to set thumbnail based on user choice
     */
    handleApply() {
      switch (this.type) {
        case 'upload':
          this.uploadFrame(this.fileToUpload)
          break
        case 'frame':
          this.uploadFrame(
            this.dataURLtoFile(this.getCurrentFrame(), 'video-frame.jpeg')
          )
          break
        default:
          this.setThumbnailURL(this.selectedSuggestion)
          break
      }
    },

    manageDisableStates(disabledStates) {
      this.disabled = {
        ...this.disabled,
        ...disabledStates,
      }

      if (disabledStates.suggested) {
        if (!disabledStates.frame) {
          this.type = 'frame'
          return
        }
        if (!disabledStates.upload) {
          this.type = 'upload'
          return
        }
      }

      if (disabledStates.frame) {
        if (!disabledStates.suggested) {
          this.type = 'suggested'
          return
        }
        if (!disabledStates.upload) {
          this.type = 'upload'
          return
        }
      }

      if (disabledStates.upload) {
        if (!disabledStates.suggested) {
          this.type = 'suggested'
          return
        }
        if (!disabledStates.frame) {
          this.type = 'frame'
        }
      }
    },

    /*
     *  Method to clear state
     */
    clearModalState() {
      this.type = 'suggested'
      this.index = 0
      this.videoSource = ''
      this.settingThumbnail = false
      this.fileToUpload = null
      this.uploadFilePreview = ''
      this.selectedSuggestion = ''
      this.suggestedImages = []
      this.emitter = () => {}
      this.disabled = {
        suggested: false,
        frame: false,
        upload: false,
      }
    },

    /*
     *  Method to select the suggested image
     */
    selectSuggestedImage(image) {
      if (this.selectedSuggestion === image) {
        this.selectedSuggestion = ''
      } else {
        this.selectedSuggestion = image
      }
    },

    /*
     *  Method to emit setThumbnail event
     */
    setThumbnailURL(mediaURL, duration = 0) {
      this.emitter(mediaURL, duration, this.type, this.index)
    },

    uploadNewThumbnail(event) {
      const file = event.target.files || event.dataTransfer.files

      if (file.length > 0) {
        this.fileToUpload = file[0]
        this.uploadFilePreview = URL.createObjectURL(file[0])
      }
    },

    /*
     *  Method to upload selected frame on gcs
     */
    async uploadFrame(file) {
      this.settingThumbnail = true
      const filters = {
        media: [
          {
            file,
          },
        ],
        folder_id: null,
      }

      try {
        await this.uploadFilesHelper(
          filters,
          (status, message, mediaContainer) => {
            if (status && mediaContainer[0]) {
              const mediaURL = mediaContainer[0].link
              if (this.type === 'frame' && this.currentThumbnailTime) {
                this.setThumbnailURL(mediaURL, this.currentThumbnailTime)
              } else {
                this.setThumbnailURL(mediaURL)
              }

              this.selectedSuggestion = mediaURL
            }
            this.settingThumbnail = false
          }
        )
      } catch (exception) {
        console.error('Exception occur while adding suggested image', exception)
        this.settingThumbnail = false
      }
    },

    /*
     *  Method to get current frame time of video
     */
    getCurrentTime() {
      if (this.$el && this.$refs) {
        return this.$refs.vid.currentTime * 1000
      }
      return 0
    },

    /*
     *  Method to get current frame image of video
     */
    getCurrentFrame() {
      let base64ImageData = ''
      if (this.$el && this.$refs) {
        const canvas = document.createElement('canvas')
        canvas.crossOrigin = 'anonymous'
        const context = canvas.getContext('2d')
        canvas.width = this.$refs.vid.videoWidth
        canvas.height = this.$refs.vid.videoHeight
        context.drawImage(
          this.$refs.vid,
          0,
          0,
          this.$refs.vid.videoWidth,
          this.$refs.vid.videoHeight
        )
        base64ImageData = canvas
          .toDataURL('image/jpeg')
          .replace('image/jpeg', 'image/octet-stream')
      }

      return base64ImageData
    },

    /*
     *  Method to convert dataURL to javascript File for rest api
     */
    dataURLtoFile(dataUrl, filename) {
      const arr = dataUrl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    getVideoTime() {
      const doSomethingWithTheFrame = (now, metadata) => {
        // Do something with the frame.
        this.currentThumbnailTime = Math.trunc(metadata.mediaTime * 1000)
      }
      // Initially register the callback to be notified about the first frame.
      this.$refs.vid.requestVideoFrameCallback(doSomethingWithTheFrame)
    },
    onHide() {
      this.$refs.vid.removeEventListener('seeking', this.seeking)
    },
  },
}
</script>

<style lang="scss">
@import '~slick-carousel/slick/slick.scss';

// This css is for slick slider internal styling, tailwindcss isn't able to handle it
.suggestion-slider {
  div.slick-list {
    padding: 0 14rem 0 0;
  }
}
</style>
