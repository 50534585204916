<script setup>
import MainGraph from '@src/modules/analytics/views/tiktok/components/graphs/MainGraph.vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import useYoutubeAnalytics from '@src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'
import { watch, computed } from 'vue'

const {
  barChartOptions,
  channelVideoSharingData,
  isReportView,
  SINGLE_GRAPH_TOOLTIPS,
  dataZoomOptions,
} = useYoutubeAnalytics()

const noDataFound = computed(() => {
  return !channelVideoSharingData.value?.length
})

watch(
  () => channelVideoSharingData,
  (newValue, oldValue) => {
    if (newValue.value === oldValue) return

    barChartOptions.value.grid = {
      ...barChartOptions.value.grid,
      right: 30,
      left: 30,
    }
    barChartOptions.value.tooltip = {
      ...barChartOptions.value.tooltip,
      formatter: tooltipFormatter,
    }

    barChartOptions.value.xAxis = {
      ...barChartOptions.value.xAxis,
      name: '',
      axisLabel: {
        ...barChartOptions.value.xAxis.axisLabel,
        interval: 0,
        formatter: (value) => value,
      },
      data: newValue.value?.map((item) => item.name) || [],
    }

    barChartOptions.value.yAxis = {
      ...barChartOptions.value.yAxis,
      name: '',
    }

    barChartOptions.value.series[0] = {
      ...barChartOptions.value.series[0],
      itemStyle: {},
      data: newValue.value?.map((item) => item.value) || [],
    }
  },
  {
    deep: true,
    immediate: true,
  }
)

const tooltipFormatter = (params) => {
  const { axisValue, value } = params[0]
  return `
        <div class="flex flex-col">
           <span class="text-[#000D21] text-base font-weight-500">${axisValue}</span>
           <div class="flex justify-between items-center mt-2">
            <div class="flex items-end">
              <span class="text-[979CA0] text-sm mr-1.5">Share Count</span>
              <span class="text-[#69788E] text-sm font-bold">${value}</span>
            </div>
          </div>
        </div>
`
}
</script>

<template>
  <AnalyticsCardWrapper
    :type="`specific-type-${Math.random()}`"
    :enable-modal="true"
  >
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <h2 class="text-base font-weight-500 select-none">
            Video Sharing Services
          </h2>
          <v-menu
            v-if="!isReportView"
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
          >
            <i class="far fa-question-circle p-0 cursor-pointer"></i>
            <template v-slot:popper>
              <p class="text-sm text-gray-900">
                {{ SINGLE_GRAPH_TOOLTIPS.SHARE_SERVICE_TYPE }}
              </p>
            </template>
          </v-menu>
        </div>
        <div>
          <slot name="header-center" />
        </div>
        <div>
          <slot name="header-right" />
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div v-if="noDataFound" class="analytics-no-data-found">
        <img
          src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
          alt=""
        />
        <p>No data found.</p>
      </div>
      <div v-else class="flex-1">
        <MainGraph
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? {
                  ...barChartOptions,
                  ...dataZoomOptions,
                  grid: { ...barChartOptions.grid, bottom: 70 },
                }
              : {
                  ...barChartOptions,
                  xAxis: [
                    {
                      ...barChartOptions?.xAxis,
                      data: barChartOptions?.xAxis?.data?.slice(0, 6),
                    },
                  ],
                  series: [
                    {
                      ...barChartOptions?.series[0],
                      data: barChartOptions?.series[0]?.data?.slice(0, 6),
                    },
                  ],
                }
          "
          :is-modal="slotProps.isModal"
        />
        <p
          v-if="!slotProps.isModal && !isReportView"
          class="text-sm font-medium text-[#69788E] hover:text-[#2961D2] cursor-pointer text-center"
          @click="slotProps.showModal"
        >
          View More
        </p>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>

<style scoped></style>
