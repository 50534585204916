export function useAiChatFormatter() {
    /* eslint-disable */
    const DefaultFormattingTags = ['i', 'b', 'u', 'strike','pre','code'].map((tag) => ({
        tag,
        tagOpen: new RegExp(`<${tag}>`, 'g'),
        tagClose: new RegExp(`<\/${tag}>`, 'g'),
        placeholderOpen: new RegExp(`___${tag}O___`, 'g'),
        placeholderClose: new RegExp(`___${tag}C___`, 'g'),
    }));
    const replaceFormattingTags = (str, tags = DefaultFormattingTags) => {
        tags.forEach(({ tag, tagOpen, tagClose }) => {
            str = str.replace(tagOpen, `___${tag}O___`).replace(tagClose, `___${tag}C___`);
        });
        return str;
    };
    const restoreFormattingTags = (str, tags = DefaultFormattingTags) => {
        tags.forEach(({ tag, placeholderOpen, placeholderClose }) => {
            str = str.replace(placeholderOpen, `<${tag}>`).replace(placeholderClose, `</${tag}>`);
        });
        return str;
    };
    const textToHTML = (text, editorType = '') => {
        text = replaceFormattingTags(text)
            .replace(/ {2,}/g, ' ')  // Collapse space sequences
            .replace(/\u00a0/g, '&nbsp;')  // Handle non-breaking spaces
            .split('\n'); // Split into lines

        if (editorType === 'blogEditor') {
            text = text.map(line => line.trim() ? `<p>${line} <br></p>` : '').join('');
        } else {
            text = text.join('<br>'); // Convert newlines to <br> in default mode
        }
        return restoreFormattingTags(text);
    };
    const formatText = (text, editorType = '') => {
        // Replace ```javascript``` and ```html``` code blocks with <code> tags
        text = text.replace(/```(javascript|html|HTML|CSS|Css|css)([\s\S]*?)```/g, (_, lang, code) => {
            const escapedCode = code.replace(/</g, "&lt;").replace(/>/g, "&gt;");
            return `<code v-pre class="chat-codeblock">${escapedCode}</code>`;
        });
        // Convert the rest of the text to HTML
        text = textToHTML(text, editorType);
        // Strip href value from <a> tags
        text = text.replace(/<a\s+([^>]*\s*)href="([^"]*)"[^>]*>/gi, '<a href="javascript:;">');
        // add hashtag class
        text = text.replace(/#(\w+)/g, '<span class="chat-hashtag">#$1</span>');
        // Add heading
        text = text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
        return text;
    }

    return {
        textToHTML,
        formatText
    }
}
