import { mapGetters, mapActions } from 'vuex'
import {
  fetchUploadedFilesUrl,
  uploadWidgetFilesUrl,
  getMediaFromLinkUrl,
  canvaAPIKey,
  fetchGCSLinkURL,
  uploadMediaAssetByLinkUrl,
} from '@src/modules/publish/config/api-utils'
import { getImagesContentURL } from '@src/modules/discovery/config/api-utils'
import { EventBus } from '@common/lib/event-bus'
import {
  blogPosting,
  gmb,
  instagramTypes,
  linkedinSharingTypes,
  social,
  youtubeTypes,
  tiktokTypes,
} from '@src/modules/publish/store/states/mutation-types'
import { swalAttributes } from '@common/constants/common-attributes'
import { videoDefaultSharingDetails } from '@src/modules/composer_v2/views/composerInitialState'
import {
  FETCH_GCS_LINK_ERROR,
  UNKNOWN_ERROR,
  UPDATE_CANVA_DESIGN_ERROR,
} from '@common/constants/messages'
import {MULTIMEDIA_ALLOWED_PLATFORMS} from "@common/constants/composer";

const fileMixin = {
  emits: ['carousel-image-update'],
  data() {
    return {
      pasteFromWordInput: '',
    }
  },

  created() {},

  computed: {
    ...mapGetters([
      'getFileWidget',
      'getThreadedTweetsSharingDetails',
      'getJWTToken',
      'getAddMediaWidgetVideoTypeLink',
      'getBlogEditorSelector',
      'getImagesPage',
    ]),
  },

  methods: {
    ...mapActions([
      'setFetchWidgetUploadedFilesLoader',
      'settWidgetUploadedFilesItems',
      'settWidgetUploadedFilesTotal',
      'setWidgetFileProgressBar',
      'setWidgetSelectedUploadedFilesItems',
      'setWidgetUploadingFilesCount',
      'setWidgetLocalUploadsPaginationScroll',
      'setAddMediaWidgetVideoType',
      'setAddMediaWidgetVideoTypeLoader',
      'setAddMediaWidgetVideoTypeLink',
      'setImagesPage',
      'setImagesScroll',
      'setImagesPosts',
      'setImagesPaginationLoader',
      'setImagesLoader',
      'setFileWidgetActiveTab',
      'resetFileWidget',
      'setMediaStorageLimit',
    ]),

    triggerWidgetFileUploader(selector) {
      console.debug('Method:triggerWidgetFileUploader', selector)
      document.getElementById(selector).click()
    },

    uploadWidgetFiles(event) {
      console.debug('Method:uploadWidgetFiles')

      const files = event.target.files || event.dataTransfer.files
      const formData = new FormData()

      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i])
      }

      this.setWidgetUploadingFilesCount(files.length)
      this.processUploadFiles(event, formData)
    },

    processUploadFiles(event, formData) {
      console.debug('Method:processUploadFiles')

      const stateObject = this
      const ajax = new XMLHttpRequest()

      ajax.upload.addEventListener('progress', function (progressEvent) {
        stateObject.widgetFileUploaderProgressHandler(progressEvent)
      })

      ajax.addEventListener('load', function (loadEvent) {
        stateObject.widgetFileUploaderCompleteHandler(loadEvent)
      })

      ajax.addEventListener('error', function (loadEvent) {
        stateObject.widgetFileUploaderErrorHandler(loadEvent)
      })

      ajax.open('POST', uploadWidgetFilesUrl)
      ajax.setRequestHeader('Authorization', 'Bearer ' + this.getJWTToken)
      ajax.send(formData)

      event.target.value = ''
    },

    processFileSelection(file) {
      console.debug('Method::processFileSelection', file)
      if (file && file._id) {
        const selectedItems = this.getFileWidget.localUploads.selectedItems

        if (selectedItems.includes(file) === false) {
          selectedItems.push(file)
        } else {
          const index = selectedItems.indexOf(file)
          if (index > -1) {
            selectedItems.splice(index, 1)
          }
        }
      }
    },
    showInsertFileModel(source) {
      EventBus.$emit('reset-upload-states')

      EventBus.$emit('show-media-library-modal', { source })
      // EventBus.$emit('mediaLibrary', { source: source })

      // this.$bvModal.show('insertFileModel')
    },
    /**
     * This method is responsible for inserting the multimedia files into the composer
     * @param {String} source
     * @param {Object} existingMedia
     * @param {String} type
     * @returns
     * */
    async insertMultimedia(type, mediaList = [], existingMedia = null) {

      if(mediaList.length === 0) { return false }

      // if source is common or instagram then we will allow the multiple type of media
        const multimedia = []
      console.log('mediaList', mediaList)
      mediaList.forEach(el => {
          if (
              el.mime_type === undefined ||
              el.mime_type === 'image/gif' ||
              el.mime_type === 'image/jpg' ||
              el.mime_type === 'image/jpeg' ||
              el.mime_type === 'image/png'
          ) {
            this.$store.dispatch(
                'updateSocialSharingMedia',
                [el]
            )
            multimedia.push(el.link)
          } else {
            multimedia.push({
              name: el.name ? el.name : '',
              title: el.title ? el.title : '',
              mime_type: el.mime_type ? el.mime_type : '',
              link: el.link ? el.link : '',
              duration: el.duration ? el.duration : '00:00:00.00',
              duration_seconds: el.duration_seconds ? el.duration_seconds : 0,
              size: el.size ? el.size : 0,
              thumbnail: el.thumbnail ? el.thumbnail : '',
              thumbnails_suggestions: el.thumbnails_suggestions
                  ? el.thumbnails_suggestions
                  : [],
              converted_video: el.converted_video ? el.converted_video : '',
              converted_link: el.converted_link ? el.converted_link : '',
              converted_mime_type: el.converted_mime_type
                  ? el.converted_mime_type
                  : '',
              converted_size: el.converted_size ? el.converted_size : 0,
              is_converted: el.is_converted || false,
              width: el.width ? el.width : 0,
              height: el.height ? el.height : 0,
            })
          }
        })

        if(type === 'multi-threads') {
            EventBus.$emit(
                'add-media-from-media-library-multi-threads',
                {
                  type: type,
                  media: multimedia,
                  mediaType: 'multimedia',
                  threadIndex: existingMedia ? existingMedia.threadedTweetIndex : null
                }
            )
          return
        }

        EventBus.$emit(
            'add-media-from-media-library',
            {
              type: type,
              media: multimedia,
              mediaType: 'multimedia'
            }
        )
    },
    async insertFile(source, existingMedia = null) {
      console.debug('Method::insertFile', source, existingMedia)
      let userState = true
      const thiss = this
      if (source === 'BlogEditorFile') {
        // eslint-disable-next-line no-undef
        $(this.getFileWidget.localUploads.selectedItems).each(function (
          index,
          el
        ) {
          if (
            el.mime_type === undefined ||
            el.mime_type === 'image/gif' ||
            el.mime_type === 'image/jpg' ||
            el.mime_type === 'image/jpeg' ||
            el.mime_type === 'image/png'
          ) {
            // html += "<img src='" + el.link + "'/><br/>"

            EventBus.$emit('insert-image-to-blog', {link: el.link, type: 'image'})
          } else {
            EventBus.$emit('insert-image-to-blog', {link: el.link, type: 'video'})

            // thiss.getBlogEditorSelector.insertHtml("<iframe style='width: 470px;height: 330px;' src='" + el.link + "' frameborder='0' allowfullscreen></iframe><br/>")
            // html += "<iframe style='width: 470px;height: 330px;' src='" + el.link + "' frameborder='0' allowfullscreen></iframe><br/>"
          }
        })

        // let html = this.fetchCKEditorHtml(false).html()
        // this.setCKEditorHtml(html, false)
        // this.setWidgetSelectedUploadedFilesItems([])
        // $('#insertFileModel').modal('hide')
        this.$bvModal.hide('upload-media-modal')

        return false
      }

      let image = 0
      let video = 0
      let gifs = 0
      let pdf = 0

      // counting files types selected by the user
      this.getFileWidget.localUploads.selectedItems.forEach(function (el) {
        if (el.mime_type === 'image/gif') gifs = gifs + 1
        else if (
          [
            undefined,
            'image/jpg',
            'jpg',
            'image/jpeg',
            'jpeg',
            'image/png',
            'png',
          ].includes(el.mime_type)
        )
          image = image + 1
        else if (el?.extension === 'pdf')
          pdf = pdf + 1
        else video = video + 1
      })

      // if source is common or instagram then we will allow the multiple type of media
      const ALLOWED_SOURCES = ['common'].concat(MULTIMEDIA_ALLOWED_PLATFORMS)
      const selectedItems = this.getFileWidget?.localUploads?.selectedItems || []
      if(ALLOWED_SOURCES.includes(source) && !pdf) {
        if (selectedItems.length > 0) {
          await this.insertMultimedia(source, selectedItems, existingMedia);
        }
        this.$bvModal.hide('upload-media-modal')
        return
      }

      // In case of share media, we will allow the multiple type of media
      if(source === 'share-media' && !pdf) {
        EventBus.$emit('publication-composer-post', {
          mode: source
        })
        setTimeout(async() => { await this.insertMultimedia('common', selectedItems) }, 1000)
        return
      }


      // if user selected video files
      // if (video) {
      //   // showing error message for Pinterest because it is not allowed to share video on Pinterest
      //   /* if (source === 'Pinterest' || source === 'pinterest')
      //     return this.alertMessage(
      //       'You cannot share videos on Pinterest.',
      //       'error'
      //     ) */
      //
      //   // showing error message for Gmb because it is not allowed to share video on Gmb
      //   // if (source === 'Gmb')
      //   // return this.alertMessage(
      //   //   'You cannot share videos on Google My Business.',
      //   //   'error'
      //   // )
      //
      //   // showing error message in case user selected muliple images
      //
      // }

      if (video > 1) {
        this.alertMessage(
            'You can only share one video at a time.',
            'error'
        )
        return false
      }

      if(pdf) {
        if (source !== 'common' && source !== 'linkedin' && source !== 'share-media')
          return this.alertMessage('You can only share PDF on Linkedin.', 'error')
        if (pdf > 1) {
          this.alertMessage(
              'You can only share one PDF at a time.',
              'error')
            return false
      }

        if(!existingMedia) {
          return this.alertMessage(
              'You can only share PDF from composer',
                'error')
        }

        if(existingMedia?.image?.length || existingMedia?.video?.link !== "") {
          if (!(await this.confirmCurrentTypeReplace(source)))
              return
        }

        this.insertPdf(source)
        this.$bvModal.hide('upload-media-modal')
        return true
      }

      if (image) {
        // showing error message for Youtube because it is not allowed to share images on Youtube
        if (source === 'Youtube' || source === 'youtube')
          return this.alertMessage(
            'You cannot share Images on Youtube.',
            'error'
          )
      }
      if (gifs) {
        // showing error message for Youtube because it is not allowed to share gifs on Youtube
        if (source === 'Youtube' || source === 'youtube')
          return this.alertMessage('You cannot share GIF on Youtube.', 'error')

        if (source === 'Tiktok' || source === 'tiktok')
          return this.alertMessage('You cannot share GIF on Tiktok.', 'error')
      }

      // showing error if user selected multiple gif files
      if (gifs > 1)
        return this.alertMessage('You are only allowed to use 1 GIF.', 'error')

      // showing error if user selected different file types
      if ((image && video) || (gifs && video) || (image && gifs))
        return this.alertMessage('You can only add one type of file.', 'error')

      const type = image || gifs ? 'image' : 'video'

      // checking already added media types
      let details = null
      if (existingMedia) {
        userState = false
        details = existingMedia
      } else {
        if (source.includes('ThreadedTweets_')) {
          var index = parseInt(source.split('_')[1])
          source = source.split('_')[0]
          details = this.getThreadedTweetsSharingDetails[index]
        } else {
          details = this[`get${source}SharingDetails`]
        }
      }

      if (details.image) {
        if (details.image.constructor === Array) {
          details.image.forEach(function (el) {
            if (el.href) {
              el = el.href
            }
            const extension = el.split('.').pop()
            extension === 'gif' ? (gifs = gifs + 1) : (image = image + 1)
          })
        } else {
          image = image + 1
        }
      }
      if (details.video && details.video.link) video = video + 1

      // adding images link
      const images = []
      this.$store.dispatch(
        'updateSocialSharingMedia',
        this.getFileWidget.localUploads.selectedItems
      )
      this.getFileWidget.localUploads.selectedItems.forEach(function (el) {
        images.push(el.link)
      })

      // taking confirmation from the user whether he want to replace the current gif or not
      if (gifs > 1) {
        // replacing if user confirm
        if (await this.confirmGifReplace(source)) {
          this.insertImages(
            source,
            images,
            true,
            index,
            existingMedia,
            userState
          )
          // $('#insertFileModel').modal('hide')
          this.$bvModal.hide('upload-media-modal')
        }
        return true
      }

      // taking confirmation from the user whether he want to replace the current media type or not
      if ((image && video) || (gifs && video) || (image && gifs)) {
        // replacing if user confirm
        if (await this.confirmCurrentTypeReplace(source)) {
          type === 'image'
            ? this.insertImages(
                source,
                images,
                true,
                index,
                existingMedia,
                userState
              )
            : this.insertVideo(source, index, existingMedia, userState)
          this.$bvModal.hide('upload-media-modal')
        }
        return true
      }

      // $('#insertFileModel').modal('hide')
      this.$bvModal.hide('upload-media-modal')
      // adding media files
      if (image || gifs)
        return this.insertImages(
          source,
          images,
          false,
          index,
          existingMedia,
          userState
        )
      if (video)
        return this.insertVideo(source, index, existingMedia, userState)
    },

    async insertImageFromLink(source, links) {
      // console.debug('Method:insertFromLink', source, link)
      const selfObject = this
      if (source === 'BlogEditorFile') {
        links.forEach(function (el) {
          selfObject.getBlogEditorSelector.insertHtml(
            "<img src='" + el + "'/><br/>"
          )
        })
        // $('#insertFileModel').modal('hide')
        this.$bvModal.hide('upload-media-modal')
        return true
      }

      let image = 0
      let video = 0
      let gifs = 0
      links.forEach(function (link) {
        const extension = link.split('.').pop()
        extension === 'gif' ? (gifs = gifs + 1) : (image = image + 1)

        // checking already added media types
        const details = selfObject[`get${source}SharingDetails`]
        if (details.image) {
          if (details.image.constructor === Array) {
            details.image.forEach(function (el) {
              const extension = el.split('.').pop()
              extension === 'gif' ? (gifs = gifs + 1) : (image = image + 1)
            })
          } else {
            image = image + 1
          }
        }
        if (details.video && details.video.link) video = video + 1
      })

      // adding image link
      const images = links

      // taking confirmation from the user whether he want to replace the current gif or not
      if (gifs > 1) {
        // replacing if user confirm
        if (await this.confirmGifReplace(source)) {
          this.insertImages(source, images, true)
          // $('#insertFileModel').modal('hide')
          this.$bvModal.hide('upload-media-modal')
        }
        return true
      }

      // taking confirmation from the user whether he want to replace the current media type or not
      if ((image && video) || (gifs && video) || (image && gifs)) {
        // replacing if user confirm
        if (await this.confirmCurrentTypeReplace(source)) {
          this.insertImages(source, images, true)
          // $('#insertFileModel').modal('hide')
          this.$bvModal.hide('upload-media-modal')
        }
        return true
      }

      //  $('#insertFileModel').modal('hide')
      this.$bvModal.hide('upload-media-modal')
      // adding media files
      return this.insertImages(source, images)
    },

    // Click event for Add media button
    async addMediaLink(source) {
      console.debug(
        'Method:addMediaLink',
        source,
        this.getAddMediaWidgetVideoType,
        this.getAddMediaWidgetVideoTypeLink
      )

      if (source === 'BlogEditorFile') {
        // $('#insertFileModel').modal('hide')
        this.$bvModal.hide('insertFileModel')

        if (this.getAddMediaWidgetVideoType === 'video') {
          // if video then add into iframe tag
          this.getBlogEditorSelector.insertHtml(
            "<iframe style='display: inline-block;width: 470px;height: 330px;' src='" +
              this.getAddMediaWidgetVideoTypeLink +
              "' frameborder='0' allowfullscreen></iframe><br/>"
          )
          return true
        }

        if (this.getAddMediaWidgetVideoType === 'image') {
          // if video then add into image tag
          this.getBlogEditorSelector.insertHtml(
            "<img src='" + this.getAddMediaWidgetVideoTypeLink + "'/><br/>"
          )
          return true
        }

        // else we will add this as link
        this.getBlogEditorSelector.insertHtml(
          "<a href='" +
            this.getAddMediaWidgetVideoTypeLink +
            "'>" +
            this.getAddMediaWidgetVideoTypeLink +
            '</a>'
        )
        return true
      }

      if (this.getAddMediaWidgetVideoType === 'image') {
        let image = 0
        let video = 0
        let gifs = 0
        const extension = this.getAddMediaWidgetVideoTypeLink.split('.').pop()
        extension === 'gif' ? (gifs = gifs + 1) : (image = image + 1)

        // checking already added media types
        const details = this[`get${source}SharingDetails`]
        if (details.image) {
          if (details.image.constructor === Array) {
            details.image.forEach(function (el) {
              const extension = el.split('.').pop()
              extension === 'gif' ? (gifs = gifs + 1) : (image = image + 1)
            })
          } else {
            image = image + 1
          }
        }
        if (details.video && details.video.link) video = video + 1

        // adding image link
        const images = [this.getAddMediaWidgetVideoTypeLink]

        // taking confirmation from the user whether he want to replace the current gif or not
        if (gifs > 1) {
          // replacing if user confirm
          if (await this.confirmGifReplace(source)) {
            this.insertImages(source, images, true)
            // $('#insertFileModel').modal('hide')
            this.$bvModal.hide('insertFileModel')
          }

          return true
        }

        // taking confirmation from the user whether he want to replace the current media type or not
        if ((image && video) || (gifs && video) || (image && gifs)) {
          // replacing if user confirm
          if (await this.confirmCurrentTypeReplace(source)) {
            this.insertImages(source, images, true)
            // $('#insertFileModel').modal('hide')
            this.$bvModal.hide('insertFileModel')
          }
          return true
        }

        this.$bvModal.hide('insertFileModel')

        // adding media files
        return this.insertImages(source, images)
      }

      this.alertMessage(
        'By using a direct link you can only add the images to the social post.',
        'error'
      )
    },

    async confirmGifReplace(source) {
      console.debug('Method::confirmGifReplace', source)

      // taking confirmation from the user whether he want to replace the current gif or not
      return await this.$bvModal
        .msgBoxConfirm(
          'You are only allowed to upload 1 GIF. Do you want to replace the current attachments?',
          {
            title: 'Replace current attachment',
            ...swalAttributes(),
          }
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          console.debug('Method::confirmGifReplaceError', err)
          return false
        })
    },

    async confirmCurrentTypeReplace(source) {
      console.debug('Method::confirmCurrentTypeReplace', source)

      // taking confirmation from the user whether he want to replace the current media type or not
      return await this.$bvModal
        .msgBoxConfirm(
          'You can only upload one type of file. Do you want to replace the current attachments?',
          {
            title: 'Replace current attachment',
            ...swalAttributes(),
          }
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          console.debug('Method::confirmCurrentTypeReplaceError', err)
          return false
        })
    },

    insertImages(
      source,
      images,
      replace = false,
      index = 0,
      existingMedia = null,
      useState = true
    ) {
      console.debug(
        'Method::insertImages',
        source,
        images,
        replace,
        index,
        existingMedia,
        useState
      )
      let isThreadedTweets = false
      if (source === 'ThreadedTweets' || source === 'threaded-tweet') {
        isThreadedTweets = true
        if (!replace) {
          const details =
            existingMedia || this.getThreadedTweetsSharingDetails[index]
          if (details.image) {
            if (details.image.constructor === Array) {
              images = images.concat(details.image)
            } else {
              images.push(details.image)
            }
          }
        }
      }

      // adding already added image if case is not replaced
      if (!replace && !isThreadedTweets && source !== 'share-media') {
        const details = existingMedia || this[`get${source}SharingDetails`]
        if (details.image) {
          if (details.image.constructor === Array) {
            images = images.concat(details.image)
          } else {
            images.push(details.image)
          }
        }
      }

      if (useState) {
        // setting images in state
        switch (source) {
          case 'Common':
            this.$store.commit('SET_COMMON_SHARING_VIDEO', null)
            this.$store.commit('setCommonSharingPreview', null)
            this.$store.commit('setCommonSharingImage', images)
            break
          case 'Facebook':
            this.$store.commit('SET_FACEBOOK_SHARING_VIDEO', null)
            this.$store.commit('setFacebookSharingPreview', null)
            this.$store.commit('setFacebookSharingImage', images)
            break
          case 'Twitter':
            this.$store.commit('SET_TWITTER_SHARING_VIDEO', null)
            this.$store.commit('setTwitterSharingPreview', null)
            this.$store.commit(
              'setTwitterSharingImage',
              images.length > 3 ? images.slice(0, 4) : images
            )
            break
          case 'ThreadedTweets':
            console.debug('Method::insertImages case', source, index)
            this.$store.commit('setThreadedTweetsSharingVideo', {
              video: null,
              index,
            })
            // this.$store.commit('setTwitterSharingPreview', null)
            this.$store.commit('setThreadedTweetsSharingImage', {
              image: images.length > 3 ? images.slice(0, 4) : images,
              index,
            })
            console.debug('Method::insertImages', source, index)
            break
          case 'Linkedin':
            this.$store.commit(linkedinSharingTypes.SET_SHARING_VIDEO, null)
            this.$store.commit(linkedinSharingTypes.SET_SHARING_PREVIEW, null)
            this.$store.commit(
              linkedinSharingTypes.SET_SHARING_IMAGE,
              images.length > 9 ? images.slice(0, 9) : images
            )
            break
          case 'Pinterest':
            this.$store.commit('SET_PINTEREST_SHARING_VIDEO', null)
            this.$store.commit('setPinterestSharingPreview', null)
            this.$store.commit('setPinterestSharingImage', images[0])
            break
          case 'Tumblr':
            this.$store.commit('SET_TUMBLR_SHARING_VIDEO', null)
            this.$store.commit('setTumblrSharingPreview', null)
            this.$store.commit('setTumblrSharingImage', images[0])
            break
          case 'Instagram':
            this.$store.commit(instagramTypes.SET_SHARING_VIDEO, null)
            this.$store.commit(instagramTypes.SET_SHARING_PREVIEW, null)
            this.$store.commit(
              instagramTypes.SET_SHARING_IMAGE,
              images.length > 10 ? images.slice(0, 10) : images
            )
            break
          case 'Gmb':
            this.$store.commit(gmb.SET_SHARING_VIDEO, null)
            this.$store.commit(gmb.SET_SHARING_PREVIEW, null)
            this.$store.commit(gmb.SET_SHARING_IMAGE, images[0])
            break
        }
      } else {
        console.debug('Method::insertImages ->', source, images)
        if (source === 'threaded-tweet') {
          EventBus.$emit(
            'add-media-from-media-library-threaded-tweet',
              {
                type: source,
                media: images,
                mediaType: 'image',
                threadIndex: existingMedia ? existingMedia.threadedTweetIndex : null
              }
          )
        } else if (source === 'bluesky-tweet') {
          EventBus.$emit(
              'add-media-from-media-library-bluesky-tweet',
              {
                type: source,
                media: images,
                mediaType: 'image',
                threadIndex: existingMedia ? existingMedia.threadedTweetIndex : null
              }
          )
        }
        else if (source === 'share-media') {
          EventBus.$emit('publication-composer-post', {
            mode: source,
            options: {
              mediaType: 'image',
              images,
            },
          })
        }else if(source === 'carousel'){
          EventBus.$emit('carousel-image-update', {
            mode: source,
            options: {
              mediaType: 'image',
              images,
            },
          })
        } else {
          EventBus.$emit(
            'add-media-from-media-library',
              {
                type: source,
                media: images,
                mediaType: 'image'
              }
          )
        }
      }
      this.setWidgetSelectedUploadedFilesItems([])
    },

    insertVideo(source, index = 0, existingMedia = null, useState = true) {
      console.debug('Method::insertVideo', source, index, existingMedia)

      // making video payload
      const video = []
      const doc = {}
      this.getFileWidget.localUploads.selectedItems.forEach(function (el) {
        if(el?.extension === 'pdf') {
          if(useState) return
          doc.name = el.name ? el.name : ''
          doc.thumbnail = el.thumbnail ? el.thumbnail : ''
          doc.link = el.link ? el.link : ''
          doc.no_of_pages = el.no_of_pages ? el.no_of_pages : 0
        } else {
          video.push({
            name: el.name ? el.name : '',
            title: el.title ? el.title : '',
            mime_type: el.mime_type ? el.mime_type : '',
            link: el.link ? el.link : '',
            duration: el.duration ? el.duration : '00:00:00.00',
            duration_seconds: el.duration_seconds ? el.duration_seconds : 0,
            size: el.size ? el.size : 0,
            thumbnail: el.thumbnail ? el.thumbnail : '',
            thumbnails_suggestions: el.thumbnails_suggestions
                ? el.thumbnails_suggestions
                : [],
            converted_video: el.converted_video ? el.converted_video : '',
            converted_link: el.converted_link ? el.converted_link : '',
            converted_mime_type: el.converted_mime_type
                ? el.converted_mime_type
                : '',
            converted_size: el.converted_size ? el.converted_size : 0,
            facebook_video: el.facebook_video ? el.facebook_video : '',
            facebook_converted_size: el.facebook_converted_size
                ? el.facebook_converted_size
                : 0,
            facebook_converted_mimetype: el.facebook_converted_mimetype
                ? el.facebook_converted_mimetype
                : '',
            width: el.width ? el.width : 0,
            height: el.height ? el.height : 0,
          })
        }
      })

      if (useState) {
        // setting video in state
        switch (source) {
          case 'Common':
            this.$store.commit('SET_COMMON_SHARING_VIDEO', video[0])
            this.$store.commit(social.SET_GMB_OPTIONS, null)
            this.$store.commit('setCommonSharingPreview', null)
            this.$store.commit('setCommonSharingImage', [])
            break
          case 'Facebook':
            this.$store.commit('SET_FACEBOOK_SHARING_VIDEO', video[0])
            this.$store.commit('setFacebookSharingPreview', null)
            this.$store.commit('setFacebookSharingImage', [])
            break
          case 'Twitter':
            this.$store.commit('SET_TWITTER_SHARING_VIDEO', video[0])
            this.$store.commit('setTwitterSharingPreview', null)
            this.$store.commit('setTwitterSharingImage', [])
            break
          case 'ThreadedTweets':
            this.$store.commit('setThreadedTweetsSharingVideo', {
              video: video[0],
              index,
            })
            // this.$store.commit('setTwitterSharingPreview', null)
            this.$store.commit('setThreadedTweetsSharingImage', {
              image: [],
              index,
            })
            break
          case 'Linkedin':
            this.$store.commit(linkedinSharingTypes.SET_SHARING_VIDEO, video[0])
            this.$store.commit(linkedinSharingTypes.SET_SHARING_PREVIEW, null)
            this.$store.commit(linkedinSharingTypes.SET_SHARING_IMAGE, [])
            break
          case 'Pinterest':
            this.$store.commit('SET_PINTEREST_SHARING_VIDEO', video[0])
            this.$store.commit('setPinterestSharingPreview', null)
            this.$store.commit('setPinterestSharingImage', '')
            break
          case 'Tumblr':
            this.$store.commit('SET_TUMBLR_SHARING_VIDEO', video[0])
            this.$store.commit('setTumblrSharingPreview', null)
            this.$store.commit('setTumblrSharingImage', '')
            break
          case 'Instagram':
            this.$store.commit(instagramTypes.SET_SHARING_VIDEO, video[0])
            this.$store.commit(instagramTypes.SET_SHARING_PREVIEW, null)
            this.$store.commit(instagramTypes.SET_SHARING_IMAGE, [])
            break
          case 'Youtube':
            this.$store.commit(youtubeTypes.SET_SHARING_VIDEO, video[0])
            this.$store.commit(youtubeTypes.SET_SHARING_PREVIEW, null)
            this.$store.commit(youtubeTypes.SET_SHARING_IMAGE, '')
            break
          case 'Tiktok':
            this.$store.commit(tiktokTypes.SET_SHARING_VIDEO, video[0])
            // this.$store.commit(tiktokTypes.SET_SHARING_PREVIEW, null)
            // this.$store.commit(tiktokTypes.SET_SHARING_IMAGE, '')
            break
          case 'Gmb':
            this.$store.commit(gmb.SET_SHARING_VIDEO, video[0])
            this.$store.commit(social.SET_GMB_OPTIONS, null)
            this.$store.commit(gmb.SET_SHARING_PREVIEW, null)
            this.$store.commit(gmb.SET_SHARING_IMAGE, '')
            break
        }
      } else {
        if (source === 'threaded-tweet') {
          EventBus.$emit(
            'add-media-from-media-library-threaded-tweet',
              {
                type: source,
                media: video,
                mediaType: 'video',
                threadIndex: existingMedia ? existingMedia.threadedTweetIndex : null
              }
          )
        } else if (source === 'bluesky-tweet') {
          EventBus.$emit(
              'add-media-from-media-library-bluesky-tweet',
              {
                type: source,
                media: video,
                mediaType: 'video',
                threadIndex: existingMedia ? existingMedia.threadedTweetIndex : null
              }
          )
        }
        else if (source === 'share-media') {
          EventBus.$emit('publication-composer-post', {
            mode: source,
            options: {
              mediaType: 'video',
              video,
            },
          })
        } else {
          EventBus.$emit(
            'add-media-from-media-library',
              {
                type: source,
                media: doc?.name ? doc : video,
                mediaType: doc?.name ? 'pdf' : 'video'
              }
          )
        }
      }
      this.setWidgetSelectedUploadedFilesItems([])
    },

    insertPdf(source) {

      const doc = {}
      this.getFileWidget.localUploads.selectedItems.forEach(function (el) {
        if (el?.extension === 'pdf') {
          doc.name = el.name ? el.name : ''
          doc.thumbnail = el.thumbnail ? el.thumbnail : ''
          doc.link = el.link ? el.link : ''
          doc.no_of_pages = el.no_of_pages ? el.no_of_pages : 0
        }
      })

    if (source === 'share-media') {
      EventBus.$emit('publication-composer-post', {
        mode: source,
        options: {
          mediaType: 'pdf',
          doc,
        },
      })
    } else {

      EventBus.$emit(
          'add-media-from-media-library',
          {
            type: source,
            media: doc,
            mediaType: 'pdf'
          }
      )
    }
    },

    fetchWidgetUploadedFiles($state = null) {
      console.debug('Method:fetchWidgetUploadedFiles')

      const selection = this.getFileWidget
      const payload = {
        page: selection.localUploads.page,
        limit: selection.localUploads.limit,
        fileType: selection.localUploads.filters.type,
        sort: selection.localUploads.filters.sort,
        search: selection.localUploads.filters.search,
      }

      this.setFetchWidgetUploadedFilesLoader(true)

      this.postRequest(
        fetchUploadedFilesUrl,
        payload,
        (response) => {
          this.settWidgetUploadedFilesItems(
            selection.localUploads.items.concat(response.data.files.data)
          )
          this.settWidgetUploadedFilesTotal(response.data.files.total)

          if (response.data.files.data.length === 20) {
            this.setWidgetLocalUploadsPaginationScroll(true)
            if ($state) {
              console.debug('$state.loaded')
              $state.loaded()
            }
          } else {
            try {
              this.setWidgetLocalUploadsPaginationScroll(false)
              if ($state) {
                console.debug('$state.complete')
                $state.complete()
              }
            } catch (ex) {
              console.log(ex)
            }
          }

          this.setFetchWidgetUploadedFilesLoader(false)
        },
        (error) => {
          console.debug('Exception in fetchWidgetUploadedFiles', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setFetchWidgetUploadedFilesLoader(false)
        }
      )
    },

    // file upload handlers start

    widgetFileUploaderProgressHandler(event) {
      console.debug('Method:widgetFileUploaderProgressHandler', event)
      let percent = (event.loaded / event.total) * 100
      percent = Math.round(percent)
      this.setWidgetFileProgressBar(percent)
    },

    widgetFileUploaderCompleteHandler(event) {
      console.debug('Method:widgetFileUploaderCompleteHandler')

      try {
        let response = event.target.responseText
        response = JSON.parse(response)

        if (response.status === true) {
          // let localUploads = this.getFileWidget.localUploads
          // $(response.media).each(function (index, el) {
          //   localUploads.items.splice(0, 0, el)
          //   localUploads.selectedItems.push(el)
          // })
          this.resetFileWidget()
          this.setFileWidgetActiveTab('recent-upload')
        } else {
          this.alertMessage(response.message, 'error')
        }
      } catch (e) {
        console.debug('Exception in widgetFileUploaderCompleteHandler', e)
      }

      this.setWidgetUploadingFilesCount(0)
      this.setWidgetFileProgressBar(0)
    },

    widgetFileUploaderErrorHandler(event) {
      console.debug('Method:widgetFileUploaderErrorHandler')
      this.alertMessage(UNKNOWN_ERROR, 'error')
    },

    pasteToEditor() {
      /* eslint-disable */
      this.checkEditorRange()
      const contentHtml = $('<div />', {
        html: $('#wordContentID')[0].outerHTML,
      })
      contentHtml.find('style').remove()
      contentHtml.find('*').removeAttr('style class')
      this.getBlogEditorSelector.insertHtml(
        contentHtml.children().unwrap().html()
      )
      $('#pasteFromWord').modal('hide')
      /* eslint-enable */
    },

    // file upload handlers end
    getMediaURL(event) {
      console.debug(event.currentTarget.value, 'Method:getMediaURL')
      this.setAddMediaWidgetVideoTypeLoader(true)
      // get input value while typing and remove extra spaces
      event.currentTarget.value = event.currentTarget.value.trim()
      this.setAddMediaWidgetVideoTypeLink(event.currentTarget.value)
      // check if it's valid url or not

      if (this.isValidURL(this.getAddMediaWidgetVideoTypeLink)) {
        this.setAddMediaWidgetVideoTypeLoader(false)
        // check if it's image url
        if (this.checkImageURL(this.getAddMediaWidgetVideoTypeLink)) {
          this.setAddMediaWidgetVideoType('image')
        }
        // else case for video url
        else {
          // If url contain youtube
          if (this.getAddMediaWidgetVideoTypeLink.includes('youtube.com')) {
            // Validate youtube url for valid url
            if (this.validateYouTubeUrl(this.getAddMediaWidgetVideoTypeLink)) {
              this.setAddMediaWidgetVideoType('video')
              this.setAddMediaWidgetVideoTypeLink(
                this.validateYouTubeUrl(this.getAddMediaWidgetVideoTypeLink)
              )
            } else {
              this.setAddMediaWidgetVideoType('link')
            }
          } else if (
            this.getAddMediaWidgetVideoTypeLink.includes('dailymotion.com')
          ) {
            // Validate Dailymotion url for valid url
            if (
              this.validateDailymotionUrl(this.getAddMediaWidgetVideoTypeLink)
            ) {
              this.setAddMediaWidgetVideoTypeLink(
                this.validateDailymotionUrl(this.getAddMediaWidgetVideoTypeLink)
              )
              this.setAddMediaWidgetVideoType('video')
            } else {
              this.setAddMediaWidgetVideoType('link')
            }
          } else if (
            this.getAddMediaWidgetVideoTypeLink.includes('vimeo.com')
          ) {
            // Validate Vimeo url for valid url
            if (this.validateVimeoUrl(this.getAddMediaWidgetVideoTypeLink)) {
              this.setAddMediaWidgetVideoTypeLink(
                this.validateVimeoUrl(this.getAddMediaWidgetVideoTypeLink)
              )
              this.setAddMediaWidgetVideoType('video')
            } else {
              this.setAddMediaWidgetVideoType('link')
            }
          }
          // Validate if url container mp4, avi etc extension at the end of url
          else if (this.checkVideoURL(this.getAddMediaWidgetVideoTypeLink)) {
            this.setAddMediaWidgetVideoType('video')
          } else {
            this.setAddMediaWidgetVideoType('link')
          }
        }
        this.setAddMediaWidgetVideoTypeLink(this.getAddMediaWidgetVideoTypeLink)
      } else {
        this.setAddMediaWidgetVideoType(null)
        // this.setAddMediaWidgetVideoTypeLink(null)
        setTimeout(() => {
          this.setAddMediaWidgetVideoTypeLoader(false)
        }, 200)
      }
    },

    getMicroLinkMediaURL(event, type) {
      console.debug(event.currentTarget.value, 'Method:getMicroLinkMediaURL')
      this.setAddMediaWidgetVideoTypeLoader(true)
      // get input value while typing and remove extra spaces
      event.currentTarget.value = event.currentTarget.value.trim()
      this.setAddMediaWidgetVideoTypeLink(event.currentTarget.value)
      // check if it's valid url or not

      if (!this.isValidTypeUrl(this.getAddMediaWidgetVideoTypeLink, type)) {
        this.alertMessage('This URL seems to be invalid', 'error')
        this.setAddMediaWidgetVideoTypeLoader(false)
        return false
      }

      if (this.isValidURL(this.getAddMediaWidgetVideoTypeLink)) {
        this.postRequest(
          getMediaFromLinkUrl,
          { link: this.getAddMediaWidgetVideoTypeLink },
          (response) => {
            if (
              response.data &&
              response.data.data &&
              response.data.data.image &&
              response.data.data.image.url
            ) {
              this.setAddMediaWidgetVideoType('image')
              this.setAddMediaWidgetVideoTypeLink(response.data.data.image.url)
            }
            this.setAddMediaWidgetVideoTypeLoader(false)
          },
          (error) => {
            console.debug('Method:getMicroLinkMediaURLError', error)
            this.setAddMediaWidgetVideoTypeLoader(false)
            this.setAddMediaWidgetVideoType('link')
          }
        )

        // let url = 'https://api.microlink.io/?url=' + this.getAddMediaWidgetVideoTypeLink
        // proxy.get(url).then(response => {
        //   console.debug(response)
        //   if (response.data && response.data.data && response.data.data.image && response.data.data.image.url) {
        //     this.setAddMediaWidgetVideoType('image')
        //     this.setAddMediaWidgetVideoTypeLink(response.data.data.image.url)
        //   }
        //   this.setAddMediaWidgetVideoTypeLoader(false)
        // }).catch(error => {
        //   this.setAddMediaWidgetVideoTypeLoader(false)
        //   this.setAddMediaWidgetVideoType('link')
        // })
        this.setAddMediaWidgetVideoTypeLink(this.getAddMediaWidgetVideoTypeLink)
      } else {
        this.setAddMediaWidgetVideoType(null)
        // this.setAddMediaWidgetVideoTypeLink(null)
        setTimeout(() => {
          this.setAddMediaWidgetVideoTypeLoader(false)
        }, 200)
      }
    },

    isValidTypeUrl(url, type) {
      /* eslint-disable */
      if (type === 'facebook') {
        const res = url.match(
          /(https?:\/\/(.+?\.)?(facebook|fb)\.(com|me)(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/g
        )
        if (res == null) return false
      } else if (type === 'instagram') {
        const res = url.match(
          /(https?:\/\/(.+?\.)?(instagram|instagr)\.(com|am)(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/g
        )
        if (res == null) return false
      } else if (type === 'pinterest') {
        const res = url.match(
          /(https?:\/\/(.+?\.)?(pinterest|pin)\.(com|it)(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/g
        )
        if (res == null) return false
      } else if (type === 'twitter') {
        const res = url.match(
          /(https?:\/\/(.+?\.)?(twitter|t)\.(co|com)(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/g
        )
        if (res == null) return false
      } else if (type === 'flickr') {
        const res = url.match(
          /(https?:\/\/(.+?\.)?(flickr|flic)\.(com|kr)(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/g
        )
        if (res == null) return false
      }
      return true
      /* eslint-enable */
    },

    isValidURL(string) {
      console.debug('Method:isValidURL')
      // eslint-disable-next-line no-useless-escape
      const res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      )
      return res != null
    },

    checkVideoURL(url) {
      console.debug('Method:checkVideoURL')
      return url.match(/\.(mp4|m4v|mov|avi)$/) != null
    },

    checkImageURL(url) {
      console.debug('Method:checkImageURL')
      return !!url.match(/\.(jpeg|jpg|gif|png)$/)
    },

    validateYouTubeUrl(url) {
      console.debug('Method:validateYouTubeUrl')
      if (url !== undefined || url !== '') {
        // eslint-disable-next-line no-useless-escape
        const regExp =
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
        const match = url.match(regExp)
        console.debug(match, 'match string youtube')
        if (match && match[2].length === 11) {
          return 'https://www.youtube.com/embed/' + match[2]
        } else {
          return false
        }
      }
    },

    validateDailymotionUrl(url) {
      console.debug('Method:validateDailymotionUrl')
      if (url !== undefined || url !== '') {
        const matchArray = []
        // eslint-disable-next-line no-useless-escape
        const regExp =
          /(?:dailymotion\.com(?:\/video|\/hub)|dai\.ly)\/([0-9a-z]+)(?:[-_0-9a-zA-Z]+#video=([a-z0-9]+))?/g
        let match
        if (url.includes('dailymotion.com/embed/')) {
          console.debug(url, 'match string dailymotion if')
          return url
        } else {
          while ((match = regExp.exec(url)) != null) {
            if (match.index === regExp.lastIndex) {
              regExp.lastIndex++
            }
            matchArray.push(match[2] ? match[2] : match[1])
          }

          if (matchArray) {
            console.debug(matchArray, 'match string dailymotion else')
            return 'https://www.dailymotion.com/embed/video/' + matchArray[0]
          } else {
            return false
          }
        }
      }
    },

    validateVimeoUrl(url) {
      console.debug('Method:validateVimeoUrl')
      if (url !== undefined || url !== '') {
        // eslint-disable-next-line no-useless-escape
        const regExp =
          '(https?://)?(www.)?(player.)?vimeo.com/([a-z]*/)*([0-9]{6,11})[?]?.*'
        const match = url.match(regExp)
        console.debug(match, 'match string vimeo')
        if (url.match(regExp)) {
          return 'https://player.vimeo.com/video/' + match[5]
        } else {
          return false
        }
      }
    },

    searchImage(type, keyword, $state = null) {
      if (keyword.trim().length > 0) {
        this.setImagesPage(0)
        this.setImagesScroll(true)
        this.setImagesPosts([])
        this.setImagesPaginationLoader(false)
        this.setImagesLoader(true)
        this.getMediaImages(type, keyword, $state)
      } else {
        this.alertMessage('Enter some string to search.')
      }
    },

    getMediaImages(type, keyword, $state = null) {
      const payload = {
        filters: this.getImagesContentFilter,
        source: type,
        search: keyword,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
      }
      this.postRequest(
        getImagesContentURL,
        payload,
        (response) => {
          if (response.data.status) {
            if (this.getImagesPage === 0) this.setImagesPosts([])
            if (!response.data.photos || response.data.photos.length === 0)
              response.data.photos = []

            if (this.getImagesPage >= 1)
              this.setImagesPosts(
                this.getImagesPosts.concat(response.data.photos)
              )
            else this.setImagesPosts(response.data.photos)

            this.setImagesPaginationLoader(true)
            if (response.data.photos.length > 0) {
              this.setImagesScroll(true)
              if ($state) {
                $state.loaded()
              }
            } else {
              this.setImagesScroll(false)
              try {
                if ($state) {
                  $state.complete()
                }
              } catch (ex) {
                console.log(ex)
              }
            }
            this.setImagesLoader(false)
          } else {
            this.setImagesPaginationLoader(true)
            this.setImagesLoader(false)
            this.setImagesScroll(false)
          }
        },
        (response) => {
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      )
    },

    /**
     * initializing canva api
     * @returns {Promise<*>}
     */
    async initializeCanva() {
      console.debug('Method:initializeCanva')
      const stateObject = this

      // checking api is already initialized or not
      let api = this.$store.getters.getCanvaApi
      if (api) return api

      // initializing canva api
      return (async function () {
        if (window.Canva && window.Canva.DesignButton) {
          api = await window.Canva.DesignButton.initialize({
            apiKey: canvaAPIKey,
          })
          stateObject.$store.commit('SET_CANVA_API', api)
          return api
        }
        return false
      })()
    },

    /**
     * initializing crello api
     */
    initializeCrello() {
      console.debug('Method:initializeCrello')
      if (!window.CrelloButton) {
        if (document.getElementById('crelloScript')) {
          return
        }
        const script = document.createElement('script')
        script.setAttribute(
          'src',
          'https://static.create.vista.com/js/frame_v2.min.js'
        )
        script.setAttribute('type', 'text/javascript')
        script.setAttribute('id', 'crelloScript')
        script.async = false // optionally
        document.head.appendChild(script)
      }
      return window.CrelloButton
    },

    /**
     * showing crello modal to create design
     * @param type
     * @param design
     * @param sharingDetails = null
     * @returns {Promise<void>}
     */
    async createCrelloDesign(type, design, sharingDetails = null) {
      console.debug('Method:createCrelloDesign', type, design, sharingDetails)

      // let stateObject = this
      // const api = this.initializeCrello(type)
      const apiKey = this.$store.getters.getCrelloApi
      if (!apiKey) return false
      const stateObject = this
      if (window.CrelloButton) {
        window.CrelloButton.init({
          apiKey,
          designType: design,
          onPublishAction: (options) => {
            const { url, format, extension, id } = options

            stateObject.saveCrelloDesign(
              type,
              url,
              format,
              extension,
              id,
              design,
              sharingDetails
            )
            console.debug('Crello', options)
          },
        }).then((api) => {
          console.log('Crello iframe is loaded now', api)
        })
      }
    },

    saveCrelloDesign(type, url, format, extension, id, design, sharingDetails) {
      console.debug(
        'Method::saveCrelloDesign ~ type, url, format, extension, id, design, sharingDetails -> ',
        type,
        url,
        format,
        extension,
        id,
        design,
        sharingDetails
      )
      const stateObject = this
      const useGlobalState = !sharingDetails
      stateObject.changeSharingImageUploadLoader(type, true, -1)
      // Saving the image to the server as the exportUrl will expire shortly.

      stateObject.postRequest(
        uploadMediaAssetByLinkUrl,
        {
          link: [url],
          name: `${design}.${extension}`,
          design_id: id,
          extension,
          source: 'crello',
          workspace_id:
            stateObject.$store.getters.getWorkspaces.activeWorkspace._id,
        },
        async (response) => {
          if (response.data.status) {
            stateObject.changeSharingImageUploadLoader(type, false, -1)

            response.data.media = response.data.media_container[0]

            if (type === 'Blog') {
              stateObject.$store.commit(blogPosting.SET_BLOG_POSTING_IMAGE, {
                source: 'crello',
                link: response.data.media.link,
              })
              return true
            }

            let video = 0
            let gif = 0
            let image = 0

            // const details = stateObject['get' + type + 'SharingDetails']
            const details =
              sharingDetails || stateObject['get' + type + 'SharingDetails']

            if (details.image) {
              if (details.image.constructor === Array) {
                details.image.forEach(function (el) {
                  if (el.split('.').pop() === 'gif') {
                    gif = gif + 1
                  } else {
                    image = image + 1
                  }
                })
              } else {
                if (details.image.split('.').pop() === 'gif') {
                  gif = gif + 1
                } else {
                  image = image + 1
                }
              }
            }
            if (details.video && details.video.link) video = video + 1

            // For type Instagram and Common, we need to update the multimedia array
            if(['common'].concat(MULTIMEDIA_ALLOWED_PLATFORMS).includes(type)) {
              await stateObject.insertMultimedia(type, response.data.media_container)
              return
            }

            const isVideo = extension === 'mp4'

            if (isVideo) {
              if (image || gif || video) {
                if (!(await stateObject.confirmCurrentTypeReplace()))
                  return false
              }
            } else {
              if (video || gif) {
                if (!(await stateObject.confirmCurrentTypeReplace()))
                  return false
              }
            }

            stateObject.$store.dispatch(
              'updateSocialSharingMedia',
              response.data.media_container
            )
            if (!useGlobalState) {
              if (isVideo) {
                sharingDetails.image = []
                sharingDetails.video = response.data.media
              } else {
                Object.assign(sharingDetails.video, videoDefaultSharingDetails)
                sharingDetails.image.push(response.data.media_container[0].link)
              }
            } else {
              switch (type) {
                case 'Common':
                  stateObject.$store.commit('SET_COMMON_SHARING_VIDEO', null)
                  stateObject.$store.commit('setCommonSharingPreview', null)

                  if (isVideo) {
                    stateObject.$store.commit(
                      'SET_COMMON_SHARING_VIDEO',
                      response.data.media
                    )
                  } else {
                    video || gif
                      ? stateObject.$store.commit('setCommonSharingImage', [
                          response.data.media.link,
                        ])
                      : stateObject.addSharingImage(
                          response.data.media.link,
                          type
                        )
                  }

                  break
                case 'Facebook':
                  stateObject.$store.commit('SET_FACEBOOK_SHARING_VIDEO', null)
                  stateObject.$store.commit('setFacebookSharingPreview', null)
                  if (isVideo) {
                    stateObject.$store.commit(
                      'SET_FACEBOOK_SHARING_VIDEO',
                      response.data.media
                    )
                  } else {
                    video || gif
                      ? stateObject.$store.commit('setFacebookSharingImage', [
                          response.data.media.link,
                        ])
                      : stateObject.addSharingImage(
                          response.data.media.link,
                          type
                        )
                  }
                  break
                case 'Twitter':
                  stateObject.$store.commit('SET_TWITTER_SHARING_VIDEO', null)
                  stateObject.$store.commit('setTwitterSharingPreview', null)
                  if (isVideo) {
                    stateObject.$store.commit(
                      'SET_TWITTER_SHARING_VIDEO',
                      response.data.media
                    )
                  } else {
                    video || gif
                      ? stateObject.$store.commit('setTwitterSharingImage', [
                          response.data.media.link,
                        ])
                      : stateObject.addSharingImage(
                          response.data.media.link,
                          type
                        )
                  }
                  break
                case 'Linkedin':
                  stateObject.$store.commit(
                    linkedinSharingTypes.SET_SHARING_VIDEO,
                    null
                  )
                  stateObject.$store.commit(
                    linkedinSharingTypes.SET_SHARING_PREVIEW,
                    null
                  )
                  if (isVideo) {
                    stateObject.$store.commit(
                      linkedinSharingTypes.SET_SHARING_VIDEO,
                      response.data.media
                    )
                  } else {
                    video || gif
                      ? stateObject.$store.commit(
                          linkedinSharingTypes.SET_SHARING_IMAGE,
                          [response.data.media.link]
                        )
                      : stateObject.addSharingImage(
                          response.data.media.link,
                          type
                        )
                  }
                  break
                case 'Pinterest':
                  stateObject.$store.commit('SET_PINTEREST_SHARING_VIDEO', null)
                  stateObject.$store.commit('setPinterestSharingPreview', null)
                  if (isVideo) {
                    stateObject.$store.commit(
                      'SET_PINTEREST_SHARING_VIDEO',
                      response.data.media
                    )
                  } else {
                    stateObject.$store.commit(
                      'setPinterestSharingImage',
                      response.data.media.link
                    )
                  }
                  break
                case 'Tumblr':
                  stateObject.$store.commit('SET_TUMBLR_SHARING_VIDEO', null)
                  stateObject.$store.commit('setTumblrSharingPreview', null)
                  if (isVideo) {
                    stateObject.$store.commit(
                      'SET_TUMBLR_SHARING_VIDEO',
                      response.data.media
                    )
                  } else {
                    stateObject.$store.commit(
                      'setTumblrSharingImage',
                      response.data.media.link
                    )
                  }
                  break
                case 'Instagram':
                  stateObject.$store.commit(
                    instagramTypes.SET_SHARING_VIDEO,
                    null
                  )
                  stateObject.$store.commit(
                    instagramTypes.SET_SHARING_PREVIEW,
                    null
                  )
                  if (isVideo) {
                    stateObject.$store.commit(
                      instagramTypes.SET_SHARING_VIDEO,
                      response.data.media
                    )
                  } else {
                    isVideo
                      ? stateObject.$store.commit(
                          instagramTypes.SET_SHARING_IMAGE,
                          [response.data.media.link]
                        )
                      : stateObject.addSharingImage(
                          response.data.media.link,
                          type
                        )
                  }
                  break
                case 'Gmb':
                  stateObject.$store.commit(gmb.SET_SHARING_VIDEO, null)
                  stateObject.$store.commit(gmb.SET_SHARING_PREVIEW, null)
                  if (isVideo) {
                    stateObject.$store.commit(
                      gmb.SET_SHARING_VIDEO,
                      response.data.media
                    )
                  } else {
                    stateObject.$store.commit(
                      gmb.SET_SHARING_IMAGE,
                      response.data.media.link
                    )
                  }
                  break
              }
            }
            return true
          } else if (response.data.storageFull) {
            stateObject.setMediaStorageLimit(response.data.usedStorage)
            stateObject.$bvModal.show('media-storage-limits-exceeded-modal')
          } else {
            stateObject.$store.dispatch('toastNotification', {
              message: FETCH_GCS_LINK_ERROR,
              type: 'error',
            })
          }

          console.debug('Method::saveCrelloDesign 3')
          stateObject.changeSharingImageUploadLoader(type, false, -1)
        },
        (response) => {
          console.debug('Exception in fetching GCS link.', response)
          stateObject.$store.dispatch('toastNotification', {
            message: UNKNOWN_ERROR,
            type: 'error',
          })
          stateObject.changeSharingImageUploadLoader(type, false, -1)
        }
      )
    },

    /**
     * showing canva modal to create design
     * @param type
     * @param design
     * @param sharingDetails
     * @returns {Promise<void>}
     */
    async createCanvaDesign(type, design, sharingDetails = null) {
      console.debug('Method:createCanvaDesign', type, design, sharingDetails)

      const stateObject = this
      const api = await this.initializeCanva()

      api.createDesign({
        design: {
          type: design,
        },
        onDesignPublish: function (options) {
          console.debug('onDesignPublish', options)

          stateObject.changeSharingImageUploadLoader(type, true, -1)
          // Saving the image to the server as the exportUrl will expire shortly.
          stateObject.postRequest(
            uploadMediaAssetByLinkUrl,
            {
              link: [options.exportUrl],
              name: options.designTitle,
              design_id: options.designId,
              source: 'canva',
              folder_id: 'null',
              workspace_id:
                stateObject.$store.getters.getWorkspaces.activeWorkspace._id,
            },
            async (response) => {
              if (response.data.status) {
                stateObject.changeSharingImageUploadLoader(type, false, -1)

                response.data.media = response.data.media_container[0].link

                if (type === 'Blog') {
                  stateObject.$store.commit(
                    blogPosting.SET_BLOG_POSTING_IMAGE,
                    { source: 'canva', link: response.data.media }
                  )
                  return true
                }

                let video = 0
                let gif = 0
                // const details = stateObject['get' + type + 'SharingDetails']
                const details =
                  sharingDetails || stateObject['get' + type + 'SharingDetails']
                if (details.image) {
                  if (details.image.constructor === Array) {
                    details.image.forEach(function (el) {
                      if (el.split('.').pop() === 'gif') gif = gif + 1
                    })
                  } else {
                    if (details.image.split('.').pop() === 'gif') gif = gif + 1
                  }
                }
                if (details.video && details.video.link) video = video + 1

                // For type Instagram and Common, we need to update the multimedia array
                if(['common'].concat(MULTIMEDIA_ALLOWED_PLATFORMS).includes(type)) {
                  await stateObject.insertMultimedia(type, response.data.media_container)
                  return
                }

                // taking confirmation from the user to change current file type in case video or gif exists
                if (video || gif) {
                  if (!(await stateObject.confirmCurrentTypeReplace()))
                    return false
                }

                stateObject.$store.dispatch(
                  'updateSocialSharingMedia',
                  response.data.media_container
                )
                if (sharingDetails) {
                    Object.assign(
                        sharingDetails.video,
                        videoDefaultSharingDetails
                    )
                    sharingDetails.image.push(
                        response.data.media_container[0].link
                    )
                } else {
                  switch (type) {
                    case 'Common':
                      stateObject.$store.commit(
                        'SET_COMMON_SHARING_VIDEO',
                        null
                      )
                      stateObject.$store.commit('setCommonSharingPreview', null)
                      video || gif
                        ? stateObject.$store.commit('setCommonSharingImage', [
                            response.data.media,
                          ])
                        : stateObject.addSharingImage(response.data.media, type)
                      break
                    case 'Facebook':
                      stateObject.$store.commit(
                        'SET_FACEBOOK_SHARING_VIDEO',
                        null
                      )
                      stateObject.$store.commit(
                        'setFacebookSharingPreview',
                        null
                      )
                      video || gif
                        ? stateObject.$store.commit('setFacebookSharingImage', [
                            response.data.media,
                          ])
                        : stateObject.addSharingImage(response.data.media, type)
                      break
                    case 'Twitter':
                      stateObject.$store.commit(
                        'SET_TWITTER_SHARING_VIDEO',
                        null
                      )
                      stateObject.$store.commit(
                        'setTwitterSharingPreview',
                        null
                      )
                      video || gif
                        ? stateObject.$store.commit('setTwitterSharingImage', [
                            response.data.media,
                          ])
                        : stateObject.addSharingImage(response.data.media, type)
                      break
                    case 'Linkedin':
                      stateObject.$store.commit(
                        linkedinSharingTypes.SET_SHARING_VIDEO,
                        null
                      )
                      stateObject.$store.commit(
                        linkedinSharingTypes.SET_SHARING_PREVIEW,
                        null
                      )
                      video || gif
                        ? stateObject.$store.commit(
                            linkedinSharingTypes.SET_SHARING_IMAGE,
                            [response.data.media]
                          )
                        : stateObject.addSharingImage(response.data.media, type)
                      break
                    case 'Pinterest':
                      stateObject.$store.commit(
                        'SET_PINTEREST_SHARING_VIDEO',
                        null
                      )
                      stateObject.$store.commit(
                        'setPinterestSharingPreview',
                        null
                      )
                      stateObject.$store.commit(
                        'setPinterestSharingImage',
                        response.data.media
                      )
                      break
                    case 'Tumblr':
                      stateObject.$store.commit(
                        'SET_TUMBLR_SHARING_VIDEO',
                        null
                      )
                      stateObject.$store.commit('setTumblrSharingPreview', null)
                      stateObject.$store.commit(
                        'setTumblrSharingImage',
                        response.data.media
                      )
                      break
                    case 'Instagram':
                      stateObject.$store.commit(
                        instagramTypes.SET_SHARING_VIDEO,
                        null
                      )
                      stateObject.$store.commit(
                        instagramTypes.SET_SHARING_PREVIEW,
                        null
                      )
                      video || gif
                        ? stateObject.$store.commit(
                            instagramTypes.SET_SHARING_IMAGE,
                            [response.data.media]
                          )
                        : stateObject.addSharingImage(response.data.media, type)
                      break
                    case 'Gmb':
                      stateObject.$store.commit(gmb.SET_SHARING_VIDEO, null)
                      stateObject.$store.commit(gmb.SET_SHARING_PREVIEW, null)
                      stateObject.$store.commit(
                        gmb.SET_SHARING_IMAGE,
                        response.data.media
                      )
                      break
                  }
                }

                return true
              } else if (response.data.storageFull) {
                stateObject.setMediaStorageLimit(response.data.usedStorage)
                stateObject.$bvModal.show('media-storage-limits-exceeded-modal')
              } else {
                stateObject.$store.dispatch('toastNotification', {
                  message: FETCH_GCS_LINK_ERROR,
                  type: 'error',
                })
              }
              stateObject.changeSharingImageUploadLoader(type, false, -1)
            },
            (response) => {
              console.debug('Exception in fetching GCS link.', response)
              stateObject.$store.dispatch('toastNotification', {
                message: UNKNOWN_ERROR,
                type: 'error',
              })
              stateObject.changeSharingImageUploadLoader(type, false, -1)
            }
          )
        },
      })
    },

    /**
     * showing canva modal to update design
     * @param media
     * @returns {Promise<void>}
     */
    async editCanvaDesign(media) {
      console.debug('Method::editCanvaDesign', media)

      const stateObject = this
      const api = await this.initializeCanva()

      api.editDesign({
        design: {
          id: media.canva_design_id,
        },
        onDesignPublish: function (options) {
          console.debug('onDesignPublish', options)

          // showing loader for pocessing
          const index =
            stateObject.$store.getters.getFileWidget.localUploads.items.findIndex(
              (item) => media.canva_design_id === item.canva_design_id
            )
          if (
            stateObject.$store.getters.getFileWidget.localUploads.items[index]
          )
            stateObject.$store.getters.getFileWidget.localUploads.items[
              index
            ].is_processing = true

          // Saving the image to the server as the exportUrl will expire shortly.
          stateObject.postRequest(
            fetchGCSLinkURL,
            {
              link: options.exportUrl,
              name: options.designTitle,
              canva_design_id: options.designId,
              source: 'canva',
              workspace_id:
                stateObject.$store.getters.getWorkspaces.activeWorkspace._id,
            },
            async (response) => {
              if (
                stateObject.$store.getters.getFileWidget.localUploads.items[
                  index
                ]
              )
                stateObject.$store.getters.getFileWidget.localUploads.items[
                  index
                ].is_processing = false
              if (response.data.status) {
                // updating item in the state
                stateObject.$store.getters.getFileWidget.localUploads.items.splice(
                  index,
                  1,
                  response.data.media
                )
                return true
              }
              stateObject.$store.dispatch('toastNotification', {
                message: UPDATE_CANVA_DESIGN_ERROR,
                type: 'error',
              })
            },
            (response) => {
              if (
                stateObject.$store.getters.getFileWidget.localUploads.items[
                  index
                ]
              )
                stateObject.$store.getters.getFileWidget.localUploads.items[
                  index
                ].is_processing = false
              console.debug('Exception in fetching GCS link.', response)
              stateObject.$store.dispatch('toastNotification', {
                message: UNKNOWN_ERROR,
                type: 'error',
              })
            }
          )
        },
      })
    },
  },
}

export { fileMixin }
