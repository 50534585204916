<script setup>
import { computed } from 'vue'
import CardsComponent from '@src/modules/analytics/views/performance-report/label-and-campaign/components/CardsComponent.vue'
import ImpressionsGraph from '@src/modules/analytics/views/performance-report/label-and-campaign/components/graphs/ImpressionsGraph.vue'
import EngagementsGraph from '@src/modules/analytics/views/performance-report/label-and-campaign/components/graphs/EngagementsGraph.vue'
import LableAndCampaignTable from '@src/modules/analytics/views/performance-report/label-and-campaign/components/LableAndCampaignTable.vue'
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'
import ReportFooter from '@/src/modules/analytics/components/reports/ReportFooter.vue'

// CONSTATS
import { PLATFORMS } from '@src/modules/analytics_v3/constants/constants'

const {
  breakdownDataByLabel,
  breakdownDataByImpression,
  breakdownDataByEngagement,
  insightsGraphBreakDown,
  isLoadingStates,
} = useLabelAndCampaign()

const chunkSize = 9

/**
 * Computes the array of start indexes for chunks of data.
 * @returns {Array<number>} An array containing the start indexes for each chunk.
 */
const breakdownDataByLabelChunks = computed(() => {
  const startIndexes = []

  let i = 0
  while (i < breakdownDataByLabel.value.length) {
    startIndexes.push(i)
    i += chunkSize
  }

  return startIndexes
})

const breakdownDataByImpressionChunks = computed(() => {
  const startIndexes = []

  let i = 0
  while (i < breakdownDataByImpression.value.length) {
    startIndexes.push(i)
    i += chunkSize
  }

  return startIndexes
})

const breakdownDataByEngagementChunks = computed(() => {
  const startIndexes = []

  let i = 0
  while (i < breakdownDataByEngagement.value.length) {
    startIndexes.push(i)
    i += chunkSize
  }

  return startIndexes
})

/**
 * Computes the total page size based on the number of hard-coded sections and the number of chunks of array.
 * @returns {number} The total page size.
 */
const totalPageSize = computed(() => {
  return (
    breakdownDataByLabelChunks.value.length +
    breakdownDataByImpressionChunks.value.length +
    breakdownDataByEngagementChunks.value.length
  )
})

/**
 * Get the actual page number based on the section and index
 * @param {number} section - The section number
 * @param {number} index - The index within the section
 * @returns {number} The actual page number
 */
const getPageNumber = (section, index = 0) => {
  return section + index
}
</script>

<template>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Overview</h2>
      <div class="flex flex-col gap-7">
        <CardsComponent
          v-if="!isLoadingStates.isLoadingOverview"
          :data="insightsGraphBreakDown"
        />
      </div>
    </div>
    <ReportFooter :current-page="getPageNumber(1)" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Impressions</h2>
      <div class="flex flex-col gap-7">
        <ImpressionsGraph
          v-if="!isLoadingStates.isLoadingImpressions"
          :data="breakdownDataByImpression"
        />
      </div>
    </div>
    <ReportFooter :current-page="getPageNumber(2)" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Engagements</h2>
      <div class="flex flex-col gap-7">
        <EngagementsGraph
          v-if="!isLoadingStates.isLoadingEngagements"
          :data="breakdownDataByEngagement"
        />
      </div>
    </div>
    <ReportFooter :current-page="getPageNumber(3)" :total-pages="totalPageSize" />
  </div>

  <template v-for="(startIndex, index) in breakdownDataByLabelChunks" :key="startIndex">
    <div class="page_view flex flex-col justify-between">
      <div class="subpage">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Labels and Campaigns Breakdown</h2>
        <LableAndCampaignTable
          v-if="!isLoadingStates.isLoadingBreakdown"
          :data="breakdownDataByLabel.slice(
            startIndex,
            startIndex + chunkSize,
          )"
        />
      </div>
      <ReportFooter :current-page="getPageNumber(4, index)" :total-pages="totalPageSize" />
    </div>
  </template>
</template>
