import { useStore } from '@state/base'
import { socialChannelsNameArray } from '@common/constants/common-attributes'
import isEmpty from 'is-empty'
import { commonMethods } from '@common/store/common-methods'
import useDateFormat from '@/src/modules/common/composables/useDateFormat'
import { fetchPlansURL } from '@/src/modules/planner/config/api-utils'
import proxy from '@/src/modules/common/lib/http-common'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

/**
 * Composable for media library functionality
 * Contains methods only for media operations
 * @returns {Object} - Media library methods
 */
export default function useMediaLibrary() {
  // Composables
  const { momentWrapper } = useDateFormat()
  const { getSocialImageRounded } = useComposerHelper()
  const { getters } = useStore()

  /**
   * Fetch usage plans for the current media
   * @param {Object} currentMedia - Current media object
   * @param {Function} setUsagePlans - Function to set usage plans
   * @param {Function} setIsLoading - Function to set loading state
   */
  const fetchUsagePlans = async (currentMedia, setUsagePlans, setIsLoading) => {
    if (!currentMedia?.planIds?.length) {
      setUsagePlans([])
      return
    }

    const options = {
      workspace_id: getters.getWorkspaces.activeWorkspace._id,
      specific_plans: currentMedia?.planIds,
      source: 'web',
      route_name: 'media_usage',
    }
    
    try {
      setIsLoading(true)
      const response = await proxy.post(fetchPlansURL, options)
      if (response.data.status) {
        setUsagePlans(response.data.plans)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Get platforms from account selection
   * @param {Object} platforms - Platforms object
   * @returns {Array} - Array of platform names
   */
  const getPlatforms = (platforms) => {
    return socialChannelsNameArray.filter(
      (platform) => !isEmpty(platforms[platform])
    )
  }

  /**
   * Get post accounts
   * @param {Object} item - Plan item
   * @returns {Array} - Array of accounts
   */
  const postAccounts = (item) => {
    if (item.account_selection) {
      const social = getPlatforms(item.account_selection)
      const accounts = []
      social.forEach((platform) => {
        item.account_selection[platform].forEach((account) => {
          accounts.push({ ...account, platform })
        })
      })
      return accounts.flat()
    }
    return []
  }

  /**
   * Social account image fallback if there's no image
   * @param {Event} event - Image error event
   */
  const socialAccountImgFallback = (event) => {
    event.target.src =
      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
  }

  /**
   * Check if the current media is a video
   * @param {String} mediaType - Media type
   * @returns {Boolean} - True if media is a video
   */
  const isVideo = (mediaType) => mediaType?.includes('video/') || false

  /**
   * Copy media link to clipboard
   * @param {String} str - Media link
   * @param {Function} setIsCopied - Function to set copied state
   * @returns {Promise} - Promise that resolves when link is copied
   */
  const copyMediaLink = async (str, setIsCopied) => {
    const el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()

    try {
      if (navigator?.clipboard?.writeText) {
        await navigator.clipboard.writeText(str)
        document.body.removeChild(el)
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 3000)
        return true
      }
    } catch (err) {
      console.error('Failed to copy:', err)
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('The Clipboard API is not available.')
  }

  /**
   * Convert bytes to human readable format
   * @param {Number} bytes - Bytes to convert
   * @returns {String} - Human readable size
   */
  const bytesConversion = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    const value = bytes / Math.pow(1024, i)
    return Math.round(value * 100) / 100 + ' ' + sizes[i]
  }

  /**
   * Handle keyboard events for navigation
   * @param {Event} e - Keyboard event
   * @param {Function} previousImage - Function to go to previous image
   * @param {Function} nextImage - Function to go to next image
   */
  const handleKeyDown = (e, previousImage, nextImage) => {
    if (e.keyCode === 37) previousImage()
    if (e.keyCode === 39) nextImage()
  }

  /**
   * Set up event listeners when modal is shown
   */
  const setupModalEventListeners = () => {
    commonMethods.toggleWidgets(true)
  }

  /**
   * Clean up event listeners when modal is hidden
   */
  const cleanupModalEventListeners = () => {
    commonMethods.toggleWidgets(false)
  }

  /**
   * Download media file
   * @param {Object} media - Media object to download
   * @param {Function} setIsDownloading - Function to set downloading state
   */
  const downloadMedia = async (media, setIsDownloading) => {
    try {
      if (!media?.link) return
      setIsDownloading(true)
      const response = await fetch(media.link)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = media.name || 'download'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Download failed:', error)
    } finally {
      setIsDownloading(false)
    }
  }

  /**
   * Helper function for string truncation
   * @param {String} str - String to truncate
   * @param {Number} num - Maximum length
   * @returns {String} - Truncated string
   */
  const truncateString = (str, num) => {
    if (!str) return ''
    if (str.length <= num) return str
    return str.slice(0, num) + '...'
  }

  /**
   * Calculate media dimensions
   * @param {Object} media - Media object
   * @param {Boolean} isVideoMedia - Whether the media is a video
   * @returns {String|null} - Formatted dimensions or null
   */
  const calculateMediaDimension = (media, isVideoMedia) => {
    if (isVideoMedia && media?.width && media?.height) {
      return `${media.width} x ${media.height}`
    } else if (media?.w && media?.h) {
      return `${media.w} x ${media.h}`
    }
    return null
  }

  return {
    // Methods
    fetchUsagePlans,
    getPlatforms,
    postAccounts,
    socialAccountImgFallback,
    isVideo,
    copyMediaLink,
    bytesConversion,
    handleKeyDown,
    setupModalEventListeners,
    cleanupModalEventListeners,
    downloadMedia,
    truncateString,
    calculateMediaDimension,
    
    // Utilities from other composables
    momentWrapper,
    getSocialImageRounded
  }
}
