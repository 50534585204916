<template>
  <div class="w-full cst-drop-shadow bg-white rounded-xl border border-[#DBDBDB]">
    <div class="flex items-center px-3 py-3 font-bold">
      <img
          class="rounded-full object-cover border border-solid border-black"
          width="40"
          height="40"
          :src="account.image"
          alt=""
          @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
            "
      />
      <div class="px-2 font-bold">
        {{ accountName }}
        <span
            v-if="isLocationAdded"
            class="block text-gray-800 font-normal text-xs"
        >
              {{ isLocationAdded }}
            </span>
      </div>
      <div
          class="ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-light-red"
      >
        <img
            src="@assets/img/integration/instagram-icon.svg"
            alt=""
            class="w-5"
        />
      </div>
    </div>
    <div class="w-full h-full">
      <b-carousel
          v-if="computedMediaItems"
          ref="carousel"
          class="instagram-preview-carousel cursor-pointer"
          :interval="0"
          :controls="computedMediaItems.length > 1"
          :indicators="computedMediaItems.length > 1"
          @sliding-end="onSlideChange()"
      >
        <b-carousel-slide
            v-for="(image, index) in computedMediaItems"
            :key="index"
        >
          <template v-if="typeof image === 'string' && !image" v-slot:img>
            <div class="max-h-[470px] w-full block relative">
              <div class="block overflow-hidden relative bg-white pt-[100%]">
                <img
                    class="w-full h-full absolute top-0 left-0"
                    src="@src/assets/img/no_data_images/no_media_found.svg"
                    alt=""
                />
              </div>
            </div>
          </template>
          <template v-else-if="typeof image === 'string'" v-slot:img>
            <div class="max-h-[470px] w-full  flex items-center" :class="computedMediaItems.length > 1 ? 'min-h-[470px]' : ''">
              <div class="overflow-hidden h-full">
                <img
                    v-tooltip="{
                        content: 'Image Preview',
                        placement: 'auto',
                       }"
                    class="w-full object-contain"
                    :src="image"
                    alt=""
                    @click="openLightBox(image, index)"
                    @error="
                        (event) => {
                          event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                        }
                      "
                />
              </div>
            </div>
            <template v-if="userTags[image] && userTags[image].length > 0">
              <div
                  v-for="(mention, key) in userTags[image]"
                  :key="key"
                  class="absolute z-20"
                  :style="`top: calc((${mention.y} * var(--image-height))); left: calc((${mention.x} * var(--image-width)))`"
              >
                <div
                    class="absolute shadow left-1/2 mt-2.5 transform -translate-x-1/2 rounded px-4 right-0 text-base w-max h-max"
                    :class="{
                        'bg-black-100 text-white py-1 ': mention.username,
                        'bg-gray-50 text-black-100 py-3': !mention.username,
                      }"
                >
                  <div
                      class="absolute left-0 w-full bottom-full flex justify-center items-center pt-1 h-1"
                  >
                    <div
                        class="h-2 w-2 bg-black-100 transform rotate-45"
                    ></div>
                  </div>
                  <template v-if="mention.username">
                    {{ mention.username }}
                  </template>
                </div>
              </div>
            </template>
          </template>
          <template v-if="typeof image !== 'string'" v-slot:img>
            <div
                v-if="image.thumbnail"
                class="relative h-full text-white text-center"
            >
              <img
                  class="w-full"
                  style="max-height: 500px"
                  :src="getVideoThumbnail(image)"
                  alt="thumbnail"
              />
              <i
                  class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 pl-[1.2rem] pt-4 pb-4 pr-4 rounded-full bg-[#00000070] border-solid -translate-x-1/2 -translate-y-1/2"
                  @click.prevent="$emit('displayFile', 'video',
                  image.converted_video || image.link,
                  0)
                  "
              ></i>
            </div>
            <img
                v-else
                class="w-full"
                src="@src/assets/img/no_data_images/no_media_found.svg"
                alt=""
            />
          </template>
          <div
              v-if="userTags[image] && userTags[image].length > 0"
              class="h-8 w-8 rounded-full bg-black-800 hover:bg-black-600 flex justify-center items-center absolute bottom-0 -left-3/20 shadow cursor-pointer"
              @click="handleUserTags"
          >
            <svg
                aria-label="Tags"
                class="_ab6-"
                color="#ffffff"
                fill="#ffffff"
                height="12"
                role="img"
                viewBox="0 0 24 24"
                width="12"
            >
              <path
                  d="M21.334 23H2.666a1 1 0 01-1-1v-1.354a6.279 6.279 0 016.272-6.272h8.124a6.279 6.279 0 016.271 6.271V22a1 1 0 01-1 1zM12 13.269a6 6 0 116-6 6.007 6.007 0 01-6 6z"
              ></path>
            </svg>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div
        v-if="!hideFooter"
        class="flex items-center px-3 py-2 reaction-icons space-x-5 text-xl"
    >
      <i class="far fa-heart"></i>
      <i class="far fa-comment"></i>
      <i class="far fa-paper-plane"></i>
      <div class="ml-auto"><i class="far fa-bookmark"></i></div>
    </div>
    <div
        v-if="getDescription"
        class="items-center px-3 py-1 pb-3"
        :class="{ 'pt-3': hideFooter }"
    >
      <span class="font-bold">{{ accountName }}</span>
      <span
          class="px-2 whitespace-pre-wrap leading-4"
          v-html="getDescription"
      ></span>
      <span
          v-if="getDescription.length > charLimit && loadMore"
          class="text-blue-300 cursor-pointer"
          @click="changeLoadMore()"
      >
            Load More</span
      >
      <span
          v-if="getDescription.length > charLimit && !loadMore"
          class="text-blue-300 cursor-pointer"
          @click="changeLoadMore()"
      >
            Show Less</span
      >
    </div>
    <div
        v-if="commentDetails?.first_comment || comment"
        class="flex justify-center px-2 py-3"
        style="border-top: 1px solid #dee2e6"
    >
      <div>
        <img
            class="rounded-full object-cover border border-solid border-black"
            width="30"
            height="30"
            :src="account.image"
            alt=""
            @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
        />
      </div>
      <div class="ml-1 w-full rounded-lg px-2">
        <span class="font-bold">{{ accountName }} </span> {{ comment }}
      </div>
      <div class="my-auto">
        <i class="far fa-heart"></i>
      </div>
    </div>
    <div v-if="commentDetails?.first_comment" class="comments-status-box">
      <p v-if="commentDetails?.first_comment?.status === true" class="ml-2">
        <span class="status published"></span>
        <span class="text">Published</span>
        <span> | [First comment has been posted successfully.]</span>
      </p>
      <p v-else-if="!commentDetails?.first_comment?.status" class="ml-2">
        <span class="status failed"></span>
        <span class="text">Failed</span>
        <span> | {{commentDetails?.first_comment?.message}}</span>
      </p>

    </div>
  </div>
  <VueEasyLightbox
      :visible="visible"
      :imgs="[selectedImage]"
      :index="0"
      @hide="visible = false"
  ></VueEasyLightbox>
</template>
<script setup>
import {
  computed,
  defineProps,
  ref,
} from 'vue'

import VueEasyLightbox from 'vue-easy-lightbox'

// Create a ref for the carousel
const carousel = ref(null)
const visible = ref(false)
const selectedImage = ref('')

const emit = defineEmits(['change-load-more'])

const props = defineProps({
  account: {
    type: [Object, Array],
    default: () => ({}),
    required: true,
  },
  isLocationAdded: {
    type: String,
    default: '',
  },
  detail: {
    type: Object,
    default: () => ({}),
    required: false,
  },
  hideFooter: {
    type: Boolean,
    default: false,
    required: false,
  },
  userTags: {
    type: [Object, Array],
    default: () => ({}),
    required: false,
  },
  getDescription: {
    type: String,
    default: '',
  },
  commentDetails: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  accountName: {
    type: String,
    default: '',
    required: true,
  },
  comment: {
    type: String,
    default: '',
    required: false,
  },
  loadMore: {
    type: Boolean,
    default: true,
  },
  charLimit: {
    type: Number,
    default: 500,
  },
})

const computedMediaItems = computed(() => {
  return props.detail?.multimedia?.length ? props.detail.multimedia : (props.detail?.image?.length ? props.detail.image : [''])
})

const handleUserTags = (strategy = 'auto') => {
  let carouselRef = carousel.value?.$refs.inner
  if (carouselRef) {
    let canShowtags = true

    switch (strategy) {
      case 'show':
        carouselRef.setAttribute('data-show-tags', 'true')
        break
      case 'hide':
        carouselRef.setAttribute('data-show-tags', 'false')
        canShowtags = false
        break
      default:
        if (
            carouselRef.getAttribute('data-show-tags') &&
            carouselRef.getAttribute('data-show-tags') === 'true'
        ) {
          carouselRef.setAttribute('data-show-tags', 'false')
          canShowtags = false
        } else {
          carouselRef.setAttribute('data-show-tags', 'true')
        }
        break
    }

    if (canShowtags) {
      carouselRef.style.setProperty(
          '--image-height',
          `${carouselRef.clientHeight}px`
      )
      carouselRef.style.setProperty(
          '--image-width',
          `${carouselRef.clientWidth}px`
      )
    } else {
      carouselRef.style.setProperty('--image-height', `0`)
      carouselRef.style.setProperty('--image-width', `0`)
    }
  }
}

const getVideoThumbnail = (item) => {
  let thumbnail = item?.thumbnails_suggestions?.length ? item?.thumbnails_suggestions[0] : item.thumbnail
  if (thumbnail)
      // custom thumbnail is supported only for reels
    return thumbnail
  // If the thumbnail url includes the /media_library/, then it is a custom thumbnail.
  // If the thumbnail url includes the ?offset= then it is 'choose from video' thumbnail.
  // If its custom thumbnail and not 'choose from video' then use the first thumbnail
  // from the suggestions as custom thumbnail is not supported in instagram.
  if (
      thumbnail.indexOf('/media_library/') > -1 &&
      thumbnail.indexOf('?offset=') === -1
  ) {
    thumbnail = item.thumbnails_suggestions[0]
  }
  return thumbnail
}

const onSlideChange = () => {
  handleUserTags('hide')
}

const changeLoadMore = () => {
  emit('change-load-more')
}

const openLightBox = (image, index = 0) => {
  visible.value = true
  selectedImage.value = image
}


</script>
