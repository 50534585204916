import { ref, inject, computed } from 'vue'
import { integrationsBaseURL } from '@src/config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { EventBus } from '@common/lib/event-bus'
import proxy from '@common/lib/http-common'
import { platforms } from '@src/modules/integration/store/states/mutation-types'
import useIntegrationComposable from "@modules/integration/Composables/useIntegrationComposable";
import useWorkspace from "@common/composables/useWorkspace";
import { useStore } from '@/src/store/base'
import { userMavenMixin } from '@/src/tracking/userMavenMixin'
import { subscriptionPlanMixin } from '@/src/modules/common/mixins/setting/subscriptionPlanMixin'

// declarations
const OPTIONS = {
  VIDEO: 'video',
  SOCIAL_CONNECT: 'social-connect',
}

const selectedPlatform = ref({ name: null })
const selectedAccount = ref(null)
const isDirty = ref(false)
const fetching = ref(false)
const selectedOption = ref(OPTIONS.SOCIAL_CONNECT)
export default function useSocialAccountsModal() {
  const root = inject('root')
  const { $bvModal } = root
  const store = useStore()
  const {getAuthorizationUrl} = useIntegrationComposable()
  const {isActiveUserSuperAdmin} = useWorkspace()

  const hasConnectedAccounts = computed(
    () => store.getters.getSocialAccountsList.length > 0
  )

  const buttonText = computed(() => {
    const steps = store.getters.getActiveWorkspace.onboarding_steps

    if (selectedOption.value === OPTIONS.VIDEO) {
        return `Go Back`
    }

    if (selectedOption.value === OPTIONS.SOCIAL_CONNECT) {
      if (
        steps?.accounts_connection_modal_closed &&
        steps.accounts_connection_modal_closed.status === true
      ) {
        if (hasConnectedAccounts.value) {
          return `Let's Go`
        }

        return `Skip & Continue`
      }

      if (hasConnectedAccounts.value) {
        return `Let's Go`
      }

      if(!isActiveUserSuperAdmin()) {
        return `Continue`
      }

      return `Skip & Continue`
    }

    return `Skip & Connect`
  })

  const processReconnectResponse = async (response) => {
    if (response?.data) {
      if (response.data.limitExceed) {
        subscriptionPlanMixin.methods.showUpgradeModal()
        return
      }
      if (response.data.status === true) {
        userMavenMixin.methods.trackUserMaven('connected_social_accounts', {
            platform: response.data.process,
        })
        _cio.track('connected_social_accounts', {
            platform: response.data.process,
        });
        switch (response.data.process) {
          case 'Twitter':
          case 'Facebook':
          case 'Instagram':
          case 'Linkedin':
          case 'Pinterest':
          case 'tumblr':
          case 'Gmb':
          case 'Youtube':
          case 'threads':
          case 'tiktok':
            if (
              response.data.pages ||
              response.data.groups ||
              response.data.profile ||
              response.data.boards ||
              response.data.profiles ||
              response.data.blogs ||
              response.data.locations ||
              response.data.channels ||
              (['tiktok', 'Twitter', 'threads'].includes(response.data.process) &&
                response.data.status)
            ) {
              store.commit(platforms.SET_CONNECTION_STATE, response.data)
              $bvModal.show('get-started-modal')

              if (
                ['tiktok', 'Twitter'].includes(response.data.process)
              ) {
                if (response.data.not_allowed.length > 0) {
                  EventBus.$emit('showNotAllowedModal', {
                    notAllowedIds: response.data.not_allowed,
                    firstSectionItems: [response.data.profile || []],
                    firstSectionHeader: 'Social Account',
                  })
                } else {
                  await store.dispatch('toastNotification', {
                    message: response.data.message,
                    type: 'success',
                  })
                }
                return
              }

              if (!['tiktok', 'Twitter', 'threads'].includes(response.data.process)) {
                $bvModal.show('save-social-accounts')
              }

              return
            }
            break
        }
        if (response.data?.not_allowed?.length) {
          EventBus.$emit('showNotAllowedModal', {
            notAllowedIds: response.data.not_allowed,
            firstSectionItems: [response.data.profile || []],
            firstSectionHeader: 'Social Account',
          })
          return
        }
        await store.dispatch('toastNotification', {
          message: response.data.message,
          type: 'success',
        })
      }
    }
  }

  const connectAccount = async (process = 'connect', subType = '') => {
    const details = { process }

    if (
      selectedPlatform.value === 'youtube' ||
      selectedPlatform.value === 'tiktok' ||
      selectedPlatform.value === 'instagram'||
      selectedPlatform.value === 'threads'
    ) {
      details.type = selectedPlatform.value
    }

    if (selectedPlatform.value === 'instagram' && subType === 'instagram_login') {
      selectedPlatform.value = 'instagram_login'
    }

    if (selectedPlatform.value === 'tumblr') {
      details.type = 'tumblr_social'
    }

    if (subType === 'facebook-profile') {
      details.type = 'facebook-profile'
    }

    details.dashboard_modal = true

    const baseUrl = window.location.href.split('#')[0];
    details.callback_url = `${baseUrl}#${selectedPlatform.value}`;

    await getAuthorizationUrl({
      platform: details.type === 'facebook-profile' ? 'facebook-profile' : selectedPlatform.value,
      connectionDetails: details,
    })
  }

  const connectTwitterApp = async (
    twitterAppData = {},
    process = 'connect',
    connectorID = null,
    redirectToDashboard = true
  ) => {
    // Define an object containing details of the connection process
    const details = {
      process,
      app_id: twitterAppData._id,
      follow: false,
      dashboard_modal: redirectToDashboard,
    }

    // The ID of the connector being used for the connection process, defaults to null
    if (connectorID) details.connector_id = connectorID

    const baseUrl = window.location.href.split('#')[0];
    details.callback_url = `${baseUrl}#twitter`;

    await getAuthorizationUrl({
      platform: 'twitter',
      connectionDetails: details,
    })
  }

  const connectTwitterCustomApp = async (
    appID = '',
    process = 'connect',
    connectorID = null
  ) => {
    if (!appID) return

    try {
      // Make a GET API call to get the authorization URL for the Twitter app
      const { data } = await proxy.post(
          `${integrationsBaseURL}developerApp/twitterAuthUrl`, {
            workspace_id: this.getActiveWorkspace._id,
            id: appID,
            connection_details: {
              process,
              connector_id: connectorID,
              callback_url: `${window.location.href.split('#')[0]}#twitter`
            }
          }
      )

      // If the API call is successful, connect the Twitter app by calling connectTwitterApp method
      if (data.status) {
        const appDetails = {
          _id: appID,
          url: data.url,
        }
        connectTwitterApp(appDetails, process, connectorID)
      }
    } catch (error) {
      // If the API call fails, display an error message using the toastNotification method
      await store.dispatch('toastNotification', {
        message:
          error.response?.data?.message || error.message || UNKNOWN_ERROR,
        type: 'error',
      })
    }
  }

  return {
    // declarations
    fetching,
    buttonText,
    OPTIONS,
    selectedOption,
    selectedAccount,
    selectedPlatform,
    isDirty,
    hasConnectedAccounts,

    // methods
    processReconnectResponse,
    connectTwitterCustomApp,
    connectTwitterApp,
    connectAccount,
  }
}
