<script setup>
import { computed, watch, defineProps, ref } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/tiktok/components/graphs/MainGraph.vue'
import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import MiniStatsCard from '@/src/modules/analytics/views/common/MiniStatsCard.vue'
import usePinterestAnalytics from '@/src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'

// state
const {
  pinsPerDay,
  miniStatsCardKeys,
  dataZoomOptions,
  barChartOptions: chartOptions,
  isReportView,
  PostingByDayDropdown: optionsDropdown,
  selectedPostingByDayType: selectedOptionType,
  routes,
  isNoAnalyticsData,
  getMiniCardData,
  getPostingByDayChartPayload,
  fetchMedia,
} = usePinterestAnalytics('Pins')

const props = defineProps({
  type: {
    type: String,
    default: 'All',
  },
})

const getSelectedOptions = () => {
  switch (selectedType.value) {
    case 'All':
      return pinsPerDay.value?.all
    case 'Video':
      return pinsPerDay.value?.video
    case 'Image':
      return pinsPerDay.value?.image
    default:
      return pinsPerDay.value?.all
  }
}

const isNoData = computed(() => {
  return isNoAnalyticsData(getSelectedOptions(), false)
})

const selectedType = computed(() => {
  return !isReportView.value ? selectedOptionType.value[0] : props.type
})

const getCheckboxData = computed(() =>
  isReportView.value ? [props.type] : selectedOptionType.value
)

watch(
  () => pinsPerDay,
  () => {
    const options = getSelectedOptions() || {}
    chartOptions.value.series[0].itemStyle = {}
    chartOptions.value.series[0].data = options?.pins_count

    chartOptions.value.xAxis.data = options?.buckets
  },
  {
    deep: true,
    immediate: true,
  }
)
const isLoading = ref(false)

const updateFilterAndFetch = async () => {
  isLoading.value = true
  try {
    await fetchMedia(routes.PIN_POSTING_PER_DAY, getPostingByDayChartPayload())
    await fetchMedia(routes.PIN_POSTING_ROLLUP, getPostingByDayChartPayload())
  } finally {
    isLoading.value = false
  }
}

const updateSelectedCheckbox = (value) => {
  selectedOptionType.value = [value]
  updateFilterAndFetch()
}
</script>

<template>
  <AnalyticsCardWrapper type="specific-type-chart" :enable-modal="true">
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Daily Pin Posting Activity
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Assess the relationship between your daily pins frequency and
                  subsequent engagement metrics, over the selected time period.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
        <div class="flex items-center gap-4">
          <template v-for="(item, i) in optionsDropdown" :key="i">
            <CstSimpleCheckbox
              :id="item.label"
              v-model="getCheckboxData"
              :name="item.label"
              reverse
              @change="updateSelectedCheckbox(item.label)"
            >
              <template v-slot:label>{{ item.label }}</template>
            </CstSimpleCheckbox>
          </template>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? {
                  ...chartOptions,
                  ...dataZoomOptions,
                  grid: { ...chartOptions.grid, bottom: 70 },
                }
              : chartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No Pins data found.</p>
        </div>
      </div>
    </template>
    <template v-slot:card-sidebar>
      <div
        class="min-w-[20rem] p-3 flex flex-col gap-4"
      >
        <p class="font-weight-500 text-base">Pin Posting Rollup</p>
        <div
          class="flex flex-col gap-2 overflow-y-auto"
          :class="{ 'h-[390px]': !isReportView }"
        >
        <MiniStatsCard
          v-for="card in miniStatsCardKeys"
          :key="card"
          :data="getMiniCardData(card, selectedType)"
          :is-report-view="isReportView"
        />
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
