<template>
  <div class="py-4 px-[1.5rem] bg-[#F4F6FA] flex flex-col">
    <div v-if="msg.length < 100" class="text-[#202324] text-sm text-center">
      {{ msg }}
    </div>
    <div v-else-if="msg.length > 100" class="text-[#202324] text-sm text-left">
      {{ truncatedMsg }}
      <span
        class="text-[#2961D2] cursor-pointer"
        @click="isViewMore = !isViewMore"
        >{{ isViewMore ? 'View More' : 'View Less' }}</span
      >
    </div>
    <div
      v-if="!firstCommentStatus && canPerform"
      class="flex align-items-center justify-content-center"
    >
      <a
        v-if="learnMoreLink(`${type}_push_notification`) !== ''"
        :href="sanitizeUrl(learnMoreLink(`${type}_push_notification`))"
        target="_blank" rel="noopener"
        class="svg-hover px-1 py-[0.625rem] text-[#2961D2] hover:text-[#214EA8] align-text-center"
      >
        <span class="mr-2 failed-options remove-margin">
          <img
            src="@/src/assets/img/common/learn-more.svg"
            alt="learn-more"
            class="w-[1.2rem] h-[1.2rem]"
          />
        </span>
        <span class="text-xs">Learn More</span>
      </a>
      <div
        v-if="resendNotificationAvailability && type === 'instagram'"
        data-v-2ac61aa1=""
        class="mx-2 h-[1.2rem] w-px bg-[#D2D5DF]"
      ></div>
      <div
        v-if="shouldDisplayResendButton"
        class="svg-hover  px-1 py-[0.625rem] text-[#2961D2] cursor-pointer hover:text-[#214EA8] align-text-center"
        @click="emit('resend-mobile-notification')"
      >
        <span class="mr-2 failed-options remove-margin">
          <img
            src="@/src/assets/img/common/bell-icon.svg"
            alt="resend"
            class="w-[1.2rem] h-[1.2rem]"
          />
        </span>
        <span class="text-xs">Resend Mobile Notification</span>
      </div>
      <!-- status section -->
      <clip-loader
          v-else-if="type !== 'facebook'"
          class="ml-2"
          :color="'#5c77f8'"
          :size="'18px'"
          aria-label="Loading"
      ></clip-loader>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue'
import { learnMoreLink } from '@src/modules/planner_v2/composables/postStatus'
import {sanitizeUrl} from "@braintree/sanitize-url";

const props = defineProps({
  msg: {
    type: String,
    required: true,
  },
  canPerform: {
    type: Boolean,
  },
  firstCommentStatus: {
    type: Boolean,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  resendNotificationAvailability: {
    type: Boolean,
    required: true,
  },
  pushNotificationLoader: {
    type: Boolean,
    required: true,
  },
})

const isViewMore = ref(true)

const emit = defineEmits(['resend-mobile-notification'])
const truncatedMsg = computed(() => {
  return props.msg.length > 100 && !isViewMore.value
    ? props.msg.slice(0, 100) + '...'
    : props.msg
})

const shouldDisplayResendButton = computed(() => {
  return (
      props.resendNotificationAvailability &&
      props.type === 'instagram' &&
      !props.pushNotificationLoader
  );
});

</script>

<style scoped>
.svg-hover:hover svg path {
  fill: #214ea8;
}
@media (width <= 1366px) {
  .failed-options {
    display: block;
  }
  .align-text-center {
    text-align: center;
  }
  .remove-margin {
    margin: 0;
  }
}
@media only screen and (min-width: 1366px) {
  @media {
    .failed-options {
      display: block;
    }
    .align-text-center {
      text-align: center;
    }
    .remove-margin {
      margin: 0;
    }
  }
}
</style>
