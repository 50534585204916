<template>
  <div
      class="grid grid-cols-12 px-2.5 w-full"
      @click.prevent="
      EventBus.$emit(getPreview(isOverview() ? posts.network : index), posts)
    "
  >
    <div class="col-span-4 xl:col-span-6 2xl:col-span-8 analytics-tab-body__item">
      <div class="analytics-tab-body__item-image">
        <template
            v-if="
            isPostImageAvailable(isOverview() ? posts.network : index, posts)
          "
        >
          <video
              v-if="isVideo(isOverview() ? posts.network : index, posts)"
              class="video_preview_image"
              controls
          >
            <source
                :src="getPostImage(isOverview() ? posts.network : index, posts)"
            />
            <source
                :src="getPostImage(isOverview() ? posts.network : index, posts)"
                type="video/ogg"
            />
            Your browser does not support the video tag.
          </video>
          <img
              v-else
              :src="
              getResizedImageURL(
                getPostImage(isOverview() ? posts.network : index, posts),
                200,
                0,
              )
            "
              alt=""
              @error="setPostFallback($event)"
          />
        </template>
        <template v-else>
          <img src="../../assets/imgs/no_data_images/not-found.png" alt=""/>
        </template>
      </div>
      <div class="analytics-tab-body__item-profile">
        <div class="analytics-tab-body__item-profile-header">
          <div>
            <img
                v-if="getImage(isOverview() ? posts.network : index, posts)"
                :src="getImage(isOverview() ? posts.network : index, posts)"
                alt=""
                @error="
                $event.target.src = fallbackImagePreview(getPostName(isOverview() ? posts.network : index, posts)[0])
              "
            />
            <img
                v-else
                src="https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg"
                alt=""
            />
            <img
              :src="
                require('@assets/img/integration/' +
                  getSocialImage(isOverview() ? posts.network : index))
              "
              alt=""
              class="absolute -right-2 bottom-[-0.35rem] !h-[20px] !w-auto"
            />
          </div>
          <div>
            <h3>{{
                getPostName(isOverview() ? posts.network : index, posts)
              }}</h3>
            <p
            ><b
            >{{
                getPostUsername(isOverview() ? posts.network : index, posts)
              }}
              •</b
            >
              {{ getPostDate(isOverview() ? posts.network : index, posts) }}</p
            >
          </div>
        </div>
        <div class="analytics-tab-body__item-profile-body">
          <template
              v-if="
              isPostTextAvailable(isOverview() ? posts.network : index, posts)
            "
          >
            <HtmlRenderer
              tag="p"
              :html-content="limitTextLength(
                isHashtag(
                  isLink(
                    nextLineReplacement(
                      getPostText(isOverview() ? posts.network : index, posts),
                    ),
                  ),
                ),
                300,
              )"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="col-span-2 2xl:col-span-1 analytics-tab-body__item">
      <b>{{
          getPostEngagement(isOverview() ? posts.network : index, posts)
        }}</b>
    </div>
    <div class="col-span-2 lg:col-span-1 analytics-tab-body__item">
      <b>{{ getPostLikes(isOverview() ? posts.network : index, posts) }}</b>
    </div>
    <div class="col-span-2 2xl:col-span-1 analytics-tab-body__item">
      <b>{{ getPostComments(isOverview() ? posts.network : index, posts) }}</b>
    </div>
    <div class="col-span-2 lg:col-span-1 analytics-tab-body__item">
      <b>{{ getPostShares(isOverview() ? posts.network : index, posts) }}</b>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@common/lib/event-bus";
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import ComposerHelperMixin from '@src/modules/composer_v2/mixins/ComposerHelper.js'
import HtmlRenderer from "@common/components/htmlRenderer.vue";

export default {
  name: 'PostRow',
  components: {HtmlRenderer},
  mixins: [analyticsUtilsMixin, ComposerHelperMixin],
  props: ['posts', 'index'],
  data() {
    return {}
  },
  computed: {
    EventBus() {
      return EventBus
    }
  },
  methods: {
    isOverview() {
      return this.index === 'overall'
    },
    setPostFallback(e) {
      e.target.src = 'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
    }
  }
}
</script>
