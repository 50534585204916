<template>
  <b-dropdown
    ref="dropdown"
    variant="studio-theme"
    toggle-class="analytic-accounts-dropdown__btn"
    :size="isPinterest ? 'md' : 'lg'"
    :disabled="updating || getAccountsList.length === 0"
    class="studio-theme-dropdown studio-theme-dropdown--checkbox studio-theme-dropdown--contain-image analytic-accounts-dropdown"
    :class="{
      '!min-w-[17rem]': isPinterest,
    }"
    no-caret
  >
    <template v-slot:button-content>
      <template v-if="getAccountsList.length === 0">
        <i class="fad fa-user-circle !text-2xl !leading-9 mr-2"></i>
        <span
          class="capitalize_text text-left inline-flex flex-col mx-0.5 font-bold justify-start"
        >
          <span class="font-normal"
            >No {{ isPinterest ? 'Board' : 'Account' }} Connected</span
          >
        </span>
      </template>
      <template v-else>
        <span class="flex flex-row w-full justify-center items-center">
          <span class="connected-accounts-images">
            <div v-if="selectedAccount">
              <img
                class="selected"
                :src="channelImage(selectedAccount, type)"
                alt=""
                @error="
                  $event.target.src = selectedAccount?.name
                    ? fallbackImagePreview(selectedAccount?.name[0])
                    : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                "
              />
            </div>
          </span>
          <span
            v-if="
              isPinterest &&
              selectedAccount &&
              selectedAccount.type === 'Profile'
            "
            class="capitalize_text text-left inline-flex flex-col mx-0.5 font-bold justify-start"
          >
            All Boards
            <small>Board</small>
          </span>
          <span
            v-else
            class="capitalize_text text-left inline-flex flex-col mx-0.5 font-bold justify-start"
          >
            {{ selectedAccount?.name ?? selectedAccount?.platform_name }}
            <small> {{ isPinterest ? 'Board' : 'Connected' }}</small>
          </span>
          <i class="icon-dropdown-cs ml-auto"></i>
        </span>
      </template>
    </template>
    <div v-if="isPinterest">
      <b-dropdown-item @click="selectAllBoards">
        <div class="field_group">
          <div class="checkbox_container">
            <label for="">
              <span class="dropdown-img-wrapper">
                <img
                  class="selected"
                  :src="channelImage(pinterestSelectedProfile, type)"
                  alt=""
                  @error="
                    $event.target.src = pinterestSelectedProfile?.username
                      ? fallbackImagePreview(
                          pinterestSelectedProfile?.username[0],
                        )
                      : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                  "
                />
                <img
                  :src="getSocialImageRounded(type)"
                  alt=""
                  class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                />
              </span>
              <span class="dropdown-item-label">
                <span v-b-tooltip.top="'All Boards'">All Boards</span>
              </span>
            </label>
          </div>
        </div>
      </b-dropdown-item>
    </div>
    <div v-for="(account, i) in getAccountsList" :key="i">
      <b-dropdown-item
        @click="changeAccount(account)"
      >
        <div class="field_group flex items-center">
          <div class="checkbox_container">
            <label for="">
              <span class="dropdown-img-wrapper">
                <img
                  class="selected"
                  :src="channelImage(account, type)"
                  alt=""
                  @error="
                    $event.target.src = account?.name
                      ? fallbackImagePreview(account.name?.[0])
                      : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                  "
                />
                <img
                  :src="getSocialImageRounded(account.account_type)"
                  alt=""
                  class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                />
              </span>
              <span class="dropdown-item-label">
                <span v-b-tooltip.top="getAccountName(account)">{{
                  getAccountName(account)
                }}</span>
                <small>{{ getProfileType(type, account) }}</small>
              </span>
            </label>
          </div>
          <img
            v-if="isReconnectRequired(account)"
            v-tooltip="{
              content: tooltipHtml(
                'To view your updated data, you must reconnect your account.',
              ),
              allowHTML: true,
              theme: 'light',
            }"
            src="@assets/img/composer/warning-icon.svg"
            alt=" "
            class="w-5 h-5 mx-2 ml-auto"
          />
        </div>
      </b-dropdown-item>
    </div>
  </b-dropdown>
</template>

<script setup>
import {
  ref,
  computed,
  defineProps,
  defineEmits,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
} from 'vue'
import useAnalyticsUtils from '@src/modules/analytics/components/common/composables/useAnalyticsUtils.js'
import { EventBus } from '@common/lib/event-bus'
import { useRoute, useRouter } from 'vue-router'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

const route = useRoute()
const router = useRouter()

const { channelImage, getSocialImageRounded, tooltipHtml } = useComposerHelper()
const { getPlatformAccounts, getProfileType, getAccountId } =
  useAnalyticsUtils()

const emits = defineEmits(['onAccountChange'])

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
})

const updating = ref(false)
const isFirstLoad = ref(true)
const selectedAccount = ref(null)
const isPinterest = computed(() => props.type === 'pinterest')

onBeforeMount(() => {
  EventBus.$on('workspace-changed', () => {
    console.log('workspace-changed')
    changeAccount(getPlatformAccounts(props.type)?.[0])
  })
  EventBus.$on('fetched-social-accounts', () => {
    if (route?.params?.accountId && isFirstLoad.value) {
      const account = getPlatformAccounts(props.type)?.find(
        (account) => getAccountId(account) === route?.params?.accountId
      )
      console.log(getPlatformAccounts(props.type))
      console.log(account)
      changeAccount(account)
    } else {
      console.log(getPlatformAccounts(props.type)?.[0])
      changeAccount(getPlatformAccounts(props.type)?.[0])
    }
    isFirstLoad.value = false
  })
})

onMounted(() => {
  console.log(route?.params?.accountId)
  if (route?.params?.accountId && isFirstLoad.value) {
    const account = getPlatformAccounts(props.type)?.find(
      (account) => getAccountId(account) === route?.params?.accountId
    )
    console.log(getPlatformAccounts(props.type))
    console.log(account)
    changeAccount(account)
  } else {
    console.log(getPlatformAccounts(props.type)?.[0])
    changeAccount(getPlatformAccounts(props.type)?.[0])
  }
  isFirstLoad.value = false
})

onBeforeUnmount(() => {
  EventBus.$off('workspace-changed')
  EventBus.$off('fetched-social-accounts')
})

const getAccountsList = computed(() => {
  return getPlatformAccounts(props.type)
})

const getAccountName = (account) => {
  return account?.name ?? account?.platform_name ?? account?.username ?? '-'
}

const changeAccount = (account) => {
  selectedAccount.value = account
  emits('onAccountChange', account)
  if (account) {
    router.push({
      name: props.type + '_analytics_v3',
      params: {
        accountId: getAccountId(account),
      },
    })
  }
}

const isReconnectRequired = (account) => {
  switch (props.type) {
    case 'youtube':
      return account?.access_token?.scope?.split(' ').length < 7
    case 'instagram':
      return (
        account && ['invalid', 'expired'].includes(account?.validity)
      )
    default:
      return ['invalid', 'expired'].includes(account?.validity)
  }
}
</script>
