<script setup>
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import AnalyticsPostsTable from '@src/modules/analytics/views/common/AnalyticsPostsTable.vue'
import { watch, computed, defineProps, ref } from 'vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import { EventBus } from '@common/lib/event-bus'
import useLinkedinAnalytics from '@src/modules/analytics/views/linkedin_v2/composables/useLinkedinAnalytics.js'
import LinkedinPostModal from '@/src/modules/analytics/views/linkedin_v2/components/LinkedinPostModal.vue'

const {
  routes,
  isReportView,
  postsLimit,
  isLoadingStates,
  selectedAccount,
  dateRange,
  topPostsData,
  validPostsTableHeaders,
  validPostsTableHeadersReport,
  nonSortableItems,
  tableSortableItems,

  fetchMedia,
  getHeaderTitles,
  getBodyValues,
  getHeaderTooltips,
  getHeaderApiKey,
} = useLinkedinAnalytics()

const props = defineProps({
  rangeStartValue: {
    type: Number,
    default: 0,
  },
  rangeEndValue: {
    type: Number,
    default: 10,
  },
})

const dataKey = ref(getHeaderApiKey(tableSortableItems.value[0]))

const dropDownValues = computed(() => {
  if (!topPostsData.value?.length) return []
  const range = []
  for (let i = 10; i <= 100; i += 10) {
    range.push(i)
  }
  return range
})

const getPosts = computed(() => {
  return topPostsData.value || []
})

const getValidHeaders = computed(() => {
  return isReportView ? validPostsTableHeadersReport : validPostsTableHeaders
})

const isPostDataLoading = computed(() => {
  return isLoadingStates.value[routes.TOP_POSTS]
})

const handleDropDownClick = async (value) => {
  EventBus.$emit('set-top-posts-limit', value)
  postsLimit.value = value
  fetchTopPerformingPosts()
}

const handleSortClick = async (header) => {
  const apiKey = getHeaderApiKey(header)
  if (apiKey) {
    dataKey.value = apiKey
    fetchTopPerformingPosts()
  }
}

const fetchTopPerformingPosts = async () => {
  fetchMedia(routes.TOP_POSTS, {
    limit: postsLimit.value,
    order_by: dataKey.value,
  })
}

watch(
  () => [dateRange, selectedAccount],
  async () => {
    fetchTopPerformingPosts()
  },
  { deep: true, immediate: true }
)
</script>

<template>
  <AnalyticsCardWrapper custom-class="overflow-x-auto">
    <template v-slot:card-header>
      <div v-if="!isReportView" class="flex justify-between items-center w-full">
        <div class="mx-1">
          <h2 class="text-secondary-cs text-base font-medium">
            LinkedIn Top Posts {{ isReportView ? `(Top ${postsLimit})` : '' }}
          </h2>
          <p class="text-sm text-secondary-cs-700">
            Review your top posts published during the selected time period,
            based on the post's lifetime performance.</p
          >
        </div>
        <CstDropdown
          v-if="!isReportView && !isPostDataLoading && getPosts.length > 0"
          dropdown-placement="bottom"
          container-classes="!max-h-96"
          dropdown-classes="!rounded-md !border-[#70707029]"
          button-classes="flex !px-5 !w-28 !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
        >
          <template v-slot:arrow>
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </template>
          <template v-slot:selected>
            <p class="text-base font-weight-500 capitalize">
              Top {{ !isReportView && postsLimit }}
            </p>
          </template>
          <template v-slot>
            <CstDropdownItem
              v-for="(value, index) in dropDownValues"
              :key="`activity_type_${index}`"
              @click="handleDropDownClick(value)"
            >
              <p class="text-gray-900 text-sm">{{ value }}</p>
            </CstDropdownItem>
          </template>
        </CstDropdown>
      </div>
    </template>
    <template v-slot:card-body>
      <AnalyticsPostsTable
        :selected-account="selectedAccount"
        :data-list="getPosts"
        :valid-headers-list="getValidHeaders"
        :non-sortable-items="nonSortableItems"
        :mutate-header-titles="getHeaderTitles"
        :mutate-body-values="getBodyValues"
        :mutate-header-tooltips="getHeaderTooltips"
        :is-report-view="isReportView"
        :range-start-value="props.rangeStartValue"
        :range-end-value="props.rangeEndValue"
        :index-to-sort-by="2"
        custom-no-data-message="No Data Found."
        :is-loading="isPostDataLoading"
        @update-sort="handleSortClick"
      >
        <template
          v-slot:post-modal="{
            selectedAccount: selectedUserAccount,
            selectedPost,
          }"
        >
          <LinkedinPostModal
            v-if="selectedPost"
            :selected-account="selectedUserAccount"
            :selected-post="selectedPost"
          />
        </template>
      </AnalyticsPostsTable>
    </template>
  </AnalyticsCardWrapper>
</template>
