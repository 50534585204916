<template>
  <div class="analytics-summary-block" data-cy="performance-summary">
    <h2
      :class="{
        'bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white': isReportView,
      }"
    >
      {{ heading }}
    </h2>
    <p>{{ subtitle }}</p>

    <!-- Children -->
    <slot></slot>
  </div>
</template>

<script setup>
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'

defineProps({
  heading: {
    type: String,
    required: true,
    default: 'All Profile Performance Summary'
  },
  subtitle: {
    type: String,
    required: true,
    default: 'View your key profile performance metrics from the reporting period.'
  }
})

const { isReportView } = useAnalytics()
</script>
