<template>
  <div class="media-library-filterbar m-0">
    <div class="media-library-filterbar__left">
      <div v-if="treeFolders" class="media-library-filterbar__folders mr-1">
        <i class="far fa-folder mr-3"></i>

        <TreeSelect
          v-model="selectedFolderValue"
          placeholder="Select Folder"
          :clearable="false"
          :flatten-search-results="true"
          :options="treeFolders"
          :normalizer="normalizer"
        >
        </TreeSelect>
      </div>

      <b-dropdown
        variant="studio-theme"
        class="studio-theme-dropdown dropdown-size-small mr-1 pr-0"
        no-caret
        :disabled="disabled"
      >
        <template v-slot:button-content>
          <i class="far fa-folder-open"></i>
          <span class="capitalize_text">{{ usedOrUnused?.label }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 292.362 292.362"
            fill="#ccc"
            class="vue-treeselect__control-arrow ml-3"
          >
            <path
              d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"
            ></path>
          </svg>
        </template>
        <b-dropdown-item v-for="item in usedOrUnusedOptions" :key="item.value" @click="changeUsedOrUnused(item)">
          {{ item.label }}</b-dropdown-item
        >
      </b-dropdown>


      <b-dropdown
        variant="studio-theme"
        class="studio-theme-dropdown dropdown-size-small mr-1 pr-0"
        no-caret
        :disabled="disabled"
      >
        <template v-slot:button-content>
          <i class="far fa-layer-group"></i>
          <span class="capitalize_text">{{ selectedType }}</span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 292.362 292.362"
            fill="#ccc"
            class="vue-treeselect__control-arrow ml-3"
          >
            <path
              d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"
            ></path>
          </svg>
        </template>
        <b-dropdown-item @click="changeType('all')">All Types</b-dropdown-item>
        <b-dropdown-item @click="changeType('image')">Images</b-dropdown-item>
        <b-dropdown-item @click="changeType('video')">Videos</b-dropdown-item>
        <b-dropdown-item @click="changeType('pdf')">PDF</b-dropdown-item>
      </b-dropdown>

      <b-dropdown
        variant="studio-theme"
        class="studio-theme-dropdown dropdown-size-small pr-0"
        right
        no-caret
        :disabled="disabled"
      >
        <template v-slot:button-content>
          <i class="far fa-clock-o"></i>
          <span class="capitalize_text">{{ selectedSort }}</span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 292.362 292.362"
            fill="#ccc"
            class="vue-treeselect__control-arrow ml-3"
          >
            <path
              d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"
            ></path>
          </svg>
        </template>
        <b-dropdown-item @click="changeSort('recent')"
          >Most recent</b-dropdown-item
        >
        <b-dropdown-item @click="changeSort('oldest')"
          >Oldest uploads</b-dropdown-item
        >
        <b-dropdown-item @click="changeSort('a2z')">A to Z</b-dropdown-item>
        <b-dropdown-item @click="changeSort('z2a')">Z to A</b-dropdown-item>
        <b-dropdown-item @click="changeSort('size')"
          >Largest to Smallest</b-dropdown-item
        >
      </b-dropdown>

      <b-dropdown
        v-if="showBulk"
        variant="studio-theme"
        class="studio-theme-dropdown dropdown-size-small mr-1"
        no-caret
        :disabled="disabled"
      >
        <template v-slot:button-content>
          <i class="far fa-layer-group"></i>
          <span class="capitalize_text">Bulk Actions</span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 292.362 292.362"
            fill="#ccc"
            class="vue-treeselect__control-arrow ml-3"
          >
            <path
              d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"
            ></path>
          </svg>
        </template>
        <b-dropdown-item @click="$emit('unselect')"
          >Unselect All</b-dropdown-item
        >
        <b-dropdown-item @click="$emit('remove')"
          >Remove Selected</b-dropdown-item
        >
      </b-dropdown>
      <div v-if="selected.length" class="media-tab__count ml-2">
        {{ selectedItemsCount }} media asset{{
          selectedItemsCount > 1 ? 's are' : ' is'
        }}
        selected
      </div>
    </div>

    <div class="media-library-filterbar__right d-flex align_center">
      <SearchField
        v-if="showSearch !== undefined && showSearch"
        v-model="searchText"
        :disabled="disabled"
      />
      <clip-loader
        v-if="isOperating"
        class="spinner ml-3"
        :color="'#9da6ac'"
        :size="'16px'"
      ></clip-loader>
    </div>
  </div>
</template>

<script>
import SearchField from '../../../../../components/common/SearchField'
export default {
  name: 'FiltersBar',
  components: {
    SearchField,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    showBulk: {
      type: Boolean,
      default: false
    },
    isOperating: {
      type: Boolean,
      default: false
    },
    folders: {
      type: Array,
      default: () => [],
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Array,
      default: () => []
    },
    isAllSelected: {
      type: Boolean,
      default: false
    },
    mediaType: {
      type: String,
      default: 'All Types'
    },
  },
  emits: ['usedOrUnused', 'type', 'sort', 'folder', 'search'],
  data() {
    return {
      searchText: '',
      type: this.mediaType,
      sort: 'Most recent',
      selectedFolderValue: 'all',
      usedOrUnused: {
        value: 'all',
        label: 'All Media'
      },
      usedOrUnusedOptions: [
        { value: 'all', label: 'All Media' },
        { value: 'used', label: 'Used' },
        { value: 'notused', label: 'Unused' }
      ]
    }
  },
  computed: {
    selectedType() {
      if (this.type) {
        switch (this.type) {
          case 'image':
            return 'Images'
          case 'video':
            return 'Videos'
          default:
            return 'All Types'
        }
      } else {
        return 'All Types'
      }
    },
    selectedItemsCount () {
      if (this.isAllSelected.total) {
        return this.total
      } else {
        return this.selected.length
      }
    },
    selectedSort() {
      console.debug('selectedSort', this.sort)
      if (this.sort) {
        switch (this.sort) {
          case 'recent':
            return 'Most recent'
          case 'oldest':
            return 'Oldest uploads'
          case 'a2z':
            return 'A to Z'
          case 'z2a':
            return 'Z to A'
          case 'size':
            return 'Largest to Smallest'
          default:
            return 'Most recent'
        }
      } else {
        return 'Most recent'
      }
    },

    treeFolders() {
      if (!this.folders) {
        return null
      }

      let list = [
        { id: 'all', label: 'All Upload' },
        { id: 'uncategorized', label: 'Uncategorized' },
      ]

      list = [
        ...list,
        ...this.folders.map((folder) => {
          const temp = folder

          temp.children = folder.sub_folders.map((subfolder) => {
            return {
              id: subfolder._id,
              label: subfolder.folder_name,
            }
          })

          if (temp.children.length === 0) {
            return {
              id: temp._id,
              label: temp.folder_name,
            }
          }

          return {
            id: temp._id,
            label: temp.folder_name,
            children: temp.children,
          }
        }),
      ]

      return list
    },
  },
  watch: {
    searchText(newVal) {
      if (newVal !== undefined) {
        this.$emit('search', newVal.trim())
      }
    },
    selectedFolderValue(newVal) {
      if (newVal !== undefined) {
        this.$emit('folder', newVal.trim())
      }
    },
  },
  methods: {
    normalizer(node) {
      let name = node.label

      if (node.id === 'all' || node.id === 'uncategorized') {
        name = name + ' (Main)'
      }

      return {
        id: node.id,
        label: name,
        children: node.children,
      }
    },

    changeUsedOrUnused(type) {
      this.usedOrUnused = type
      this.$emit('usedOrUnused', type.value)
    },

    changeType(type) {
      this.type = type
      this.$emit('type', type)
    },

    changeSort(sort) {
      this.sort = sort
      this.$emit('sort', sort)
    },
  },
}
</script>
