<template>
  <div class="page_view">
    <div class="cover_page">
      <div class="subpage w-100 h-100">
        <div class="content">
          <!-- Company Logo Section -->
          <div v-if="showCompanyInfo && companyLogo" class="flex items-center justify-center text-center my-8">
            <img :src="companyLogo" alt="" class="company-logo" />
            <h3 class="company-name">{{ companyName }}</h3>
          </div>

          <slot></slot>
        </div>

        <!-- Generated Date -->
        <div class="generated-date">
          <p>Created at : {{ getGeneratedDate() }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import useWhiteLabelApplication from '@src/modules/setting/composables/whitelabel/useWhiteLabelApplication.js'
import useDateFormat from "@common/composables/useDateFormat"
import {useStore} from "@state/base";
import ContentStudioIcon from '@/public/favicons/apple-touch-icon-114x114.png'

defineProps({
  showCompanyInfo: {
    type: Boolean,
    default: false
  }
})

const {getters} = useStore()
const { momentWrapper } = useDateFormat()
const { appData } = useWhiteLabelApplication()

const companyName = ref('ContentStudio')
const companyLogo = ref(ContentStudioIcon)

const getCompanyDetails = () => {
  const { company_name, company_logo } = getters.getActiveWorkspace
  const { businessName, brandLogo } = appData.value || {}

  // Only set from appData if workspace properties aren’t present
  companyName.value = company_name || businessName
  companyLogo.value = company_logo || brandLogo
}

const getGeneratedDate = () => {
  return momentWrapper()?.format('DD MMMM, YYYY hh:mm A')
}

onMounted(() => {
  getCompanyDetails()
})
</script>

<style lang="less">
.page_view {
  min-height: 100vh;
  position: relative;
  background: white;
}

.cover_page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('~@assets/img/analytics/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  page-break-after: always;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-filter: none;
  filter: none;
}

.subpage {
  padding: 2rem;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.company-logo {
  height: 60px;
  width: auto;
  margin-right: 1rem;
}

.company-name {
  font-size: 24px;
  font-weight: 500;
  color: #333;
}

.generated-date {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  p {
    font-size: 14px;
    color: #666;
    margin: 0;
    padding: 0;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
</style>
