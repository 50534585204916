<script setup>
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import AnalyticsPostsTable from '@src/modules/analytics/views/common/AnalyticsPostsTable.vue'
import YoutubePostModal from '@src/modules/analytics/views/youtube/components/YoutubePostModal.vue'
import { watch, computed, defineProps, ref } from 'vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import { EventBus } from '@common/lib/event-bus'
import useYoutubeAnalytics from '@/src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'

defineProps({
  rangeStartValue: {
    type: Number,
    default: 0,
  },
  rangeEndValue: {
    type: Number,
    default: 10,
  },
})

const {
  routes,
  selectedAccount,
  dateRange,
  postsLimit,
  isPostDataLoading,
  isReportView,
  validPostsTableHeaders,
  nonSortableItems,
  validPostsTableApiKeys,
  allTopPosts,

  getHeaderTitles,
  getBodyValues,
  getHeaderTooltips,
  fetchMedia,
  getHeaderApiKey,
} = useYoutubeAnalytics()

const selectedSortKey = ref(validPostsTableApiKeys.engagement)

const dropDownValues = computed(() => {
  if (!getPosts.value?.length) return []
  const range = []
  for (let i = 10; i <= 100; i += 10) {
    range.push(i)
  }
  return range
})

const getPosts = computed(() => {
  return allTopPosts.value[selectedSortKey.value] || []
})

const handleDropDownClick = async (value) => {
  EventBus.$emit('set-top-posts-limit', value)
  postsLimit.value = value
  await fetchTopPerformingPosts()
}

const handleSortClick = async (header) => {
  const apiKey = getHeaderApiKey(header)
  if (apiKey) {
    selectedSortKey.value = apiKey
    await fetchTopPerformingPosts()
  }
}

const fetchTopPerformingPosts = async () => {
  isPostDataLoading.value = true
  await fetchMedia(routes.SORTED_TOP_POSTS, {
    limit: postsLimit.value,
    order_by: selectedSortKey.value,
  })
  isPostDataLoading.value = false
}
watch(
  () => [dateRange, selectedAccount.value],
  async () => {

    isPostDataLoading.value = true
    await fetchTopPerformingPosts()
    isPostDataLoading.value = false
  },
  { deep: true , immediate: true}
)
</script>

<template>
  <AnalyticsCardWrapper custom-class="overflow-x-auto">
    <template v-slot:card-header>
      <div v-if="!isReportView" class="flex justify-between items-center w-full">
        <div class="mx-1">
          <h2 class="text-secondary-cs text-base font-medium">
            Youtube Top Posts {{ isReportView ? `(Top ${postsLimit})` : '' }}
          </h2>
          <p class="text-sm text-secondary-cs-700">
            Review your top posts published during the selected time period,
            based on the post's lifetime performance.</p
          >
        </div>
        <CstDropdown
          v-if="!isReportView && !isPostDataLoading && getPosts?.length > 0"
          dropdown-placement="bottom"
          container-classes="!max-h-96"
          dropdown-classes= '!rounded-md !border-[#70707029]'
          button-classes="flex !px-5 !w-28 !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
        >
          <template v-slot:arrow>
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </template>
          <template v-slot:selected>
            <p class="text-base font-weight-500 capitalize">
              Top {{ !isReportView && postsLimit }}
            </p>
          </template>
          <template v-slot>
            <CstDropdownItem
              v-for="(value, index) in dropDownValues"
              :key="`activity_type_${index}`"
              @click="handleDropDownClick(value)"
            >
              <p class="text-gray-900 text-sm">{{ value }}</p>
            </CstDropdownItem>
          </template>
        </CstDropdown>
      </div>
    </template>
    <template v-slot:card-body>
      <AnalyticsPostsTable
        :selected-account="selectedAccount"
        :data-list="getPosts"
        :valid-headers-list="validPostsTableHeaders"
        :non-sortable-items="nonSortableItems"
        :mutate-header-titles="getHeaderTitles"
        :mutate-body-values="getBodyValues"
        :mutate-header-tooltips="getHeaderTooltips"
        :is-report-view="isReportView"
        :range-start-value="rangeStartValue"
        :range-end-value="rangeEndValue"
        :is-loading="isPostDataLoading"
        @update-sort="handleSortClick"
      >
        <template
          v-slot:post-modal="{
            selectedAccount: selectedUserAccount,
            selectedPost,
          }"
        >
          <YoutubePostModal
            v-if="selectedPost"
            :selected-account="selectedUserAccount"
            :selected-post="selectedPost"
          />
        </template>
      </AnalyticsPostsTable>
    </template>
  </AnalyticsCardWrapper>
</template>
