<script setup>
import { ref, inject, onMounted, onUnmounted, computed } from 'vue'
import { EventBus } from '@common/lib/event-bus'
import { debounce } from 'lodash'
import PerformingPostsCard from '@/src/modules/analytics_v3/components/PerformingPostsCard.vue'
import useTiktokAnalytics from '@/src/modules/analytics/views/tiktok/composables/useTiktokAnalytics'
import TiktokPostModal from '@/src/modules/analytics/views/tiktok/components/TiktokPostModal.vue'

const { isReportView, screenWidth } = useTiktokAnalytics()

const props = defineProps({
  selectedAccount: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  topPosts: {
    type: Array,
    required: true,
    default: () => [],
  },
  leastPosts: {
    type: Array,
    required: true,
    default: () => [],
  },
  threshold: {
    type: Number,
    default: 5,
  },
})

const root = inject('root')
const { $bvModal } = root
const selectedPost = ref({})

const handlePostSelect = (post) => {
  selectedPost.value = post

  $bvModal.show('post-details-modal')
}

const handleShowAll = () => EventBus.$emit('tab-change', '#posts')

const postsDisplayLimit = computed(() => (screenWidth.value >= 1510 ? 5 : 4))

const displayedTopPosts = computed(() =>
  props.topPosts?.slice(0, postsDisplayLimit.value)
)

const displayedLeastPosts = computed(() =>
  props.leastPosts?.slice(0, postsDisplayLimit.value)
)

const updateScreenWidth = debounce(() => {
  screenWidth.value = window.innerWidth
}, 200)

onMounted(() => {
  window.addEventListener('resize', updateScreenWidth)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateScreenWidth)
})
</script>

<template>
  <div
    class="color-border cst-editor relative w-full rounded-md bg-white p-5 h-fit"
  >
    <div v-if="!isReportView" class="grid grid-cols-2 gap-28 mb-6">
      <div class="flex gap-3 items-center justify-start">
        <p class="text-base font-weight-500 select-none capitalize"
          >Top Performing Posts</p
        >
        <v-menu
          v-if="!isReportView"
          :popper-triggers="['hover']"
          placement="top"
          popper-class="first-comment__info-popover"
          :delay="300"
        >
          <i class="far fa-question-circle p-0 cursor-pointer"></i>
          <template v-slot:popper>
            <p class="text-sm text-gray-900">
              The most engaging posts of the account, published during the
              selected time period.
            </p>
          </template>
        </v-menu>
      </div>

      <div
        class="flex items-center"
        :class="{ 'justify-between': topPosts?.length || leastPosts?.length }"
      >
        <div class="flex gap-3 items-center justify-center">
          <p class="text-base font-weight-500 select-none capitalize"
            >Least Performing Posts</p
          >
          <v-menu
            v-if="!isReportView"
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
          >
            <i class="far fa-question-circle p-0 cursor-pointer"></i>
            <template v-slot:popper>
              <p class="text-sm text-gray-900">
                The least engaging posts of the account, published during the
                selected time period.
              </p>
            </template>
          </v-menu>
        </div>

        <p
          v-if="(topPosts?.length || leastPosts?.length) && !isReportView"
          class="text-base font-medium text-[#2961D2] cursor-pointer"
          @click="handleShowAll"
        >
          Show All Posts
        </p>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-28 rounded !p-2 mx-1">
      <!-- top posts -->
      <div
        v-if="topPosts?.length"
        class="grid w-full mt-2 grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-2"
      >
        <!-- Item -->
        <PerformingPostsCard
          v-for="post in displayedTopPosts"
          :key="post"
          :data="post"
          class="max-w-[8rem]"
          footer-classes="p-2"
          @preview-performance-post="handlePostSelect(post)"
        >
          <template v-slot:media-content>
            <img
              :src="post.coverImage"
              loading="lazy"
              alt=""
              class="rounded-lg h-[7.25rem] w-full max-w-[8rem] object-cover"
              @error="
                $event.target.src =
                  'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
              "
            />
          </template>

          <template v-slot:tooltip-content>
            <p class="text-sm flex justify-between text-gray-900">
              Views
              <span class="ml-3 font-bold">
                {{ post.views }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Engagements
              <span class="ml-3 font-bold">
                {{ post.engagement }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Eng. Rate
              <span class="ml-3 font-bold"> {{ post.engagementRate }}% </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Likes
              <span class="ml-3 font-bold">
                {{ post.likes }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Comments
              <span class="ml-3 font-bold">
                {{ post.comments }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Post Type
              <span class="ml-3 font-bold">
                {{ post.mediaType }}
              </span>
            </p>
          </template>
        </PerformingPostsCard>
      </div>
      <div v-else class="flex justify-content-center w-full">
        <p class="font-medium text-sm">No posts found.</p>
      </div>

      <!--      least posts-->
      <div
        v-if="leastPosts?.length"
        class="grid w-full mt-2 grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-2"
      >
        <!-- Item -->
        <PerformingPostsCard
          v-for="post in displayedLeastPosts"
          :key="post"
          :data="post"
          class="max-w-[8rem]"
          footer-classes="p-2"
          @preview-performance-post="handlePostSelect(post)"
        >
          <template v-slot:media-content>
            <img
              :src="post.coverImage"
              loading="lazy"
              alt=""
              class="rounded-lg h-[7.25rem] w-full max-w-[8rem]"
              @error="
                $event.target.src =
                  'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
              "
            />
          </template>

          <template v-slot:tooltip-content>
            <p class="text-sm flex justify-between text-gray-900">
              Engagements
              <span class="ml-3 font-bold">
                {{ post.engagement }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Eng. Rate
              <span class="ml-3 font-bold"> {{ post.engagementRate }}% </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Likes
              <span class="ml-3 font-bold">
                {{ post.likes }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Comments
              <span class="ml-3 font-bold">
                {{ post.comments }}
              </span>
            </p>
            <p class="text-sm flex justify-between text-gray-900">
              Post Type
              <span class="ml-3 font-bold">
                {{ post.mediaType }}
              </span>
            </p>
          </template>
        </PerformingPostsCard>
      </div>
      <div v-else class="flex justify-content-center w-full">
        <p class="font-medium text-sm">No posts found.</p>
      </div>
    </div>
  </div>

  <TiktokPostModal
    v-if="selectedPost"
    :selected-account="selectedAccount"
    :selected-post="selectedPost"
  />
</template>
