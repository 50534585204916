<template>
  <div
    :class="{
      'h-[40vh]':
        isLoadingStates?.ai_insights ||
        !aiInsightsData ||
        !aiInsightsData.length,
      'h-auto':
        !isLoadingStates?.ai_insights && aiInsightsData && aiInsightsData.length,
    }"
    class="bg-gray-cs-50 rounded-lg border border-gray-200 relative"
  >
    <!-- Loading state -->
    <div
      v-if="isLoadingStates?.ai_insights"
      class="flex items-center justify-center h-full"
    >
      <ClipLoader size="32px" color="#1F2937" />
    </div>

    <!-- Empty state -->
    <div
      v-else-if="!aiInsightsData || !aiInsightsData.length"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-900 text-center w-full px-4"
    >
      Insufficient data to generate insights. At least one record is required during the selected time period to generate insights.
    </div>

    <!-- Content when loaded -->
    <div v-else class="space-y-4 pb-4">
      <div
        v-for="sectionData in aiInsightsData"
        :key="sectionData.category"
        class="mt-6 ml-6 mr-6 flex flex-col gap-2 color-border rounded-xl bg-white shadow-[0px_3px_10px_0px_rgba(211,217,236,0.30)]"
      >
        <CstCollapsible
          :id="sectionData.category"
          :open="openCollapsible(sectionData.category)"
          @header-click="toggleSection(sectionData.category)"
        >
          <template v-slot:header>
            <div class="flex items-center gap-3 ml-3">
              <img
                :src="getAIInsightsIcons(sectionData.category)"
                alt="Followers Growth"
                class="w-5 h-5"
              />
              <h3 class="font-medium" style="font-size: 16px">
                {{ sectionData.category }}
              </h3>
            </div>
          </template>
          <div
            v-for="insight in sectionData.insights"
            :key="insight.title"
            class="pl-8 pt-16 pb-16 ml-6"
          >
            <h5 class="font-medium" style="font-size: 14px">{{
              insight.title
            }}</h5>
            <p class="text-gray-900 pt-1" style="font-size: 13px">
              {{ insight.description }}
            </p>
            <ul v-if="insight.recommendations" class="list-disc pl-5 mt-2">
              <li
                v-for="(recommendation, index) in insight.recommendations"
                :key="index"
                class="text-gray-900"
              >
                {{ recommendation }}
              </li>
            </ul>
            <div v-if="insight?.data?.buckets">
              <div class="flex">
                <div class="mt-4 flex-1 chart-wrapper">
                  <MainGraph :chart-options="getChartOptions(insight.data)" :show-graph="openCollapsible(sectionData.category)" :custom-height="'300px'"/>
                </div>
              </div>
            </div>
          </div>
        </CstCollapsible>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, defineProps, watch } from 'vue'
import CstCollapsible from '@ui/Collapse/CstCollapsible'
import ClipLoader from '@src/modules/common/components/spinner/ClipLoader'
import MainGraph from '@src/modules/analytics/views/facebook_v2/components/graphs/MainGraph.vue'
import useAiInsights from '@src/modules/analytics_v3/composables/useAiInsights'

const props = defineProps({
  section: {
    type: String,
    required: true,
  },
  selectedDropdownOption: {
    type: String,
    required: false,
    default: '',
  },
  platform: {
    type: String,
    required: false,
    default: 'facebook',
  }
})

const {
  aiInsightsData,
  isLoadingStates,
  fetchInsights,
  getChartOptions,
  openCollapsible,
  toggleSection,
  getAIInsightsIcons
} = useAiInsights(props.platform)

onMounted(async () => {
  await fetchInsights(props.section, props.selectedDropdownOption);
});

// Watch for changes in selectedDropdownOption
watch(
  () => props.selectedDropdownOption,
  (newValue) => {
    if (newValue) {
      fetchInsights(props.section, newValue)
    }
  }
)
</script>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
}
.chart-wrapper {
  height: 300px;
}
.list-disc {
  list-style-type: none;
}
.list-disc li::before {
  content: '\2022';
  color: #2563eb; /* Custom bullet color */
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
h5 {
  padding-bottom: 2px;
  margin-bottom: 4px;
}
.pl-8 {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-right: 16px;
}
.pl-8:not(:last-child) {
  border-bottom: 1px solid #70707029;
  margin-bottom: 0;
}
</style>
