<template>
  <div class="media-library-filterbar">
    <div class="media-library-filterbar__left">
      <SearchField v-model="searchText" :disabled="disabled" />

      <b-dropdown
        v-if="selected.length > 1"
        variant="studio-theme"
        class="studio-theme-dropdown dropdown-size-small mr-1 pr-0"
        no-caret
        :disabled="disabled"
      >
        <template v-slot:button-content>
          <i class="far fa-layer-group"></i>
          <span class="capitalize_text">Bulk Actions</span>
          <i class="icon-dropdown-cs mr-0"></i>
        </template>
        <b-dropdown-item @click="$emit('unselect')"
          >Unselect All</b-dropdown-item
        >
        <b-dropdown-item @click="$emit('remove')"
          >Remove Selected</b-dropdown-item
        >
        <b-dropdown-item @click="$emit('share', true)"
          >Compose Post</b-dropdown-item
        >
        <b-dropdown-item @click="$emit('export')"
          >Export as CSV</b-dropdown-item
        >
        <b-dropdown-item
          v-if="showRestoreOption && showRestoreOption === true"
          @click="$emit('restore')"
          >Restore Selected</b-dropdown-item
        >
      </b-dropdown>

      <div v-if="isOperating" class="d-flex align-items-center">
        <clip-loader
          id="tooltip-button"
          variant="info"
          class="spinner ml-3 mr-1"
          :color="'#9da6ac'"
          :size="'16px'"
        ></clip-loader>

        <div
          v-if="loadingTitle"
          class="tooltip b-tooltip bs-tooltip-right bs-tooltip-right-docs position-relative zindex-1"
          role="tooltip"
        >
          <div class="arrow" style="top: 5px"></div>
          <div class="tooltip-inner">{{ loadingTitle }}</div>
        </div>
      </div>

      <div v-if="selected.length" class="media-tab__count ml-2">
        {{ selectedItemsCount }} media asset{{
          selectedItemsCount > 1 ? 's are' : ' is'
        }}
        selected
        <a
          v-if="isAllSelected.visible === false"
          href="javascript:;"
          @click="$emit('select-all-visible')"
          >Select All</a
        >
        <!-- <a @click="$emit('unselect')" href="javascript:;" v-else-if="isAllSelected.total === true || selectedItemsCount === total">Clear Selection</a> -->
        <a
          v-else-if="
            !(isAllSelected.total === true || selectedItemsCount === total)
          "
          href="javascript:;"
          @click="$emit('select-all')"
          >Select All {{ total ? total : '' }} media assets</a
        >
      </div>
    </div>
    <div class="media-library-filterbar__right">
      <!-- Used/Unused Dropdown -->
      <b-dropdown
      variant="studio-theme"
      class="studio-theme-dropdown dropdown-size-small mr-1 pr-0"
      no-caret
      :disabled="disabled"
    >
      <template v-slot:button-content>
        <i class="far fa-folder-open"></i>
        <span class="capitalize_text">{{ usedOrUnused?.label }}</span>
        <i class="icon-dropdown-cs mr-0"></i>
      </template>
      <b-dropdown-item v-for="item in usedOrUnusedOptions" :key="item.value" @click="changeUsedOrUnused(item)">
        {{ item.label }}</b-dropdown-item
      >
    </b-dropdown>


      <b-dropdown
        variant="studio-theme"
        class="studio-theme-dropdown dropdown-size-small mr-1 pr-0"
        no-caret
        :disabled="disabled"
      >
        <template v-slot:button-content>
          <i class="far fa-layer-group"></i>
          <span class="capitalize_text">{{ selectedType }}</span>
          <i class="icon-dropdown-cs mr-0"></i>
        </template>
        <b-dropdown-item @click="changeType('all')">All Types</b-dropdown-item>
        <b-dropdown-item @click="changeType('image')">Images</b-dropdown-item>
        <b-dropdown-item @click="changeType('video')">Videos</b-dropdown-item>
        <b-dropdown-item @click="changeType('pdf')">PDF</b-dropdown-item>
      </b-dropdown>

      <b-dropdown
        variant="studio-theme"
        class="studio-theme-dropdown dropdown-size-small pr-0"
        right
        no-caret
        :disabled="disabled"
      >
        <template v-slot:button-content>
          <i class="far fa-clock-o"></i>
          <span class="capitalize_text">{{ selectedSort }}</span>
          <i class="icon-dropdown-cs mr-0"></i>
        </template>
        <b-dropdown-item @click="changeSort('recent')"
          >Most recent</b-dropdown-item
        >
        <b-dropdown-item @click="changeSort('oldest')"
          >Oldest uploads</b-dropdown-item
        >
        <b-dropdown-item @click="changeSort('a2z')">A to Z</b-dropdown-item>
        <b-dropdown-item @click="changeSort('z2a')">Z to A</b-dropdown-item>
        <b-dropdown-item @click="changeSort('size')"
          >Largest to Smallest</b-dropdown-item
        >
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import SearchField from '../../../../../components/common/SearchField'
export default {
  name: 'FiltersBar',
  components: {
    SearchField
  },
  props: [
    'selected',
    'disabled',
    'isOperating',
    'loadingTitle',
    'showRestoreOption',
    'total',
    'isAllSelected'
  ],
  data () {
    return {
      searchText: '',
      usedOrUnused: {
        value: 'all',
        label: 'All Media'
      },
      usedOrUnusedOptions: [
        { value: 'all', label: 'All Media' },
        { value: 'used', label: 'Used' },
        { value: 'notused', label: 'Unused' }
      ]
    }
  },
  computed: {
    selectedItemsCount () {
      if (this.isAllSelected.total) {
        return this.total
      } else {
        return this.selected.length
      }
    },
    selectedType () {
      if (this.$route.query && this.$route.query.filetype) {
        switch (this.$route.query.filetype) {
          case 'image':
            return 'Images'
          case 'video':
            return 'Videos'
          case 'pdf':
            return 'PDF'
          default:
            return 'All Types'
        }
      } else {
        return 'All Types'
      }
    },

    selectedSort () {
      if (this.$route.query && this.$route.query.sort) {
        switch (this.$route.query.sort) {
          case 'recent':
            return 'Most recent'
          case 'oldest':
            return 'Oldest uploads'
          case 'a2z':
            return 'A to Z'
          case 'z2a':
            return 'Z to A'
          case 'size':
            return 'Largest to Smallest'
          default:
            return 'Most recent'
        }
      } else {
        return 'Most recent'
      }
    }
  },
  watch: {
    '$route.query.search' (newVal) {
      console.debug('route search', newVal)
      if (newVal === '' || newVal === undefined) {
        this.searchText = ''
      }
    },
    searchText (newVal) {
      if (newVal !== undefined) {
        const payload = {
          name: 'media-library',
          query: { ...this.$route.query, search: newVal.trim() }
        }
        this.$router.push(payload)
      }
    }
  },
  methods: {
    changeType (type) {
      const payload = {
        name: 'media-library',
        query: { ...this.$route.query, filetype: type }
      }
      this.$router.push(payload)
    },

    changeSort (type) {
      const payload = {
        name: 'media-library',
        query: { ...this.$route.query, sort: type }
      }
      this.$router.push(payload)
    },

    changeUsedOrUnused (type) {
      this.usedOrUnused = type
      const payload = {
        name: 'media-library',
        query: { ...this.$route.query, usedOrUnused: type.value }
      }
      this.$router.push(payload)
    }
  }
}
</script>
