<template>
  <div>
    <IndividualCoverPage
      v-if="type !== 'group' && type !== 'campaign-and-label'"
      :accounts="accounts"
      :report-date="reportDate"
      :type="type"
      :labels="labels"
      :campaigns="campaigns"
    />
    <BaseCoverPage v-for="(page, pageIndex) in accountPages" v-else :key="pageIndex" :show-company-info="pageIndex === 0">
      <!-- Report Title Section - Only on first page -->
      <div v-if="pageIndex === 0" class="report-title text-center mb-8">
        <h1 class="mb-3">{{ getReportName }}</h1>
        <div class="date-range  mx-auto rounded-full">{{ getReportDate }}</div>
      </div>

      <!-- Accounts Grid Section -->
      <div class="accounts-grid">
        <div class="account-grid-container">
          <template v-for="account in page" :key="account.id">
            <div class="account-item">
              <div class="account-icon">
                <img
                  :src="account?.platform_logo || account?.image"
                  :alt="account?.name"
                  class="profile-image"
                  @error="handleImageError"
                />
                <img
                  :src="getSocialImageRounded(account?.account_type)"
                  :alt="account?.account_type"
                  class="platform-icon"
                />
              </div>
              <div class="account-name">{{ account?.name || account?.platform_name }}</div>
            </div>
          </template>
        </div>
      </div>
    </BaseCoverPage>
    <div v-if="type === 'campaign-and-label'" class="page_view">
      <div class="cover_page">
        <div class="subpage w-100 h-100">
          <div class="content space-y-8">
            <!-- Labels Section -->
            <div v-if="labels.length" class="account-grid-container">
              <h3 class="text-xl font-semibold text-center mb-4">Labels</h3>
              <div class="flex flex-row flex-wrap gap-3 justify-center px-4">
                <div
                  v-for="label in labels"
                  :key="label"
                  class="px-5 py-2.5 rounded-full text-sm font-medium"
                  :style="{
                    backgroundColor: getLabelColor(labelCampaignByKeys[label]?.color).backgroundColor,
                    color: getLabelColor(labelCampaignByKeys[label]?.color).color
                  }"
                >
                  {{ labelCampaignByKeys[label]?.name }}
                </div>
              </div>
            </div>

            <!-- Campaigns Section -->
            <div v-if="campaigns.length" class="account-grid-container">
              <h3 class="text-xl font-semibold text-center mb-4">Campaigns</h3>
              <div class="flex flex-row flex-wrap gap-3 justify-center px-4">
                <div
                  v-for="campaign in campaigns"
                  :key="campaign"
                  class="px-5 py-2.5 rounded-full text-sm font-medium"
                  :style="{
                    backgroundColor: getCampaignColor(labelCampaignByKeys[campaign]?.color).backgroundColor,
                    color: getCampaignColor(labelCampaignByKeys[campaign]?.color).color
                  }"
                >
                  {{ labelCampaignByKeys[campaign]?.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'
import useDateFormat from "@common/composables/useDateFormat";
import IndividualCoverPage from './IndividualCoverPage.vue'
import BaseCoverPage from './BaseCoverPage.vue'


export default {
  components: {
    IndividualCoverPage,
    BaseCoverPage
  },
  props: {
    reportDate: {
      type: String,
      default: '',
    },
    accounts: {
      type: [Array, Object],
      default: () => [],
    },
    type: {
      type: String,
      default: 'Twitter',
    },
    labels: {
      type: Array,
      default: () => []
    },
    campaigns: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const { handleImageError, getSocialImageRounded } = useComposerHelper()
    const { labelCampaignByKeys, getLabelColor, getCampaignColor } = useLabelAndCampaign()
    const { momentWrapper } = useDateFormat()

    return {
      handleImageError,
      getSocialImageRounded,
      momentWrapper,
      labelCampaignByKeys,
      getLabelColor,
      getCampaignColor
    }
  },
  data() {
    return {
      showCompanyInfo: false,
      company_logo: '',
      company_name: '',
    }
  },
  computed: {
    ...mapGetters(['getActiveWorkspace']),

    getReportDate() {
      if (!this.reportDate) return

      const splitDates = this.reportDate.split(' - ')
      if (splitDates.length < 2) return
      const formattedStartDate = this.momentWrapper(splitDates[0]).formatTimezone().formatDate()
      const formattedEndDate = this.momentWrapper(splitDates[1]).formatTimezone().formatDate()

      return `${formattedStartDate} - ${formattedEndDate}`
    },
    getReportName() {
      if (!this.type) return
      if(this.type === 'campaign-and-label') return 'Campaigns & Labels Analytics';
      else if(this.type === 'twitter') return 'X (Twitter) Analytics';
      else if(this.type === 'individual') return 'Individual Overview Report';
      else if(this.type === 'group') return 'Grouped Overview Report';
      const format = (
        this.type.charAt(0).toUpperCase() + this.type.slice(1)
      ).replace(/-/g, ' ')

      return `${format} Analytics`
    },
    accountPages() {
      if (!this.accounts || !Array.isArray(this.accounts)) {
        return [];
      }

      const ACCOUNTS_PER_PAGE = 36; // 6 columns x 6 rows
      const pages = [];

      for (let i = 0; i < this.accounts.length; i += ACCOUNTS_PER_PAGE) {
        pages.push(this.accounts.slice(i, i + ACCOUNTS_PER_PAGE));
      }

      return pages;
    }
  },
  mounted() {
    this.company_logo = this.getActiveWorkspace.company_logo
    this.company_name = this.getActiveWorkspace.company_name
  }
}
</script>

<style lang="less" scoped>
.report-title {
  margin: 3rem 0;

  h1 {
    font-size: 32px;
    font-weight: 500;
    color: #333;
  }
  .date-range {
    margin-top: 1rem;
    font-size: 18px;
    width: fit-content;
    border: 2px solid #333;
    color: #333;
    padding: 0.5rem 2rem;
  }
}

.accounts-grid {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;

  .account-grid-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1.5rem;
    justify-items: center;
  }

  .account-item {
    text-align: center;
    width: 100%;

    .account-icon {
      position: relative;
      width: 60px;
      height: 60px;
      margin: 0 auto 8px;

      .profile-image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }

      .platform-icon {
        position: absolute;
        bottom: -2px;
        right: -2px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid white;
      }
    }

    .account-name {
      font-size: 12px;
      color: #333;
      margin-top: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100px;
      margin: 0 auto;
    }
  }
}

.generated-date {
  margin-top: 3rem;
  p {
    font-size: 14px;
    color: #666;
  }
}

.continuation-title {

  h2 {
    font-size: 28px;
    font-weight: 500;
    color: #333;
  }

  .page-number {
    margin-top: 0.5rem;
    font-size: 16px;
    color: #666;
  }
}

.page-break-before {
  page-break-before: always;
}
</style>
