<template>
  <div>
    <div v-if="item && type === 'plan'">
      <div class="v-calendar-view">
        <div class="event_inner">
          <!--<div class="custom_loader hide">-->
          <!--<div class="loader_inner"></div>-->
          <!--</div>-->
          <div class="top_tags_category">
            <div
                v-if="item.content_category"
                v-tooltip.top="'Content Category'"
                class="top-category"
                :style="{ 'background-color': item.content_category?.color_code }"
            >{{ item.content_category?.name }}
            </div>
            <div
                v-if="getItemTag"
                v-tooltip.top="'Content Type'"
                class="list_tag"
            >{{ getItemTag }}
            </div>
          </div>

          <!--<div title="Content Category" class="top-category">adasdasd</div>-->

          <div class="event_head d-flex align-items-center">
            <div class="head_left">
              <p class="d-flex align-items-center">
                <img
                    v-if="item.render_class"
                    v-tooltip="item.render_class?.tooltip"
                    :src="getPostStateImage(item)"
                    class="
                    status_color
                    left-icon
                    !w-4
                    !h-4
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                />
                <span>{{ formatTime(item?.time) }}</span>
                <!-- <span v-if="item.execution_time">
                {{
                  useGetWorkspaceTimeZoneTime(
                    item['execution_time']['date'],
                    getWorkspaces.activeWorkspace.timezone,
                    'hh:mm a',
                  )
                }}</span
              > -->
                <!--                                <input type="checkbox" id="calendarItemCheckbox" :name="'plan_actions'+ item._id" :value="item._id" v-model="getPlans.selected_plans">-->
                <!--                                <label :for="'plan_actions'+ item._id" class="no_text checkbox_left"></label>-->
              </p>
            </div>
            <!-- note, this is slowing down  the speed -->
            <div class="head_right">
              <PlanPlatforms :item="item"></PlanPlatforms>
            </div>
          </div>

          <div class="event_content">
            <p v-html="getCalanderPostText(item.message, 100)"></p>

            <div v-if="item.image" class="img preview-here relative">
              <!--data-plan will be use to find plan media to display in the modal-->
              <img
                  id="viewItemAttachment"
                  data-fallback="0"
                  :src="getResizedImageURL(item.image, 120, 0)"
                  :data-plan="item._id"
                  alt="post attachment"
                  @error="onError($event, item.image)"
              />
              <div v-if="item?.post_type" class="absolute font-bold leading-tight bottom-1 right-2 p-1 bg-black-100 opacity-70 text-white rounded-md text-center text-capitalize">{{ item.post_type }}</div>
            </div>
            <div v-else-if="item?.post_type === 'blog'" class="absolute font-bold leading-tight bottom-2 right-2 p-1 bg-black-100 opacity-70 text-white rounded-md text-center text-capitalize">{{ item.post_type }}</div>

            <div class="filter_label">
              <div v-if="item.campaign && item.campaign?.name"
                v-tooltip.top="'Campaign'"
                :style="getCampaignColor(item.campaign?.color)"
                class="label_tag !rounded-md !text-[12px]">
                {{ item.campaign?.name }}
              </div>
              <template v-for="(label, key) in item.labels_info">
                <div
                  v-if="label"
                  :style="getLabelColor(label.color)"
                  :key="key"
                  v-tooltip.top="'Label'"
                  class="label_tag !rounded-md !text-[12px]"
                >{{ label.name }}
                </div>
              </template>
            </div>
          </div>
          <div class="bottom_action calendar-btn d-flex">
            <!--<template v-if="item.can_perform.approval">-->
            <!--<i id="approveCalendarItem"  title="Approve" class="green_icon calendar_actions check far fa-check"></i>-->
            <!--<i id="rejectCalendarItem" title="Reject" class="red_icon calendar_actions cross far fa-times"></i>-->
            <!--</template>-->

            <template
                v-if="item.status === 'review' || item.status === 'draft'"
            >
              <template v-if="item.status === 'review' && !item.approval">
                <template v-if="item.can_perform?.approval">
                  <i
                      v-tooltip.top="'Approve'"
                      data-event="approveCalendarItem"
                      class="green_icon calendar_actions check far fa-check"
                  ></i>
                  <i
                      v-tooltip.top="'Reject'"
                      data-event="rejectCalendarItem"
                      class="red_icon calendar_actions cross far fa-times"
                  ></i>
                </template>
              </template>

              <template
                  v-else-if="
                  item.approval &&
                  item.approval.status === 'pending_approval' &&
                  checkApprovalStatus(item.approval)
                "
              >
                <!--<button  type="button" class="btn_click btn" :disabled="item.is_processing" @click.prevent="callProcessPlanApproval(item,'approve')">Approve</button>-->
                <i
                    v-tooltip.top="'Approve'"
                    data-event="approvalCalendarItemApprove"
                    class="green_icon calendar_actions check far fa-check"
                ></i>

                <!--<button type="button" class="btn_click btn" :disabled="item.is_processing" @click.prevent="callProcessPlanApproval(item,'reject')">Reject</button>-->
                <i
                    v-tooltip.top="'Reject'"
                    data-event="approvalCalendarItemReject"
                    class="red_icon calendar_actions cross far fa-times"
                ></i>
              </template>
            </template>

            <template
                v-if="
                getTeamMembership === 'team' &&
                item.status === 'draft' &&
                item.publish_time_options &&
                item.publish_time_options.plan_status === 'Approved' &&
                item.type === 'Composer Social'
              "
            >
              <div class="min_width dropdown_header" data-toggle="dropdown">
                <i
                    v-if="item.publish_time_options.hide_client"
                    v-tooltip.top="hideFromClientMessage(item)"
                    data-event="hideClientCalendarItem"
                    class="far fa-eye-slash"
                ></i>
                <i
                    v-else
                    v-tooltip.top="hideFromClientMessage(item)"
                    data-event="hideClientCalendarItem"
                    class="far fa-eye"
                ></i>
              </div>
              <div
                  id="test"
                  class="
                  dropdown-menu dropdown-menu-right
                  custom-popup
                  eye-visible-pop
                  visibility-popup
                "
              >
                <div class="popup-inner">
                  <template v-if="item.publish_time_options.hide_client">
                    <p>Make this post visible to clients?</p>
                    <div class="buttons">
                      <button data-event="visibleBtn" type="">No</button>
                      <button
                          data-event="visibleBtn"
                          @click.prevent="hideFromClientAction(plan, false)"
                      >Yes
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <p>Make this post Invisible to clients?</p>
                    <div class="buttons">
                      <button data-event="visibleBtn" type="">No</button>
                      <button
                          data-event="visibleBtn"
                          @click.prevent="hideFromClientAction(plan, true)"
                      >Yes
                      </button>
                    </div>
                  </template>
                </div>
              </div>
            </template>
            <template v-if="!item.is_inprogress">
              <!-- <template v-if="!isInProgressPost(item)"> -->
              <i
                  v-if="canReplacePost(item)"
                  v-tooltip.top="
                  'This action will fetch a new post with the same rules you have set in the automation campaign'
                "
                  data-event="replaceCalendarItem"
                  class="calendar_actions refresh far fa-refresh"
              ></i>
              <i
                  v-if="canEditPlan(item)"
                  v-tooltip.top="'Edit'"
                  data-event="editCalendarItem"
                  class="calendar_actions edit icon-edit-cs"
              ></i>
              <i
                  v-if="canDuplicatePost(item)"
                  v-tooltip.top="'Duplicate'"
                  data-event="duplicateCalendarItem"
                  class="calendar_actions clone far fa-clone"
              ></i>
              <i
                  v-if="canDeletePost(item)"
                  v-tooltip.top="'Delete'"
                  data-event="deleteCalendarItem"
                  class="delete_icon calendar_actions trash icon-delete-cs"
              ></i>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="v-note-view mb-2">
      <div
          :style="{
      backgroundColor: `${getLighterColor(item.note_color)}`,
      border: `1px solid ${item.note_color}`,
    }"
          :id="generateUniqueId(item._id)"
          class="event_content p-2 rounded-lg cursor-pointer"
          title="View note"
      >
        <div class="flex items-center justify-between leading-none">
          <div class="flex-1 min-w-0 mr-2"> <!-- Added flex-1 and min-w-0 for proper truncation -->
            <span class="text-black-700 flex items-center truncate">
              <img v-if="item.is_private" src="@assets/img/icons/notes_lock.svg" class="w-3 h-3 mr-1" alt=""/>
              <strong class="mr-1">
                {{ getNoteBlockTitle(item).title }}
              </strong>
              <template v-if="getNoteBlockTitle(item).description">
                - <span class="font-normal ml-1">{{ getNoteBlockTitle(item).description }}</span>
              </template>
            </span>
          </div>
          <span
              v-if="item.start_date !== item.end_date"
              class="text-black-500 whitespace-nowrap flex-shrink-0">
          {{ momentWrapper(new Date(item.start_date)).formatDate() }} - {{ momentWrapper(new Date(item.end_date)).formatDate() }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlatformName } from '@common/lib/integrations'
// import { getWorkspaceTimeZoneTime } from '@common/lib/date-time'
import {
  getStatusClass,
  // planHeadAttachment,
  itemSelectedPlatformVisibleStatus,
} from '@common/lib/planner'
import { memoizeCallback } from '@common/lib/helper'
import PlanPlatforms from '@src/modules/planner/components/view/PlanPlatforms'
import { mapGetters } from 'vuex'
import plannerUtilsMixin from '@common/mixins/publish/planner-mixin'
// import usePermissions from "@common/composables/usePermissions";
import usePlannerHelper from '@src/modules/planner_v2/composables/usePlannerHelper'
import useDateFormat from '@common/composables/useDateFormat'
// Memoize the callback to avoid unnecessary re-renders
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'

const { postCalenderHeadAttachment } = usePlannerHelper()
const usePlanHeadAttachment = memoizeCallback(postCalenderHeadAttachment)
// const useGetWorkspaceTimeZoneTime = memoizeCallback(getWorkspaceTimeZoneTime)

const importedMethods = {
  getPlatformName,
  getStatusClass,
  itemSelectedPlatformVisibleStatus,
  // useGetWorkspaceTimeZoneTime,
}
export default {
  components: {
    PlanPlatforms,
  },
  mixins: [plannerUtilsMixin],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    plan: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
  },
  emits: ['preview-plan'],
  setup() {
    // const {hasPermission} = usePermissions()
    const { getUserTimePreference, momentWrapper } = useDateFormat()
    const { getLabelColor, getCampaignColor} = useLabelAndCampaign()
    const {
      renderClass,
      isReplaceablePost,
      canEditThisPost,
      isDuplicatablePost,
      isEditablePost,
      getCalanderPostText,
      isInProgressPost,
      getPostStateImage,
      generateUniqueId,
      getNoteBlockTitle,
      getLighterColor
    } = usePlannerHelper()
    return {
      // hasPermission,
      renderClass,
      isReplaceablePost,
      isDuplicatablePost,
      getCalanderPostText,
      isInProgressPost,
      isEditablePost,
      canEditThisPost,
      getPostStateImage,
      getUserTimePreference,
      momentWrapper,
      generateUniqueId,
      getNoteBlockTitle,
      getLighterColor,
      getLabelColor,
      getCampaignColor
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getPlannerLoaders',
      'getProfile',
      'getActiveWorkspace',
      'getPublishSelection',
      'getSocialIntegrationsNames',
    ]),
    getItemTag() {
      if (this.item.evergreen_automation_id) return 'Evergreen'
      if (this.item.rss_automation_id) return 'RSS'
      if (this.item.repeat_post) return 'Repeat'
      return ''
    },
    getRenderClass() {
      return this.renderClass(this.item)
    },
  },
  methods: {
    ...importedMethods,
    onError(event, image) {
      const fallbackIndex = event.target.dataset.fallback
      const fallbacks = [
        image,
        `https://storage.googleapis.com/lumotive-web-storage/some-fallback-0.png`,
        `https://storage.googleapis.com/lumotive-web-storage/some-fallback-1.png`,
        `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`,
      ]
      if (fallbackIndex > fallbacks.length - 1) return
      event.target.src = fallbacks[fallbackIndex]
      event.target.dataset.fallback++
    },
    hideFromClientMessage(item) {
      if (item.publish_time_options.hide_client) return 'Hide from clients'
      return 'Unhide from clients'
    },
    getPlanAttachment(item) {
      // const attachment = planHeadAttachment(item) //before
      const attachment = usePlanHeadAttachment(item)

      if (attachment) {
        return attachment
        // return attachment.type === 'image'
        //   ? attachment.url
        //   : attachment.thumbnail
      }
      return item?.linkedin_options?.document?.thumbnail || ''
    },
    onClickCalendarEvent() {
      console.log('METHOD::onClickCalendarEvent')
      this.$emit('preview-plan', this.item._id)
    },
    canReplacePost(item) {
      return item?.can_perform?.replace
      // return (
      //   this.isReplaceablePost(item) &&
      //   this.hasPermission('can_schedule_plan')
      // )
    },
    canDuplicatePost(item) {
      return item?.can_perform?.duplicate
      // return (
      //     this.isDuplicatablePost(item) &&
      //     this.hasPermission('can_schedule_plan')
      // )
    },
    canDeletePost(item) {
      return item?.can_perform?.delete
      // return this.hasPermission('delete_plan', item.user_id)
    },
    canEditPlan(item) {
      return item?.can_perform?.edit
      //  return (
      //      this.isEditablePost(item) &&
      //     this.canEditThisPost(item)
      //  )
    },
    getTooltipText(item) {
      if (item.partially_failed) {
        return 'Partially Failed'
      }
      return this.getRenderClass.tooltip
    },
    formatTime(time) {
      // user time preference is 24h convert this 12hour format to 24hour format manually
      if (this.getUserTimePreference !== '24h') {
        return time
      }

      // Validate time format
      const timePattern = /^(1[0-2]|0?[1-9]):([0-5][0-9])\s?(am|pm)?$/i
      if (!timePattern.test(time)) {
        console.log('Invalid time format')
        return time
      }

      // Extract hour, minute, and period (am/pm)
      const [timePart, period] = time.split(/\s+/)
      let [hour, minute] = timePart.split(':')
      hour = parseInt(hour, 10)

      // Handle missing am/pm indicator
      if (!period) {
        console.log('Missing am/pm indicator, assuming 24-hour format')
        return time
      }

      // Convert 12-hour format to 24-hour format
      if (period.toLowerCase() === 'pm' && hour < 12) {
        hour += 12
      } else if (period.toLowerCase() === 'am' && hour === 12) {
        hour = 0
      }

      return `${hour.toString().padStart(2, '0')}:${minute}`
    },
  },
}
</script>

<style lang="scss" scoped>
i.partially_failed {
  transform: rotate(270deg);
  color: #c00000;
}
</style>
