<script setup>
import { computed } from 'vue'
import CardsComponent from '@src/modules/analytics/views/facebook_v2/components/CardsComponent.vue'
import TopPosts from '@src/modules/analytics/views/facebook_v2/components/TopPosts.vue'
import AudienceGrowthChart from '@src/modules/analytics/views/facebook_v2/components/graphs/AudienceGrowthChart.vue'
import ImpressionsChart from '@src/modules/analytics/views/facebook_v2/components/graphs/ImpressionsChart.vue'
import EngagementsChart from '@src/modules/analytics/views/facebook_v2/components/graphs/EngagementsChart.vue'
import ActiveUserByHour from '@src/modules/analytics/views/facebook_v2/components/graphs/ActiveUserByHour.vue'
import ActiveUserByDay from '@src/modules/analytics/views/facebook_v2/components/graphs/ActiveUserByDay.vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'
import AiInsightsSection from '@src/modules/analytics/views/facebook_v2/components/AIInsightsSection.vue'
import ReelsPerformanceChart from '@/src/modules/analytics/views/facebook_v2/components/graphs/ReelsPerformanceChart.vue'
import RollupCard from '@/src/modules/analytics/views/facebook_v2/components/RollupCard.vue'
import VideoPerformanceChart from '@/src/modules/analytics/views/facebook_v2/components/graphs/VideoPerformanceChart.vue'
import PublishingBehaviourChart from '@/src/modules/analytics/views/facebook_v2/components/graphs/PublishingBehaviourChart.vue'
import PostsSection from '@/src/modules/analytics/views/facebook_v2/components/PostsSection.vue'
import AudienceLocationChart from '@/src/modules/analytics/views/facebook_v2/components/graphs/AudienceLocationChart.vue'
import DemographicsByGender from '@/src/modules/analytics/views/facebook_v2/components/graphs/DemographicsByGender.vue'
import DemographicsByAge from '@/src/modules/analytics/views/facebook_v2/components/graphs/DemographicsByAge.vue'
import ReportFooter from '@/src/modules/analytics/components/reports/ReportFooter.vue'

const GENDER_MAP = {
  M: 'Males',
  F: 'Females',
  U: 'Unspecified',
}

const {
  rollupTypes,
  topPostsData,
  overviewDemographicsData,
  showActiveUserChart
} = useFacebookAnalytics()

// max no. of posts per page
const chunkSize = 10

/**
 * Computes the array of start indexes for chunks of top engaging posts.
 * @returns {Array<number>} An array containing the start indexes for each chunk.
 */
const topEngagingPostsChunks = computed(() => {
  const startIndexes = []
  if (topPostsData.value) {
    for (let i = 0; i < topPostsData.value?.length; i += chunkSize) {
      startIndexes.push(i)
    }
  }
  return startIndexes
})

/**
 * Computes the total page size based on the number of hard-coded sections and the number of chunks of top engaging posts.
 * @returns {number} The total page size.
 */
const totalPageSize = computed(() => {
  const TOTAL_SECTIONS = 10
  const demographicsSection = 1

  if (hasDemographicsData.value) {
    return (
      TOTAL_SECTIONS + topEngagingPostsChunks.value.length + demographicsSection
    )
  }

  return TOTAL_SECTIONS + topEngagingPostsChunks.value.length
})

const leadingGender = computed(() => {
  return (
    GENDER_MAP[overviewDemographicsData.value?.max_gender_age?.gender] ||
    'Unknown'
  )
})
const hasDemographicsData = computed(() => {
  return overviewDemographicsData.value?.fans > 100
})

const getActualPageNumber = (number) => {
  console.log('hasDemographicsData', hasDemographicsData.value)
  if (!hasDemographicsData.value) {
    return number - 1
  }
  return number
}
</script>

<template>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Overview</h2>

      <!-- Cards -->
      <div class="grid grid-cols-8 gap-4 mb-14">
        <CardsComponent />
      </div>
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Daily Audience Growth</h2>

      <div class="grid grid-cols-3 gap-4">
        <div class="col-span-2">
          <AudienceGrowthChart type="daily" />
        </div>
        <RollupCard :type="rollupTypes.AUDIENCE" />
      </div>
    </div>
    <ReportFooter :current-page="1" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Cumulative Audience Growth</h2>
      <div class="grid grid-cols-3 gap-4">
        <div class="col-span-2">
          <AudienceGrowthChart type="cumulative" />
        </div>
        <RollupCard :type="rollupTypes.AUDIENCE" />
      </div>
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Page Impressions</h2>
      <div class="grid grid-cols-3 gap-4">
        <div class="col-span-2">
          <ImpressionsChart />
        </div>
        <RollupCard :type="rollupTypes.IMPRESSIONS" />
      </div>
    </div>
    <ReportFooter :current-page="2" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500"> Page Engagements</h2>
      <div class="grid grid-cols-3 gap-4">
        <div class="col-span-2">
          <EngagementsChart />
        </div>
        <RollupCard :type="rollupTypes.ENGAGEMENTS" />
      </div>
      <div class="flex flex-col gap-7">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Publishing Behaviour By Impressions</h2>
        <PublishingBehaviourChart type="impressions" />
      </div>
    </div>
    <ReportFooter :current-page="3" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <div class="flex flex-col gap-4">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Publishing Behaviour By Engagement</h2>
        <PublishingBehaviourChart type="engagements" />
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Publishing Behaviour By Reach</h2>
        <PublishingBehaviourChart type="reach" />
      </div>
    </div>
    <ReportFooter :current-page="4" :total-pages="totalPageSize" />
  </div>
  <div v-if="showActiveUserChart" class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Active Users</h2>
      <div class="grid grid-cols-2 gap-4">
        <ActiveUserByHour />
        <ActiveUserByDay />
      </div>
    </div>
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <div class="flex flex-col gap-7">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Video Performance By Watch Time</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <VideoPerformanceChart type="watch_time" />
          </div>
          <RollupCard :type="rollupTypes.VIDEO_INSIGHTS" />
        </div>
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Video Performance By Views</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <VideoPerformanceChart type="views" />
          </div>
          <RollupCard :type="rollupTypes.VIDEO_INSIGHTS" />
        </div>
      </div>
    </div>
    <ReportFooter :current-page="6" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <div class="flex flex-col gap-7">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Video Performance By Engagement</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <VideoPerformanceChart type="engagements" />
          </div>
          <RollupCard :type="rollupTypes.VIDEO_INSIGHTS" />
        </div>
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Reels Performance By Initial Plays</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <ReelsPerformanceChart type="initial" />
          </div>
          <RollupCard :type="rollupTypes.REELS" />
        </div>
      </div>
    </div>
    <ReportFooter :current-page="7" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <div class="flex flex-col gap-7">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Reels Performance By Watch Time</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <ReelsPerformanceChart type="watch_time" />
          </div>
          <RollupCard :type="rollupTypes.REELS" />
        </div>
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Reels Performance By Engagement</h2>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2">
            <ReelsPerformanceChart type="engagement" />
          </div>
          <RollupCard :type="rollupTypes.REELS" />
        </div>
      </div>
    </div>
    <ReportFooter :current-page="8" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Top Posts</h2>
      <TopPosts />
    </div>
    <ReportFooter :current-page="5" :total-pages="totalPageSize" />
  </div>
  <template v-if="hasDemographicsData">
    <div class="page_view flex flex-col justify-between">
      <div class="subpage">
        <div class="w-full text-white p-3 bg-[black] mb-2 rounded-md"
          >Meta has deprecated
          <span class="font-bold">Facebook Audience by Age and Gender</span>
          data. Insights prior to
          <span class="font-bold">March 14, 2024</span>, are available if your
          account was connected before that date.
        </div>
        <!-- Demographics Chart -->
        <!-- fans value is always 0 for accounts connected after 14 march 2024 so hiding for accounts connected after that -->
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Demographics</h2>
        <div class="grid grid-cols-1 gap-4">
          <DemographicsByAge />
          <DemographicsByGender />

          <div
            class="bg-white p-5 rounded-md w-full relative flex justify-center color-border cst-editor"
          >
            <p>
              <span class="font-semibold"> {{ leadingGender }}</span> between
              the ages of
              <span class="font-semibold">
                {{ overviewDemographicsData?.max_gender_age?.age }}</span
              >
              appear to be the leading force among your followers.
            </p>
          </div>
        </div>
      </div>
      <ReportFooter :current-page="9" :total-pages="totalPageSize" />
    </div>
  </template>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Audience Demographics</h2>
      <div class="grid grid-cols-1 gap-7">
        <AudienceLocationChart type="country" />
        <AudienceLocationChart type="city" />
      </div>
    </div>
    <ReportFooter :current-page="getActualPageNumber(10)" :total-pages="totalPageSize" />
  </div>
  <template v-for="(start, index) in topEngagingPostsChunks" :key="index">
    <!-- Iterate over each chunk of top engaging posts -->
    <div class="page_view flex flex-col justify-between">
      <div class="subpage">
        <!-- Render post table for the current chunk of top posts -->
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Facebook Top Posts (Top 5)</h2>
        <PostsSection
          :range-start-value="start"
          :range-end-value="Math.min(start + chunkSize, topPostsData?.length)"
        />
      </div>
      <ReportFooter :current-page="getActualPageNumber(11 + index)" :total-pages="totalPageSize" />
    </div>
  </template>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">AI Insights</h2>
      <AiInsightsSection :indexes-to-show="[0, 1]" />
    </div>
    <ReportFooter :current-page="getActualPageNumber(11 + topEngagingPostsChunks.length)" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <AiInsightsSection :indexes-to-show="[2]" />
    </div>
    <ReportFooter :current-page="getActualPageNumber(11 + topEngagingPostsChunks.length + 1)" :total-pages="totalPageSize" />
  </div>
</template>
