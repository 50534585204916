<script setup>
import { computed, watch } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/facebook_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'

// state
const {
  engagementsData,
  dataZoomOptions,
  lineChartOptions: engagementsChartOptions,
  isReportView,
  isLoadingStates,
  routes,
} = useFacebookAnalytics('Engagements')

const isNoData = computed(() => {
  return engagementsData.value?.engagement?.buckets?.length === 0
})

watch(
  () => [engagementsData.value],
  () => {
    const pageOptions = engagementsData.value?.engagement || {}
    // page chart options
    engagementsChartOptions.value.series[0].data =
      pageOptions?.page_engagements || []
    engagementsChartOptions.value.xAxis.data = pageOptions?.buckets
  },
  {
    deep: true,
    immediate: true,
  }
)
const isLoading = computed(
  () => isLoadingStates.value?.[routes.OVERVIEW_ENGAGEMENT]
)
</script>

<template>
  <AnalyticsCardWrapper
    type="engagements-chart-fb"
    :enable-modal="true"
    :show-insights-button="!isReportView"
    :platform="'facebook'"
  >
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Page Engagements
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Examine the overall engagement metrics (reactions, comments,
                  and shares) throughout the selected time period, regardless of
                  the post's publication date.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          :type="getChartType"
          :custom-modal-height = "slotProps.isInsights ? '40vh' : '80vh' "
          :chart-options="
            slotProps.isModal
              ? {
                  ...engagementsChartOptions,
                  ...dataZoomOptions,
                  grid: { ...engagementsChartOptions.grid, bottom: 70 },
                }
              : engagementsChartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
